@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-document-detail {
    margin-top: rem(31);
    margin-bottom: rem(56);
    @media screen and (min-width: $tab) {
        margin-top: rem(25);
        margin-bottom: rem(90);
    }   
}
