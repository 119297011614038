@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.hamburger {
	position: relative;
	width: 100%;
	max-width: 37px;
	height: 37px;
	@media screen and (min-width: $tab) {
	}
}

.hamburger__btn {
	position: absolute;
	width: 100%;
	height: 100%;
	right: 0;
	z-index: 999; /*追記：いつも一番上*/
	cursor: pointer;
}

button.hamburger__btn {
	border: none;
	outline: none;
	background: transparent;
}

.bar {
	width: 37px;
	height: 2px;
	display: block;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	background-color: #7e7e7e;
	pointer-events: none;
}

.bar-top {
	top: 8px;
}
.bar-middle {
	top: 50%;
	transform: translate(-50%, -50%);
}
.bar-bottom {
	bottom: 8px;
}

.hamburger__btn.close .bar-top {
	transform: translate(-50%, 10px) rotate(45deg);
	transition: transform 0.3s;
}

.hamburger__btn.close .bar-middle {
	opacity: 0;
	transition: opacity 0.3s;
}

.hamburger__btn.close .bar-bottom {
	transform: translate(-50%, -10px) rotate(-45deg);
	transition: transform 0.3s;
}
