@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-publication {
    margin-top: rem(31);
    margin-bottom: rem(81);
    @media screen and (min-width: $tab) {
        margin-top: rem(26);
        margin-bottom: rem(115);
    }   
}
