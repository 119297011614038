@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

// お役立ち記事 セレクトボックス

.knowledge-detail-selectBox__content.sub-selectBox__content {
    @media screen and (min-width: $tab) {
        display: none;
    }
}

