@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

// お役立ち記事セレクトボックス(カスタマイズ)
.seminer-selectBox-content.sub-selectBox__content {
    width: 100%;
    margin-top: rem(51);
    display: flex;
    justify-content: center;
    @media screen and (min-width: 1120px) {
        display: none;
    }
}

.seminer-select {
    position: relative;
    height: 25px;
    cursor: pointer;
}
.seminer-select1 {
    margin-right: rem(9);
    max-width: rem(164);
    width: 100%;
    background-color:  $yellow;
    color: $white;
    z-index: 10;
}
.seminer-select2 {
    max-width: rem(164);
    width: 100%;
    background-color:  #F0F0F0;
    color: $sub-black2;
    z-index: 10;
}
.seminer-select__expand {
    width: 0;
    height: 25px;
    position: absolute;
    top: 0;
    right: 0;
}

.seminer-select__expand::after {
    content: "▼";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    font-size: 10px;
    pointer-events: none;
    z-index: 2;
    transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
}

.seminer-select__expand:checked::after {
    transform: translate(-50%, -50%) rotate(180deg) scaleX(-1);
}

.seminer-select__expand1::after {
    color: $white;
}

.seminer-select__expand2::after {
    color: $txt-color;
}

.seminer-select__expandLabel {
    display: block;
    width: 100%;
    height: 25px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.seminer-select__close {
    display: none;
}

.seminer-select__closeLabel {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
}

.seminer-select__items {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 25px;
}

.seminer-select__input {
    display: none;
}

.seminer-select__label {
    transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
    display: block;
    height: 0;
    font-size: 9px;
    line-height: 25px;
    overflow: hidden;
    color: $white;
    background-color: $yellow;
    cursor: pointer;
    padding-left: 5px;
    position: relative;
    @media screen and (min-width: $sp) {
        font-size: 11px;
    }
}

.seminer-select__label2.seminer-select__label {
    color: $sub-black2;
    background-color: #F0F0F0;
}

.seminer-select__label1.seminer-select__label.select__label-placeholder {
    color: $white;
    height: 25px;
    left: 0px;
}

.seminer-select__label2.seminer-select__label.select__label-placeholder {
    color: $sub-black2;
    background-color: #F0F0F0;
    height: 25px;
    left: 0px;
}

span.seminer-select__label.seminer-select__label-placeholder::before {
    display: none;
}

.seminer-select__label-placeholder {
    height: 25px;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0px;
    background-color: transparent;
}

.seminer-select__expand:checked + .seminer-select__closeLabel {
    display: block;
}

.seminer-select__expand:checked + .seminer-select__closeLabel + .seminer-select__options .seminer-select__label {
    height: 25px;
}


.seminer-select__expand:checked + .seminer-select__closeLabel + .seminer-select__expandLabel {
    display: none;
}

.seminer-select__input:checked + .seminer-select__label {
    height: 25px;
    margin-top: -25px;
}
