@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//swiper
.swiper {
    transform: translateY(-70px);
    @media screen and (min-width: $tab) {
        transform: translateY(30px);
    }
}

.swiper-wrapper {
    // padding-top: rem(60);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: rem(1135);
    }
}

.swiper-slide {
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(346);
    }
}

.swiper-slide img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 268 / 363;
    @media screen and (min-width: $tab) {
        aspect-ratio: 346 / 469;
    }
}

//ページネーション
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -10px;
    @media screen and (min-width: $tab) {
        bottom: -115px;
    }
}

span.swiper-pagination-bullet {
    height: 11px;
    width: 11px;
}
