@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//card2

.card2__article-content {
    margin-top: rem(40);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: rem(50);
    @media screen and (min-width: $tab) {
        max-width: rem(1176);
        grid-template-columns: repeat(3, 1fr);
        gap: rem(21);
    }
}

.card2__img {
    max-width: rem(600);
    width: 100%;
    padding-inline: rem(14);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: rem(338);
        padding-inline: rem(0);
    }
}

.card2__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 300 / 165;
    @media screen and (min-width: $tab) {
        aspect-ratio: 338 / 186;
    }
}

.card2__body {
    margin-top: rem(15);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media screen and (min-width: $tab) {
        margin-top: rem(8);
    }
}

.card2__title {
    font-weight: bold;
    font-size: rem(16);
    line-height: calc(24 / 16);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(20);
        line-height: calc(30 / 20);
    }
}

.card2__download {
    margin-top: rem(15);
    max-width: rem(183);
    width: 100%;
    background-color: $sub-black2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(5);
    padding: rem(3) 0;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        margin-top: rem(9);
        max-width: rem(338);
        gap: rem(6);
    }
}

span.card2__download-icon img {
    width: rem(15);
    height: rem(11);
    @media screen and (min-width: $tab) {
        width: rem(21);
        height: rem(15);
    }
}

.card2__download img {
    width: rem(72);
    @media screen and (min-width: $tab) {
        width: rem(102);
    }
}

//card2__imgホバー画像拡大
.card2__img {
    overflow: hidden;
}

.card2__img img {
    transition: transform 0.5s ease;
}

.card2__link:hover .card2__img img {
    transform: scale(1.1);
}
