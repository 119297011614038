@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-about04 {
    margin-top: rem(50);
    margin-bottom: rem(80);
    @media screen and (min-width: $tab) {
        margin-top: rem(101);
        margin-bottom: rem(100);
    }
}
