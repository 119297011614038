@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//私たちの6つの強み
.sixstrength__inner {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(1242);
        padding-inline: rem(25);
    }
}

.sixstrength__bg {
    background: url(../images/sixstrength/sp-sixstrength-bg.webp) no-repeat center / cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    padding-inline: rem(20);
    aspect-ratio: 335 / 459;
    @media screen and (min-width: $tab) {
        background: url(../images/sixstrength/sixstrength-bg.webp) no-repeat center / cover;
        max-width: rem(1232);
        aspect-ratio: 1192 / 620;
        padding-inline: rem(25);
    }
}

.sixstrength__category {
    background-color: $blue;
}

.sixstrength__text-content {
    position: relative;
    max-width: rem(600);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(660);
    }
}

.sixstrength__title--en {
    max-width: rem(600);
    margin-inline: auto;
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(500);
    }
    @media screen and (min-width: 769px) {
        max-width: rem(589);
    }
}

.sixstrength__title--ja {
    margin-top: rem(15);
    font-weight: 500;
    font-size: rem(24);
    line-height: calc(49 / 32);
    color: $white;
    text-align: center;
    @media screen and (min-width: 370px){ 
    font-size: rem(32);
}
    @media screen and (min-width: $tab) {
        margin-top: 0;
        font-size: rem(54);
        line-height: calc(83 / 54);
    }
}

.sixstrength__text {
    margin-top: rem(27);
    font-weight: bold;
    font-size: rem(14);
    letter-spacing: 0.1em;
    color: $white;
    text-align: center;
    @media screen and (min-width: 370px){ 
        font-size: rem(17);
    }
    @media screen and (min-width: 1050px) {
        margin-top: rem(29);
        font-size: rem(22);
    }
}

.sixstrength__article-container {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding: rem(15) 0;
    @media screen and (min-width: $tab) {
        margin-top: rem(50);
        max-width: rem(1234);
        padding: 0 rem(25);
    }
}


.sixstrength__article-content {
    display: flex;
    flex-direction: column;
    padding: rem(15) rem(20);
    @media screen and (min-width: $tab) {
        flex-direction: row;
        align-items: center;
        background-color: #f4f4f4;
        margin-top: rem(30);
        padding: 0;
    }
}

article.sixstrength__article:nth-child(1){
    margin-top: 0;
}

.sixstrength__article:nth-child(2n) > .sixstrength__article-content {
    background-color: #f4f4f4;
    @media screen and (min-width: $tab) {
        flex-direction: row-reverse;
    }
}

.sixstrength__article-img {
    margin-top: rem(10);
    max-width: rem(670);
    width: 100%;
    flex-shrink: 0;
    order: 0;
    @media screen and (min-width: $tab) {
        margin-top: 0;
        max-width: rem(360);
    }
}

.sixstrength__article-img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 335 / 160;
    object-position: center;
    @media screen and (min-width: $tab) {
        aspect-ratio: 360 / 506;
    }
}

.sixstrength__article-body {
    display: contents;
    @media screen and (min-width: $tab) {
        display: flex;
        flex-direction: column;
        padding: 0 rem(20);
    }
    @media screen and (min-width: 1200px) {
        padding: rem(61) rem(31);
    }
}

.sixstrength__category {
    order: -1;
    width: fit-content;
    padding: 0 10px;
    @media screen and (min-width: $tab) {
        padding: 2px 10px;
    }
}

.sixstrength__category img {
    width: rem(47);
    @media screen and (min-width: $tab) {
        width: rem(75);
    }
}

.sixstrength__article-number {
    order: -1;
    font-weight: 900;
    font-size: rem(40);
    line-height: calc(47 / 40);
    color: $sub-black1;
    @media screen and (min-width: 1200px) {
        font-size: rem(54);
        line-height: calc(64 / 54);
    }
}

.sixstrength__article-title {
    order: -1;
    font-weight: 900;
    font-size: rem(23);
    line-height: calc(29 / 23);
    color: $sub-black1;
    @media screen and (min-width: 1200px) {
        font-size: rem(32);
        line-height: calc(45 / 32);
    }
}

.sixstrength__article-text {
    margin-top: rem(10);
    font-weight: 400;
    font-size: rem(14);
    line-height: calc(21 / 14);
    color: $sub-black1;
    @media screen and (min-width: 1200px) {
        margin-top: rem(22);
        font-size: rem(16);
        line-height: calc(24 / 16); 
    }
}
