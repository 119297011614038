$txt-color:#1C1C1C;
$white:#ffff;
$black: #000000;
$blue: #0077B4;
$green:  #005a2d;
$red:  #FF0926;
$yellow:  #d0b627;
$purple: #6e0054;
$brown:  #4e2e12;
$sub-black1: #383838;
$sub-black2: #404040;
