@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//出版実績
.publication__inner {
    width: 100%;
    padding: 0 rem(16) 0 rem(19);
    @media screen and (min-width: $tab) {
        max-width: rem(1097);
    }
}

.publication__pagination {
   margin-top: rem(49);
   @media screen and (min-width: $tab) {
        margin-top: rem(72);
    }
}

//card-list__linkホバー画像拡大
.card-list__img {
    overflow: hidden;
}

.card-list__img img {
    transition: transform 0.5s ease;
}

.card-list__link:hover .card-list__img img {
    transform: scale(1.1);
}