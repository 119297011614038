@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.footer-logo {
	background: #ddd;
	padding: 22px 0;
	@media screen and (max-width: 950px) {
		padding: 19px 0;
	}
}

.footer-logo__inner {
	max-width: rem(1060);
	width: 100%;
	margin-inline: auto;
	padding-inline: rem(20);
}

.footer-logo__items {
	display: flex;
	@media screen and (max-width: 950px) {
		flex-direction: column;
	}
}

.footer-logo__item-wrap {
	display: flex;
	align-items: center;
	@media screen and (max-width: 950px) {
		margin-inline: auto;
	}
}

.footer-logo__item {
	display: inline-block;
	margin-right: rem(209);
	@media screen and (max-width: 950px) {
		max-width: rem(142);
		width: 100%;
		margin-right: rem(23);
	}
}

.footer-logo__item-wrap > div:nth-child(2) {
	@media screen and (max-width: 950px) {
		max-width: rem(170);
		width: 100%;
		margin-right: 0;
	}
}

.footer-logo__item img {
	@media screen and (max-width: 950px) {
	width: 100%;
	height: 100%;
	}
}

.footer-logo__item.footer-logo__item--last {
	margin-right: 0;
	@media screen and (max-width: 950px) {
		margin-inline: auto;
	}
}
