@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//お役立ち記事 サイドバー

.knowledge-side__category-title {
    background-color: $purple;
}

.knowledge-side__category-list,
.knowledge-side__tag-list {
    margin-top: rem(15);
}

.knowledge-side__recommendーarticles {
    margin-top: rem(36);
    @media screen and (min-width: $tab) {
        margin-top: rem(16);
    }
}

//おすすめ記事の配置切り替えメディアクエリ
.knowledge-side__recommend-bg.sub-side__articles-bg {
    display: none;
    @media screen and (min-width: $tab) {
        display: block;
    }
}

.sp-knowledge-side__recommend-bg.sub-side__articles-bg {
    @media screen and (min-width: $tab) {
        display: none;
    }
}