@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//top-card2

.top-card2__article-content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(1176);
        grid-template-columns: repeat(3, 1fr);
        gap: rem(21);
    }
}

.top-card2__img {
    max-width: rem(670);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(378);
    }
}

.top-card2__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 335 / 174;
    @media screen and (min-width: $tab) {
        aspect-ratio: 378 / 196;
    }
}

.top-card2__body {
    margin-top: rem(10);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.top-card2__category {
    width: fit-content;
    padding: 0 17px;
    line-height: rem(11);
    @media screen and (min-width: $tab) {
        padding: 0 10px;
        line-height: rem(15);
    }
}

.top-card2__category img {
    display: inline-block;
    max-width: rem(56);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(73);
    }
}

.top-card2__title {
    margin-top: rem(5);
    font-weight: 900;
    font-size: rem(13);
    line-height: calc(15 / 13);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(20);
    }
    @media screen and (min-width: 1050px) {
        font-size: rem(22);
        line-height: calc(26 / 22);
    }
}

.top-knowledge__topics {
    margin-top: rem(5);
    font-size: rem(10);
    line-height: calc(22 / 10);
    color: $sub-black1;
    @media screen and (min-width: 1050px) {
        font-size: rem(13);
        line-height: calc(22 / 13);
    }
}

//top-sixstrengthホバー画像拡大
.top-card2__img {
    overflow: hidden;
}

.top-card2__img img {
    transition: transform 0.5s ease;
}

.top-card2__link:hover .top-card2__img img {
    transform: scale(1.1);
}
