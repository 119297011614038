@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

// セミナー情報 セレクトボックス

.seminer-detail-selectBox__content.sub-selectBox__content {
    margin-top: rem(51);
    @media screen and (min-width: $tab) {
        display: none;
    }
}

