@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-beliveInEdithing {
    margin-top: rem(31);
    @media screen and (min-width: $tab) {
        margin-top: rem(26);
    }   
}
