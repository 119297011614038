@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//下層ページセクションタイトル
.sub-section-title__contents {
	text-align: center;
}

.sub-section-title__en {
	max-width: rem(220);
	width: 100%;
	margin-inline: auto;
	@media screen and (min-width: $tab) {
		max-width: rem(446);
	}
}

.sub-section-title__ja {
	font-weight: 500;
	font-size: rem(18);
	line-height: calc(30 / 18);
	text-align: center;
	@media screen and (min-width: $tab) {
		margin-top: rem(-15);
		font-size: rem(20);
		line-height: calc(30 / 20);
	}
}
