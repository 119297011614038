@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//お客様事例 詳細

.caseStudy-detail__inner {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(930);
        padding-inline: rem(25);
    }
}

.caseStudy-detail__container {
    margin-top: rem(40);
    @media screen and (min-width: $tab) {
        margin-top: rem(30);
    }
}
.caseStudy-detail__content-flex {
    display: flex;
    flex-direction: column;
    max-width: rem(710);
    width: 100%;
    margin-bottom: rem(53);
    @media screen and (min-width: $tab) {
        flex-direction: row;
        max-width: rem(880);
        margin-bottom: rem(75);
    }
}

.caseStudy-detail__img {
    max-width: rem(280);
    width: 100%;
    margin: 0 auto;
    @media screen and (min-width: $tab) {
        max-width: rem(386);
        margin: 0 rem(50) 0 0;
    }
}

.caseStudy-detail__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 280 / 396;
    @media screen and (min-width: $tab) {
        aspect-ratio: 386 / 551;
    }
}

.caseStudy-detail__body {
    margin-top: rem(33);
    max-width: rem(670);
    width: 100%;
    @media screen and (min-width: $tab) {
        margin-top: rem(0);
        max-width: rem(440);
    }
}

.caseStudy-detail__title {
    font-weight: bold;
    font-size: rem(22);
    line-height: calc(29 / 22);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(40);
        line-height: calc(50 / 40);
    }
}

.caseStudy-detail__category-wrap {
    margin-top: rem(5);
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media screen and (min-width: $tab) {
        margin-top: rem(31);
        max-width: rem(440);
    }
}

.caseStudy__category {
    display: inline;
    background: #5f9a4a;
    font-weight: 500;
    font-size: rem(13);
    color: $white;
    line-height: calc(20 / 13);
    padding: 0 rem(20);
    margin-right: rem(8);
    display: flex;
    align-items: center;
}

.caseStudy-detail__auther-wrap {
    margin-top: rem(22);
    @media screen and (min-width: $tab) {
        margin-top: rem(26);
    }
}

.caseStudy-detail__auther-name {
    font-weight: bold;
    font-size: rem(16);
    line-height: calc(28 / 16);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(20);
        line-height: calc(28 / 20);
    }
}
.caseStudy-detail__auther-text {
    margin-top: rem(10);
    font-weight: 400;
    font-size: rem(12);
    line-height: calc(18 / 12);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        margin-top: rem(14);
        font-size: rem(15);
        line-height: calc(30 / 15);
    }
}


