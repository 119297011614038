@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//about01

.about01__inner {
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(950);
        padding-inline: rem(25);
    }
}

.about-section__text {
    margin-top: rem(21);
    @media screen and (min-width: $tab) {
        margin-top: rem(31);
    }
}

.about01__lists {
    margin-top: rem(20);
    @media screen and (min-width: $tab) {
        margin-top: rem(38);
    }
}

.about01__list {
    margin-top: rem(10);
    @media screen and (min-width: $tab) {
        margin-top: rem(36);
    }
}

.about01__title {
    font-weight: bold;
    font-size: rem(17);
    letter-spacing: 0.1em;
    color: $white;
    background-color: $blue;
    padding: rem(5) 0 rem(11);
    @media screen and (min-width: $tab) {
        font-size: rem(20);
        padding: rem(5) 0 rem(7);
    }
}

.about01__text {
    margin-top: rem(13);
    font-size: rem(13);
    font-weight: 400;
    letter-spacing: 0.1em;
    line-height: calc(26 / 13);
    color: $black;
    @media screen and (min-width: $tab) {
        font-size: rem(15);
        line-height: calc(30 / 15);
    }
}
