@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//header-spacer
.header-spacer {
	height: 50px;
	@media screen and (min-width:1060px) {
		height: 104px;
	}
}

//スクロールでヘッダーを隠す
.hide{
	transform: translateY(-100%); /* 上に消えるようにする */
}

#scrollArea{
    position: fixed; /* 固定して表示されるようにする */
    top: 0; /* 表示場所を一番上に */
    transition: .5s; /* スクロール時に一瞬で消えると物足りないので.hideの処理を0.5秒で行う */
    z-index: 100; /* 前面に表示されるようにする */
}


