@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//fadeIn

.js-fadeIn {
    opacity: 0;
    visibility: hidden;
    transform: translateY(50px);
    transition: opacity 0.5s,visibility 0.5s, transform 1s;
}

.scroll {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
}




