@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//お役立ち資料
.top-document__section-title {
    max-width: rem(226);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(487);
    }
}

.top-document__article-content {
    margin-top: rem(30);
}

figure.top-document__article-img1.top-card2__img {
    max-width: rem(335);
    @media screen and (min-width: $tab) {
        max-width: rem(378);
    }
}

figure.top-document__article-img1.top-card2__img img {
    aspect-ratio: 335 / 233;
    @media screen and (min-width: $tab) {
        aspect-ratio: 378 / 263;
    }
}

.top-document__download {
    margin-top: rem(7);
    width: 100%;
    background-color: $sub-black2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(6);
    padding: rem(3) 0;
    @media screen and (min-width: $tab) {
        margin-top: rem(10);
    }
}

span.download-icon img {
    width: rem(21);
    height: rem(15);
}

.top-document__download img {
    width: rem(102);
}

.top-document__category {
    background-color: $brown;
}

.top-document__button {
    margin-top: rem(32);
    justify-content: flex-end;
    @media screen and (min-width: $tab) {
        margin-top: rem(52);
    }
}