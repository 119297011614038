@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.caseStudy-detail-content {
    h3 {
        margin-bottom: rem(31);
        background-image: url("../images/caseStudy-detail/caseStudy-detail-title-bg.webp");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        font-weight: bold;
        font-size: rem(18);
        line-height: calc(27 / 18);
        color: $white;
        padding: 0 rem(4);
        @media screen and (min-width: $tab) {
            margin-bottom: rem(40);
            font-size: rem(26);
            line-height: calc(35 / 26);
            padding: 0 rem(20);
            background-image: url("../images/caseStudy-detail/sp-caseStudy-detail-title-bg.webp");
        }
    }
    p.caseStudy-detail-content__text-l {
        margin-bottom: rem(30);
        font-weight: 400;
        font-size: rem(16);
        line-height: calc(20 / 16);
        color: $sub-black1;
        @media screen and (min-width: $tab) {
            margin-bottom: rem(40);
            font-size: rem(20);
            line-height: 1;
        }
    }
    p.caseStudy-detail-content__text-s {
        margin-bottom: rem(30);
        font-weight: 400;
        font-size: rem(14);
        line-height: calc(28 / 14);
        color: $sub-black1;
        @media screen and (min-width: $tab) {
            margin-bottom: rem(40);
            font-size: rem(15);
            line-height: 2;
        }
    }
    .caseStudy-detail-content__img {
        margin-bottom: rem(30);
        max-width: rem(335);
        width: 100%;
        margin-inline: auto;
        @media screen and (min-width: $tab) {
            margin-bottom: rem(40);
            max-width: rem(880);
        }
    }
    .caseStudy-detail-content__img img {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 335 / 224;
        @media screen and (min-width: $tab) {
            aspect-ratio: 880 / 589;
        }
    }
}
