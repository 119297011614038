@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-top-mv {
    margin-top: rem(0);
    @media screen and (min-width: $tab) {
        margin-top: rem(60);
    }
}
