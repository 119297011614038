@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//出版実績
.top-publication__section-title {
    max-width: rem(266);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(573);
    }
}

.top-publication__top-bg {
    background: url(../images/top/publication-bg.webp) no-repeat center / cover;
}

.top-publication__article-contents {
    display: flex;
    flex-direction: column;
}

.top-publication__article-content {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $tab) {
        flex-direction: row;
    }
}


.top-publication__article-wrap {
    display: flex;
}

.top-publication__article {
    max-width: 100%;
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(285);
    }
}
.top-publication__article2,
.top-publication__article4 {
    @media screen and (min-width: $tab) {
        margin-bottom: rem(30);
    }
}
.top-publication__article3 {
    @media screen and (min-width: $tab) {
        margin-top: rem(-74);
    }
}
.top-publication__article5 {
    @media screen and (min-width: $tab) {
        margin-top: rem(-132);
    }
}
.top-publication__article1,
.top-publication__article3,
.top-publication__article5 {
    @media screen and (min-width: $tab) {
        margin-right: rem(42);
    }
}

.top-publication__article {
    @media screen and (min-width: $tab) {
        max-width: rem(285);
    }
}

figure.top-publication__article-img1.top-card1__img img {
    aspect-ratio: 136 / 154;
    @media screen and (min-width: $tab) {
        aspect-ratio: 285 / 321;
    }
}

figure.top-publication__article-img2.top-card1__img img {
    aspect-ratio: 136 / 154;
    @media screen and (min-width: $tab) {
        aspect-ratio: 285 / 398;
    }
}

figure.top-publication__article-img3.top-card1__img img {
    aspect-ratio: 136 / 154;
    @media screen and (min-width: $tab) {
        aspect-ratio: 285 / 246;
    }
}

figure.top-publication__article-img4.top-card1__img img {
    aspect-ratio: 136 / 154;
    @media screen and (min-width: $tab) {
        aspect-ratio: 285 / 330;
    }
}

figure.top-publication__article-img5.top-card1__img img {
    aspect-ratio: 136 / 154;
    @media screen and (min-width: $tab) {
        aspect-ratio: 285 / 358;
    }
}

figure.top-publication__article-img6.top-card1__img img {
    aspect-ratio: 136 / 154;
    @media screen and (min-width: $tab) {
        aspect-ratio: 285 / 321;
    }
}

.top-publication__article-content:nth-child(1) {
    margin-top: rem(20);
    @media screen and (min-width: $tab) {
        margin-top: rem(0);
    }
}

h3.top-publication__article-title.top-card1__title {
    margin-top: rem(10);
    font-size: rem(13);
    line-height: calc(15 / 13);
    @media screen and (min-width: $tab) {
        font-size: rem(18);
    }
    @media screen and (min-width: 1050px) {
        margin-top: rem(5);
        font-size: rem(22);
        line-height: calc(26 / 22);
    }
}

.top-publication__category {
    background-color: $green;
}

.top-publication__category img {
    max-width: rem(58);
    @media screen and (min-width: $tab) {
        max-width: rem(75);
    }
}

.top-publication__top-button {
    display: inline-flex;
    align-items: center;
}

.top-publication__top-button__arrow {
    left: rem(40);
    @media screen and (min-width: $sp) {
        left: rem(60);
    }
    @media screen and (min-width: $tab) {
        left: rem(80);
    }
}

.top-publication__button {
    justify-content: right;
    padding-right: 5px;
    margin-top: rem(20);
}

.top-card1__top-link:hover .top-publication__top-button__arrow {
    transform: translateX(8px);
}
