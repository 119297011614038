@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//目次
.sub-contents__inner {
    max-width: rem(710);
    width: 100%;
    padding-inline: rem(20);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: rem(930);
        padding-inline: rem(25);
    }
}

.sub-contents__title-content {
    display: flex;
    align-items: center;
}

.sub-contents__title--en {
    max-width: rem(149);
    width: 100%;
    margin-right: rem(19);
    @media screen and (min-width: $tab) {
        max-width: rem(340);
        margin-right: rem(48);
    }
}

.sub-contents__title--ja {
    font-weight: bold;
    font-size: rem(14);
    line-height: calc(20 / 14);
    @media screen and (min-width: $tab) {
        font-size: rem(18);
        line-height: calc(20 / 18);
    }
}

.accordion__title-wrap {
    margin-top: rem(32);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: $tab) {
        margin-top: rem(24);
    }
}

.accordion__button {
    font-family: $space-grotesk;
    font-weight: bold;
    font-size: rem(12);
    line-height: 1;
    color: #6e6e6e;
    @media screen and (min-width: $tab) {
        font-size: rem(23);
    }
}

.accordion__title1 {
    font-weight: bold;
    font-size: rem(15);
    line-height: calc(18 / 15);
    @media screen and (min-width: $tab) {
        font-size: rem(25);
        line-height: calc(30 / 25);
    }
}

.accordion__title {
    margin-top: rem(37);
    font-weight: bold;
    font-size: rem(15);
    line-height: calc(18 / 15);
    @media screen and (min-width: $tab) {
        margin-top: rem(60);
        font-size: rem(25);
        line-height: calc(30 / 25);
    }
}

.accordion__text {
    margin-top: rem(25);
    text-indent: rem(18);
    font-weight: 400;
    font-size: rem(15);
    line-height: calc(20 / 15);
    @media screen and (min-width: $tab) {
        margin-top: rem(30);
        text-indent: rem(32);
        font-size: rem(25);
        line-height: calc(30 / 25);
    }
}

.accordion__text-list {
    margin-top: rem(25);
    @media screen and (min-width: $tab) {
        margin-top: rem(30);
    }
}

.accordion__text-item {
    text-indent: rem(18);
    font-weight: 400;
    font-size: rem(15);
    line-height: calc(20 / 15);
    @media screen and (min-width: $tab) {
        text-indent: rem(32);
        font-size: rem(25);
        line-height: calc(30 / 25);
    }
}

.sub-contents__stripe {
    background: repeating-linear-gradient(-65deg, #fff 3px, #fff 5px, #d0d0d0 5px, #d0d0d0 6px);
    width: 100%;
    height: 13px;
    margin-top: rem(20);
    margin-bottom: rem(20);
}

.sub-contents__stripe.sub-contents__stripe--top {
    margin-top: rem(40);
    @media screen and (min-width: $tab) {
        margin-top: rem(88);
    }
}

.sub-contents__news {
    margin: rem(20) 0;
    @media screen and (min-width: $tab) {
        margin: rem(30) 0;
    }
}

.sub-contents__news-text {
    font-weight: 400;
    font-size: rem(14);
    line-height: calc(20 / 14);
    @media screen and (min-width: $tab) {
        line-height: calc(24 / 14);
    }
}

.sub-contents__pdf {
    margin-top: rem(26);
    font-weight: 400;
    font-size: rem(14);
    line-height: calc(20 / 14);
    color: #0177b4;
    text-decoration: underline;
    @media screen and (min-width: $tab) {
        margin-top: rem(34);
        line-height: calc(24 / 14);
    }
}

.sub-contents__infomation {
    margin: rem(21) 0 rem(20);
    @media screen and (min-width: $tab) {
        margin: rem(31) 0 rem(30);
    }
}

.sub-contents__infomation-title {
    max-width: rem(218);
    width: 100%;
}

.sub-contents__infomation-text {
    margin-top: rem(13);
    font-weight: 400;
    font-size: rem(14);
    line-height: calc(24 / 14);
}
