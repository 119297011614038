@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-about03 {
    margin-top: rem(50);
    @media screen and (min-width: $tab) {
        margin-top: rem(100);
    }
}
