@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//セミナー情報　詳細
.seminer-detail__section-title {
    margin-top: rem(31);
    @media screen and (min-width: $tab) {
        margin-top: rem(25);
    }
}

.seminer-detail__sub-section-title.detail-section-title__en {
    max-width: rem(97);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(186);
    }
}

.seminer-detail__main-contents {
    @media screen and (min-width: $tab) {
        display: flex;
        gap: rem(40);
        margin-left: auto;
        margin-right: 0;
        max-width: 1175px;
        width: 100%;
    }
    @media screen and (min-width: 1281px) {
        margin-right: auto;
    }
}

.seminer-detail__main {
    @media screen and (min-width: $tab) {
        max-width: calc(100% - rem(230));
        width: 100%;
        margin-left: auto;
        padding-left: rem(25);
    }
}

.seminer-detail__inner {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(880);
        width: 100%;
        padding-inline: rem(0);
    }
}

.seminer-detail__menu-content {
    display: none;
    @media screen and (min-width: $tab) {
        display: block;
        margin-top: rem(30);
    }
}

.seminer-detail__content {
    display: flex;
    flex-direction: column;
}

.seminer-detail__head {
    margin-top: rem(26);
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $tab) {
        margin-top: rem(0);
    }
}

.seminer-detail__title {
    font-weight: bold;
    font-size: rem(22);
    line-height: calc(29 / 22);
    color: $yellow;
    border-left: 10px solid $yellow;
    padding-left: rem(5);
    @media screen and (min-width: $tab) {
        position: relative;
        font-size: rem(40);
        line-height: calc(50 / 40);
        padding-top: rem(5);
        padding-bottom: rem(5);
    }
}

.seminer-detail__title::before {
    @media screen and (min-width: $tab) {
        position: absolute;
        content: "";
        background-image: url("../images/seminer-detail/seminer-detail__title-bg.webp");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

.seminer-detail__tag-wrap {
    margin-top: rem(10);
    display: flex;
}

.seminer-detail__tag {
    font-weight: 500;
    font-size: rem(13);
    line-height: calc(20 / 13);
}

.seminer-detail__date-wrap {
    margin-top: rem(10);
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: $tab) {
        margin-top: rem(9);
    }
}

.seminer-detail__date {
    font-weight: 400;
    font-size: rem(12);
    line-height: calc(18 / 12);
    @media screen and (min-width: $tab) {
        font-size: rem(20);
        line-height: 1;
    }
}

.seminer-detail__date:nth-child(1) {
    padding-right: rem(15);
    border-right: 1px solid $txt-color;
    @media screen and (min-width: $tab) {
        padding-right: rem(9);
    }
}

.seminer-detail__date:nth-child(2) {
    padding-left: rem(15);
    @media screen and (min-width: $tab) {
        margin-top: rem(5);
        padding-left: rem(0);
    }
    @media screen and (min-width: 780px) {
        margin-top: rem(0);
        padding-left: rem(9);
    }
}

.seminer-detail__flex {
    display: flex;
    flex-direction: column-reverse;
    @media screen and (min-width: $tab) {
        flex-direction: column;
    }
}

.seminer-detail__sns-wrap {
    margin-top: rem(10);
    @media screen and (min-width: $tab) {
        margin-top: rem(24);
    }
}

.seminer-detail__sns-wrap2.sub-sns__wrap2 {
    margin-top: rem(5);
    @media screen and (min-width: $tab) {
        margin-top: rem(0);
    }
}

.seminer-detail__img {
    margin-top: rem(21);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        margin-top: rem(38);
        max-width: rem(880);
    }
}

.seminer-detail__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 335 / 176;
    @media screen and (min-width: $tab) {
        aspect-ratio: 880 / 462;
    }
}

.seminer-detail__text-wrap {
    margin-top: rem(30);
    font-weight: 400;
    font-size: rem(14);
    line-height: calc(28 / 14);
    order: 5;
    @media screen and (min-width: $tab) {
        margin-top: rem(40);
        font-size: rem(15);
        line-height: calc(30 / 15);
    }
}

.seminer-detail__text + .seminer-detail__text {
    margin-top: rem(30);
    @media screen and (min-width: $tab) {
        margin-top: rem(40);
    }
}

.seminer-detail__changeByEdit__bg.changeByEdit__bg {
    background-color: rgba(208, 182, 39, 0.1);
}
