@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//stripe
.stripe {
    background: repeating-linear-gradient(-65deg, #fff 3px, #fff 5px, #d0d0d0 5px, #d0d0d0 6px);
    width: 100%;
    height: 7px;
    margin-top: rem(20);
    margin-bottom: rem(20);
    @media screen and (min-width: $tab) {
        display: none;
    }
}