@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//お役立ち資料
.document {
    position: relative;
    background-color: #f7f7f7;
    padding: rem(39) rem(8) rem(28);
    text-align: center;
    @media screen and (min-width: $tab) {
        padding: rem(58) rem(25) rem(40);
    }
}

.document::after {
    position: absolute;
    content: "";
    top: rem(39);
    left: 0;
    background-image: url("../images/document/document-mv-bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 83%;
    z-index: 1;
    @media screen and (min-width: $tab) {
        top: rem(58);
    }
}

.document__section-title-contents {
    margin-top: rem(-8);
    z-index: 3;
    position: relative;
    @media screen and (min-width: $tab) {
        margin-top: rem(-36);
    }
}

.document__sub-section-title.sub-section-title__en {
    max-width: rem(188);
    @media screen and (min-width: $tab) {
        max-width: rem(380);
    }
}

.document__section-text.sub-section-title__ja {
    @media screen and (min-width: $tab) {
        margin-top: rem(-3);
    }
}

.document__bg {
    position: relative;
    z-index: 3;
}

//document__mv-imgホバー画像拡大
.document__mv-img {
    overflow: hidden;
}

.document__mv-img img {
    transition: transform 0.5s ease;
}

.document__link:hover .document__mv-img img {
    transform: scale(1.1);
}

.document__inner {
    display: inline-block;
    margin-top: rem(9);
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    border-radius: 13px;
    background-color: #fff;
    padding: rem(20) 0 rem(45);
    @media screen and (min-width: $tab) {
        margin-top: rem(13);
        max-width: rem(1038);
        padding: rem(61) 0 rem(61);
    }
}

.document__mv-img {
    max-width: rem(638);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: rem(589);
    }
}

.document__mv-img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 319 / 176;
    @media screen and (min-width: $tab) {
        aspect-ratio: 589 / 323;
    }
}

.document__mv-title {
    margin-top: rem(10);
    max-width: rem(272);
    width: 100%;
    margin-inline: auto;
    font-weight: bold;
    font-size: rem(16);
    line-height: calc(24 / 16);
    text-align: center;
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        margin-top: rem(29);
        max-width: 100%;
        font-size: rem(28);
        line-height: calc(42 / 28);
    }
}

.document__download {
    display: inline-flex;
    position: absolute;
    bottom: rem(-18);
    left: 50%;
    transform: translateX(-50%);
    max-width: rem(245);
    width: 100%;
    margin-inline: auto;
    background-color: $sub-black2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(4);
    padding: rem(8) 0;
    @media screen and (min-width: $tab) {
        margin-top: rem(-23);
        gap: rem(6);
    }
}

.document__download-icon {
    display: inline-block;
}

span.document__download-icon img {
    width: rem(15);
    height: rem(11);
    @media screen and (min-width: $tab) {
        width: rem(21);
        height: rem(15);
    }
}

.document__download img {
    width: rem(72);
    @media screen and (min-width: $tab) {
        width: rem(102);
    }
}

.document-menu___menu-list {
    margin-top: rem(30);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    max-width: rem(600);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: 970px) {
        margin-top: rem(23);
        flex-direction: row;
        max-width: rem(978);
        padding-inline: rem(25);
    }
}

.document-menu___flex {
    display: flex;
}

.document-menu__item {
    font-weight: bold;
    font-size: rem(14);
    line-height: 1.5;
    @media screen and (min-width: 970px) {
        font-size: rem(16);
        line-height: calc(24 / 16);
    }
}

.document-menu___flex:nth-child(1) > .document-menu__item:nth-child(1) {
    padding: 0 rem(36) 0 0;
    border-right: 1px solid #1c1c1c;
    @media screen and (min-width: 970px) {
        padding: rem(5) rem(74) rem(5)  0;
    }
}
.document-menu___flex:nth-child(1) > .document-menu__item:nth-child(2) {
    padding: 0 0 0 rem(49);
    @media screen and (min-width: 970px) {
        border-right: 1px solid #1c1c1c;
        padding: rem(5) rem(85);
    }
}

.document-menu___flex:nth-child(2) {
    margin-top: rem(10);
    @media screen and (min-width: 970px) {
        margin-top: rem(0);
    }
}

.document-menu___flex:nth-child(2) > .document-menu__item:nth-child(1) {
    padding: 0 rem(42) 0 0;
    border-right: 1px solid #1c1c1c;
    @media screen and (min-width: 970px) {
        padding: rem(5) rem(81);
    }
}

.document-menu___flex:nth-child(2) > .document-menu__item:nth-child(2) {
    padding: 0 0 0 rem(43);
    @media screen and (min-width: 970px) {
        padding: rem(5) 0 rem(5) rem(81);
    }
}

//card-list__linkホバー画像拡大
.card-list__img {
    overflow: hidden;
}

.card-list__img img {
    transition: transform 0.5s ease;
}

.card-list__link:hover .card-list__img img {
    transform: scale(1.1);
}
