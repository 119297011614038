@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-knowledge-detail {
    margin-top: rem(22);
    @media screen and (min-width: $tab) {
        margin-top: rem(30);
    }   
}
