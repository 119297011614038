@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

// セレクトボックス(カスタマイズ)
.sub-selectBox__content {
    display: flex;
    justify-content: center;
}

.select {
    position: relative;
    height: 25px;
    cursor: pointer;
    background-color: #5f9a4a;
    @media screen and (min-width: $tab) {
        height: 29px;
    }
}
.select1 {
    margin-right: rem(9);
    max-width: 130px;
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 126px;
        margin-right: rem(46);
    }
}
.select2 {
    max-width: 130px;
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: 173px;
    }
}
.select__expand {
    width: 0;
    height: 25px;
    position: absolute;
    top: 0;
    right: 0;
    @media screen and (min-width: $tab) {
        height: 29px;
    }
}

// .select__expand::after {
//     content: "▼";
//     position: absolute;
//     top: 50%;
//     right: 10px;
//     transform: translate(-50%, -50%);
//     color: $white;
//     font-size: 10px;
//     pointer-events: none;
//     z-index: 2;
//     transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
// }

.select__expand::after {
    content: "";
    display: block;
    width: 8px; // 画像の幅に合わせて調整してください
    height: 9px; // 画像の高さに合わせて調整してください
    background-image: url('../images/common/triangle-icon.webp'); // 画像のパスを指定してください
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 2;
    transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
}

// .select__expand:hover::after {
//     opacity: 1;
// }

.select__expand:checked::after {
    transform: translate(-50%, -50%) rotate(180deg) scaleX(-1);
}

.select__expandLabel {
    display: block;
    width: 100%;
    height: 25px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    @media screen and (min-width: $tab) {
        height: 29px;
    }
}

.select__close {
    display: none;
}

.select__closeLabel {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
}

.select__items {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 25px;
    @media screen and (min-width: $tab) {
        padding-top: 29px;
    }
}

.select__input {
    display: none;
}

.select__label {
    transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
    display: block;
    height: 0;
    font-size: 9px;
    line-height: 25px;
    overflow: hidden;
    color: $white;
    background-color: #5f9a4a;
    cursor: pointer;
    padding-left: 13px;
    position: relative;
    @media screen and (min-width: $sp) {
        font-size: 11px;
    }
    @media screen and (min-width: $tab) {
        line-height: 29px;
        font-size: rem(13);
        padding-left: 30px;
    }
}

.select__label::before {
    content: "▶︎";
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(-50%, -50%);
    color: $white;
    font-size: 5px;
    @media screen and (min-width: $tab) {
        left: 20px;
        font-size: 8px;
    }
}

span.select__label.select__label-placeholder::before {
    display: none;
}

.select__label-placeholder {
    height: 25px;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: -10px;
    background-color: transparent;
    @media screen and (min-width: $tab) {
        height: 29px;
    }
}

.select__expand:checked + .select__closeLabel {
    display: block;
}

.select__expand:checked + .select__closeLabel + .select__options .select__label {
    height: 25px;
    @media screen and (min-width: $tab) {
        height: 29px;
    }
}

// .select__expand:checked + .select__closeLabel + .select__options .select__label:hover {
//     background-color: #f7f7f7;
// }

.select__expand:checked + .select__closeLabel + .select__expandLabel {
    display: none;
}

.select__input:checked + .select__label {
    height: 25px;
    margin-top: -25px;
    @media screen and (min-width: $tab) {
        height: 29px;
        margin-top: -30px;
    }
}
