@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//about03

.about03__inner {
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(950);
        padding-inline: rem(25);
    }
}

.about03__section-text.about-section__text {
    margin-top: rem(21);
    font-weight: 400;
    font-size: rem(13);
    line-height: calc(26 / 13);
    letter-spacing: 0.1em;
    color: $black;
    @media screen and (min-width: $tab) {
        margin-top: rem(31);
        font-size: rem(15);
        line-height: calc(30 / 15);
    }
}

.about03__img img {
    margin-top: rem(20);
    max-width: rem(600);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        margin-top: rem(31);
        max-width: rem(733);
        padding-inline: 0;
    }
}

.about03__text {
    margin-top: rem(20);
    font-weight: 400;
    font-size: rem(13);
    letter-spacing: 0.1em;
    line-height: calc(26 / 13);
    color: $black;
    @media screen and (min-width: $tab) {
        margin-top: rem(30);
        font-size: rem(15);
        line-height: calc(30 / 15);
    }
}

.about03__text2 {
    margin-top: rem(40);
    @media screen and (min-width: $tab) {
        margin-top: rem(45);
    }
}
