@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

// CHANGE BY EDIT
.changeByEdit__bg {
    width: 100%;
    background:rgba(110,0,84,0.1);
    padding: rem(57) 0 rem(30);
    @media screen and (min-width: $tab) {
        padding: rem(36) 0 rem(61);
    }
}

.changeByEdit__inner {
    max-width: rem(710);
    width: 100%;
    padding-inline: rem(20);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: rem(1128);
        padding-inline: rem(25);
    }
}

.changeByEdit__section-title {
    max-width: rem(187);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(259);
    }
}

.changeByEdit__items {
    margin-top: rem(33);
    display: grid;
	grid-template-columns: 1fr;
    width: 100%;
    gap: rem(35);
    @media screen and (min-width: $tab) {
        margin-top: rem(22);
        grid-template-columns: repeat(3, 1fr);
        gap: rem(32);
    }
}

.changeByEdit__body {
    margin-top: rem(5);
    max-width: rem(632);
    width: 100%;
    padding-inline: rem(16);
    @media screen and (min-width: $tab) {
        margin-top: rem(10);
        max-width: rem(338);
    }
}

.changeByEdit__img {
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(18);
    @media screen and (min-width: $tab) {
        padding-inline: rem(0);
    }
}

.changeByEdit__img img {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 300 / 165;
        @media screen and (min-width: $tab) {
            aspect-ratio: 338 / 186;
        }
}

.changeByEdit__title {
    font-weight: bold;
    font-size: rem(16);
    line-height: calc(24 / 16);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(20);
        line-height: calc(30 / 20);
    }
}

.changeByEdit__article-tag-wrap {
    margin-top: rem(5);
    display: flex;
}

.changeByEdit__article-tag {
    font-weight: 500;
    font-size: rem(13);
    line-height: calc(20 / 13);
    color: $sub-black1;
    margin-right: rem(20);
}


//changeByEdit__linkホバー画像拡大
.changeByEdit__img {
    overflow: hidden;
}

.changeByEdit__img img {
    transition: transform 0.5s ease;
}

.changeByEdit__link:hover .changeByEdit__img img {
    transform: scale(1.1);
}

.changeByEdit__link:hover .knowledge__button__arrow {
    transform: translateX(8px);
}
