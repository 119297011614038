@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.drawer {
	// display: none;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	overflow-y: scroll;
}

.drawer__bg {
	background-image: url("../images/common/drawer-bg-sp.webp");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 48px 0 28px;
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	@media screen and (min-width: 1130px) {
		background-image: url("../images/common/drawer-bg.webp");
		padding: 61px 0 84px;
		height: 100%;
	}
}

.drawer__inner {
	max-width: rem(710);
	width: 100%;
	padding-inline: rem(20);
	@media screen and (min-width: $tab) {
		max-width: rem(1107);
		padding-inline: rem(25);
	}
}

.drawer__logo {
	max-width: rem(370);
	width: 100%;
	@media screen and (min-width: $tab) {
		max-width: rem(1041);
	}
}

.drawer__content {
	margin-top: 29px;
	@media screen and (min-width: $tab) {
		margin-top: 75px;
	}
}

.drawer__nav-lists {
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 1130px) {
		flex-direction: row;
	}
}

.drawer__nav-list:nth-child(1) {
	@media screen and (min-width: 1130px) {
		margin-right: rem(33);
	}
}

.drawer__nav-title {
	margin-top: rem(11);
	display: flex;
	align-items: center;
	@media screen and (min-width: $tab) {
		margin-top: rem(16);
	}
}
.drawer__nav-list:nth-child(1) > a:nth-child(1) > .drawer__nav-title {
	margin-top: 0;
}

.nav-title__en {
	margin-right: rem(16);
	@media screen and (min-width: $tab) {
		margin-right: rem(20);
	}
}

.nav-title__en-about {
	width: rem(80);
	@media screen and (min-width: $tab) {
		width: rem(118);
	}
}

.nav-title__ja {
	font-size: 11px;
	line-height: calc(12 / 11);
	@media screen and (min-width: $tab) {
		font-size: rem(13);
		line-height: calc(20 / 13);
	}
}

.nav-title__en-publication {
	width: rem(156);
	@media screen and (min-width: $tab) {
		width: rem(232);
	}
}

.nav-title__en-6strength {
	width: rem(151);
	@media screen and (min-width: $tab) {
		width: rem(216);
	}
}

.drawer__menu-item {
	margin-top: rem(5);
	font-weight: 900;
	font-size: 11px;
	line-height: calc(12 / 11);
	color: $blue;
	@media screen and (min-width: $tab) {
		margin-top: rem(13);
		font-size: rem(16);
		line-height: calc(19 / 16);
	}
}

.nav-title__en-powerOfEditiing {
	width: rem(233);
	@media screen and (min-width: $tab) {
		width: rem(332);
	}
}

.nav-title__en-caseStudy {
	width: rem(150);
	@media screen and (min-width: $tab) {
		width: rem(214);
	}
}

.nav-title__en-seminer {
	width: rem(113);
	@media screen and (min-width: $tab) {
		width: rem(162);
	}
}

.nav-title__en-knowledge {
	width: rem(152);
	@media screen and (min-width: $tab) {
		width: rem(226);
	}
}

.nav-title__en-document {
	width: rem(152);
	@media screen and (min-width: $tab) {
		width: rem(197);
	}
}

.drawer__menu-info__copyright {
	margin-top: rem(18);
	text-align: right;
	@media screen and (min-width: $tab) {
		margin-top: rem(55);
	}
}


.js-drawer {
    transition: opacity 0.8s;
    opacity: 0;
	visibility: hidden;
}

.js-drawer.fade {
    opacity: 1;
	visibility: visible;
}