@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//企業出版とは

.about__mv-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about__mv-title--en {
    max-width: rem(204);
    width: 100%;
    text-align: center;
    @media screen and (min-width: $tab) {
        max-width: rem(323);
    }
}

.about__mv-title--ja {
    font-size: rem(34);
    line-height: calc(52 / 34);
    @media screen and (min-width: $tab) {
        font-size: rem(54);
        line-height: calc(83 / 54);
    }
}

.about__mv-bg {
    margin-top: rem(32);
    background-color: #efefef;
    padding: rem(52) 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: $tab) {
        margin-top: rem(31);
        padding: rem(94) 0 rem(142);
    }
}

.about__mv-text {
    font-weight: bold;
    font-size: rem(16);
    letter-spacing: 0.1em;
    line-height: calc(29 / 16);
    color: $black;
    text-align: center;
    max-width: rem(1006);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(21);
    @media screen and (min-width: $tab) {
        max-width: rem(944);
        padding-inline: rem(25);
        font-size: rem(22);
        line-height: calc(40 / 22);
    }
}

.about__mv-text2,
.about__mv-text3 {
    margin-top: rem(32);
    @media screen and (min-width: $tab) {
        margin-top: rem(40);
    }
}

