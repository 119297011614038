@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//document-section-title 共通パーツ
.document-section-title__contents {
    max-width: rem(696);
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-inline: auto;
    padding: 0 0 rem(4) rem(6) ;
    border-bottom: 1px solid $sub-black2;
    border-left: 4px solid $sub-black2;
    @media screen and (min-width: $tab) {
        max-width: rem(1120);
        padding: 0 0 rem(4) rem(15);
    }
}

.document-section-title__en {
    font-weight: bold;
    font-size: rem(18);
    line-height: calc(22 / 18);
    @media screen and (min-width: $tab) {
        font-size: rem(30);
        line-height: calc(32 / 30);
    }
}

//section-button
.section-button {
    text-align: right;
}

.section-button__link {
    display: inline-flex;
    align-items: center;
    padding-right: rem(20);
}

.section-button__arrow {
    position: absolute;
    left: 10px;
    background-color: transparent;
    border-radius: 50px;
    width: 16px;
    height: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease;
}

.section-button__arrow {
    border: 1px solid $txt-color;
}

.section-button__link:hover .section-button__arrow {
    transform: translateX(8px);
}

.section-button__text {
    font-family: $roboto;
    font-weight: bold;
    font-size: rem(14);
    line-height: 1;
}

//section-arrow--black
.section-arrow--black {
    position: relative;
    display: inline-block;
    color: $txt-color;
    vertical-align: middle;
    text-decoration: none;
    font-size: 15px;
}
.section-arrow--black::before,
.section-arrow--black::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
}

.section-arrow--black::before {
    left: 2px;
    width: 8px;
    height: 1px;
    background: $txt-color;
}
.section-arrow--black::after {
    left: 5px;
    width: 4px;
    height: 4px;
    border-top: 1px solid $txt-color;
    border-right: 1px solid $txt-color;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
