@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-caseStudy-detail {
    margin-top: rem(34);
    margin-bottom: rem(50);
    @media screen and (min-width: $tab) {
        margin-top: rem(24);
        margin-bottom: rem(59);
    }   
}
