@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.header {
	background-color: $white;
	position: fixed;
	width: 100vw;
	height: auto;
	z-index: 999;
	@media screen and (min-width: 1060px) {
		height: 104px;
	}
}

.header__inner {
	max-width: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	height: 50px;
	padding: 0 rem(13) 0 rem(17);
	margin-inline: auto;
	@media screen and (min-width: 1060px) {
		padding: 0 rem(36) 0 rem(41);
		height: 70px;
		padding: 0;
		border-bottom: 1px solid #bcbcbc;
	}
}

.header__content {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media screen and (min-width: 1060px) {
		padding: 0 rem(36) 0 rem(41);
	}
}

.header__button-wrap {
	max-width: rem(1250);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header__logo {
	display: flex;
	flex-direction: column;
	@media screen and (min-width: $tab) {
		flex-direction: row;
		align-items: center;
	}
}

.header__logo img {
	width: 100%;
	max-width: rem(240);
	@media screen and (min-width: $tab) {
		max-width: rem(344);
		margin-right: rem(25);
	}
}

.header__logo-text {
	font-size: 11px;
	line-height: 1;
	margin-left: rem(15);
	font-weight: 500;
	@media screen and (min-width: 1060px) {
		font-size: rem(13);
		margin-left: 0;
	}
}

.header__button {
	display: none;
	@media screen and (min-width: 1060px) {
		display: block;
		display: flex;
		align-items: center;
		margin-right: rem(26);
		font-size: rem(13);
		max-width: rem(435);
		width: 100%;
	}
}

.header__menu-items {
	display: flex;
	gap: rem(36);
}

.header__menu-item a {
	display: flex;
	align-items: center;
	gap: rem(5);
	padding: 13px 0;
}

.header__aboutUs-icon {
	display: inline-block;
	width: rem(15);
	height: rem(14);
}

.header__contact-icon {
	display: inline-block;
	width: rem(21);
}

.header__quote-icon {
	display: inline-block;
	width: rem(14);
}

.header__search-icon {
	width: rem(14);
	cursor: pointer;
}

.header__menu-info__sns {
	@media screen and (min-width: 1060px) {
		margin-top: rem(21);
	}
}

.header__nav {
	display: none;
	@media screen and (min-width: 1060px) {
		display: block;
		border-bottom: 1px solid #bcbcbc;
		height: 34px;
		display: flex;
		align-items: center;
	}
}

.header__nav-items {
	@media screen and (min-width: 1060px) {
		display: flex;
		align-items: center;
		gap: 6%;
		max-width: rem(1086);
		width: 100%;
		height: inherit;
		padding-inline: rem(20);
		margin-inline: auto;
	}
}

.header__nav-item {
	font-size: rem(13);
	line-height: 1;
	height: inherit;
}

.header__nav-item a {
	display: inline-block;
	padding: 10px 0;
}
