@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-corporate-publishing {
    margin-top: rem(62);
    @media screen and (min-width: $tab) {
        margin-top: rem(64);
    }   
}
