@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//お役立ち記事 資料ダウンロード

.knowledge-download__content {
    background: #eaeaea;
    padding: rem(30) rem(13) rem(30);
    @media screen and (min-width: $tab) {
        padding: rem(43) rem(45) rem(48);
    }
}

.knowledge-download__title {
    font-size: rem(21);
    line-height: calc(29 / 21);
    max-width: rem(309);
    width: 100%;
    margin-inline: auto;
    text-align: center;
    @media screen and (min-width: $tab) {
        font-size: rem(41);
        line-height: calc(55 / 41);
        max-width: rem(791);
    }
}

.knowledge-download__title > p:nth-child(1) {
    font-weight: bold;
    background-color: #272727;
    color: $white;
    padding: rem(6) 0;
}

.knowledge-download__title > p:nth-child(2) {
    font-weight: 400;
    background-color: #eaeaea;
    color: $black;
    border: 1px solid #707070;
    padding: rem(6) 0;
}

.knowledge-download__text {
    margin-top: rem(23);
    text-align: center;
    @media screen and (min-width: $tab) {
        margin-top: rem(35);
    }
}

.knowledge-download__text p {
    font-weight: 400;
    font-size: rem(18);
    line-height: 1;
    @media screen and (min-width: $tab) {
        font-size: rem(23);
    }
}

.knowledge-download__text p:nth-child(2) {
    margin-top: rem(20);
    @media screen and (min-width: $tab) {
        margin-top: rem(25);
    }
}

.knowledge-download__img {
    margin-top: rem(40);
    max-width: rem(618);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        margin-top: rem(32);
        max-width: rem(791);
    }
}

.knowledge-download__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 309 / 162;
    @media screen and (min-width: $tab) {
        aspect-ratio: 791 / 414;
    }
}

.knowledge-download__button-wrap {
    margin-top: rem(42);
    text-align: center;
    @media screen and (min-width: $tab) {
        margin-top: rem(45);
    }
}

.knowledge-download__button {
    display: inline-block;
    background-color: #5c5c5c;
    color: $white;
    font-weight: 500;
    font-size: rem(16);
    line-height: calc(24 / 16);
    padding: rem(12) rem(59);
    @media screen and (min-width: $tab) {
        font-size: rem(23);
        line-height: calc(35 / 23);
        padding: rem(14) rem(85);
    }
}
