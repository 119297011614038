@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//私たちの6つの強み
.top-sixstrength__section-title {
    max-width: rem(248);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(535);
    }
}

.top-sixstrength__top-bg {
    background: url(../images/top/6strength-bg.webp) no-repeat center / cover;
}

.top-sixstrength__category {
    background-color: $blue;
}

.top-sixstrength__top-button {
    display: inline-flex;
    align-items: center;
}

.top-sixstrength__top-button__arrow {
    left: rem(40);
    @media screen and (min-width: $sp) {
        left: rem(60);
    }
    @media screen and (min-width: $tab) {
        left: rem(80);
    }
}

.top-sixstrength__button {
    margin-top: rem(18);
    justify-content: right;
    padding-right: 5px;
    @media screen and (min-width: $tab) {
        margin-top: rem(28);
    }
}

.top-card1__top-link:hover .top-sixstrength__top-button__arrow {
    transform: translateX(8px);
}
