@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//お客様事例 セクション
.sub-caseStudy-detail__bg {
    background:rgba(0,90,45,0.1);
    padding: rem(51) 0 rem(37);
    @media screen and (min-width: $tab) {
        padding: rem(30) 0 rem(85);
    }
}

.sub-caseStudy-detail__inner {
    max-width: rem(710);
    width: 100%;
    padding-inline: rem(20);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: rem(1130);
        padding-inline: rem(25);
    }
}

.sub-caseStudy-detail__section-title {
    max-width: rem(172);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(247);
    }
}

.sub-caseStudy-detail__container {
    margin-top: rem(12);
    grid-template-columns: 1fr;
    gap: 28px;
    row-gap: 56px;
    @media screen and (min-width: $tab) {
        margin-top: rem(28);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 68px;
    }
}
.sub-caseStudy-detail__article {
    margin-top: rem(51);
    max-width: rem(710);
    width: 100%;
    @media screen and (min-width: $tab) {
        margin-top: rem(0);
        max-width: rem(525);
    }
}

.sub-caseStudy-detail__title {
    font-weight: bold;
    font-size: rem(20);
    line-height: calc(24 / 20);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(24);
        line-height: calc(32 / 24);
        margin-left: rem(165);
    }
}

.sub-caseStudy-detail__content {
    margin-top: rem(7);
    display: flex;
    padding-bottom: rem(14);
    border-bottom: 1px solid #707070;
    @media screen and (min-width: $tab) {
        margin-top: rem(0);
        padding-bottom: rem(21);
        margin-top: rem(-30);
        align-items: center;
    }
}

.sub-caseStudy-detail__img {
    max-width: rem(116);
    width: 100%;
    margin-right: rem(7);
    @media screen and (min-width: $tab) {
        max-width: rem(149);
        margin-right: rem(20);
    }
}

.sub-caseStudy-detail__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 116 / 161;
    @media screen and (min-width: $tab) {
        aspect-ratio: 149 / 211;
    }
}

.sub-caseStudy-detail__author-wrap {
    @media screen and (min-width: $tab) {
        display: flex;
        align-items: center;
        margin-top: rem(63);
    }
}

.sub-caseStudy-detail__author-img {
    max-width: rem(80);
    width: 100%;
    border-radius: 50%;
    @media screen and (min-width: $tab) {
        margin-right: rem(19);
        max-width: rem(111);
    }
}

.sub-caseStudy-detail__author-img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1;
}

.sub-caseStudy-detail__author-text {
    margin-top: rem(7);
    font-weight: bold;
    font-size: rem(14);
    line-height: calc(22 / 14);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        margin-top: rem(0);
        font-size: rem(18);
        line-height: calc(28 / 18);
    }
}

.sub-caseStudy-detail__category-content {
    display: flex;
    justify-content: space-between;
}

.sub-caseStudy-detail__category-wrap {
    display: flex;
    flex-wrap: wrap;
    max-width: rem(270);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(338);
    }
}

.sub-caseStudy-detail__category {
    display: inline;
    background: #5f9a4a;
    font-weight: 500;
    font-size: 10px;
    color: $white;
    padding: 0 rem(16);
    margin: rem(7) rem(7) 0 0;
    display: flex;
    align-items: center;
    @media screen and (min-width: $tab) {
        font-size: rem(13);
    }
}

.sub-caseStudy-detail__button {
    display: flex;
    align-items: center;
    padding-right: rem(13);
}

p.sub-caseStudy-detail__button-text.button__text {
    font-family: $roboto;
    font-weight: bold;
    font-size: 10px;
    @media screen and (min-width: $tab) {
        margin-top: rem(0);
        font-size: rem(14);
    }
}

.sub-caseStudy-detail__button__arrow {
    width: rem(12);
    height: rem(12);
    position: relative;
    display: inline-block;
    color: $txt-color;
    vertical-align: middle;
    text-decoration: none;
    font-size: 15px;
    @media screen and (min-width: $tab) {
        width: rem(16);
        height: rem(16);
    }
}

.sub-caseStudy-detail__button__arrow::before,
.sub-caseStudy-detail__button__arrow::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
}

.sub-caseStudy-detail__button__arrow::before {
    left: 2px;
    width: 6px;
    height: 1px;
    background: $txt-color;
    @media screen and (min-width: $tab) {
        left: 3px;
        width: 8px;
    }
}
.sub-caseStudy-detail__button__arrow::after {
    left: 5px;
    width: 2px;
    height: 2px;
    border-top: 1px solid $txt-color;
    border-right: 1px solid $txt-color;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    @media screen and (min-width: $tab) {
        left: 5px;
        width: 5px;
        height: 5px;
    }
}


//sub-caseStudy-detail__linkホバー画像拡大
.sub-caseStudy-detail__img {
    overflow: hidden;
}

.sub-caseStudy-detail__img img {
    transition: transform 0.5s ease;
}

.sub-caseStudy-detail__link:hover .sub-caseStudy-detail__img img {
    transform: scale(1.1);
}

.sub-caseStudy-detail__link:hover .sub-caseStudy-detail__button__arrow {
    transform: translateX(8px);
}




