@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//お役立ち情報
.top-knowledge__section-title {
    max-width: rem(260);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(559);
    }
}

.top-knowledge__article-content {
    margin-top: rem(30);
}

.top-knowledge__category {
    background-color: $purple;
}

.top-knowledge__button {
    margin-top: rem(20);
    justify-content: flex-end;
}
