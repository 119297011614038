@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//ページネーション

.pagination {
    display: flex;
}

.pagination__inner {
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.pagination__item {
    margin-right: rem(16);
}

.pagination__link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(16);
    line-height: calc(20 / 16);
    color: #a7a7a7;
    transition: 0.3s;
    @media screen and (min-width: $tab) {
        font-size: 16px;
        line-height: calc(20 / 16);
    }
}

.pagination__link--active {
    color: $txt-color;
    pointer-events: none;
}

.pagination__link--next,
.pagination__link--prev {
    color: $txt-color;
}

.pagination__item:nth-child(5),
.pagination__item:nth-child(6) {
	display: none;
	@media screen and (min-width: $tab){
		display: block;
	}
}



