@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

/* pcの電話番号発信対応 */
a[href^="tel:"] {
    @media screen and (min-width:$sp) {
        pointer-events: none;
    }
}

/* ホバー */
a {
    text-decoration: none;
    color: inherit;
    transition: opacity 0.3s;
    cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
}

/* ハンバーガーメニュークリックで背景固定 */
body {
    overflow-y: auto;
}
body.is-fixed {
    overflow-y: hidden;
}

/* スクロール中にヘッダーを消す */
.hide{
	transform: translateY(-100%); /* 上に消えるようにする */
}

#scrollArea{
    position: fixed; /* 固定して表示されるようにする */
    top: 0; /* 表示場所を一番上に */
    transition: .5s; /* スクロール時に一瞬で消えると物足りないので.hideの処理を0.5秒で行う */
    z-index: 100; /* 前面に表示されるようにする */
}

/* base-color */
body {
    color: $txt-color;
}

.sp-br {
    @media screen and (min-width: $tab) {
        display: none;
    }
}
.pc-br {
    display: none;
    @media screen and (min-width: $tab) {
        display: block;
    }
}