@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//section-title
.section-title__contents {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #1c1c1c;
    padding-bottom: rem(6);
    @media screen and (min-width: $tab) {
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        padding-bottom: rem(8);
        background-color: $white;
    }
}

.section-title__en {
   @media screen and (min-width: $tab) {
       margin-bottom: rem(-20);
   }
}

.section-title__ja {
font-weight: 900;
font-size: rem(17);
letter-spacing: 0.1em;
color: $black;
@media screen and (min-width: $tab) {
    font-size: rem(24);
}
}

