@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//menu-lists 

.menu-lists {
	padding: rem(46) 0 rem(6);
	background: transparent linear-gradient(179deg, #0097e5 0%, #003c5a 100%) 0% 0% no-repeat padding-box;
	@media screen and (min-width: $tab) {
		padding: rem(128) 0 rem(55);
	}
}

.menu-lists__inner {
	max-width: rem(1090);
	width: 100%;
	margin-inline: auto;
	padding-inline: rem(20);
}

.menu-list {
	display: flex;
	flex-direction: column;
	color: $white;
	@media screen and (min-width: $tab) {
		flex-direction: row;
	}
}

.menu-list__service {
	max-width: rem(320);
	width: 100%;
}

.menu-list__title img {
	width: 100%;
	height: 100%;
}

.menu-list__title--service {
	max-width: rem(96);
	width: 100%;
}
.menu-list__title--news {
	max-width: rem(67);
	width: 100%;
}

.menu-title__en-powerOfEditing img {
	max-width: rem(214);
	width: 100%;
}
.menu-title__en-publication img {
	max-width: rem(148);
	width: 100%;
}
.menu-title__en-seminar img {
	max-width: rem(104);
	width: 100%;
}
.menu-title__en-company img {
	max-width: rem(111);
	width: 100%;
}
.menu-title__en-philosophy img {
	max-width: rem(144);
	width: 100%;
}
.menu-title__en-recruit img {
	max-width: rem(96);
	width: 100%;
}

.menu-list__service > ul,
.menu-list__news > ul {
	margin-top: 11px;
}

.menu-list__service > ul > li,
.menu-list__news > ul > li {
	font-weight: bold;
	font-size: 12px;
}

.menu-list__news-lists > ul > li {
	font-weight: bold;
	font-size: 12px;
}

.menu-list__service > ul > li {
	margin-top: 15px;
}

.menu-list__news > ul > li {
	margin-top: 8px;
}

.menu-list__news {
	margin-top: 47px;
	max-width: rem(296);
	width: 100%;
	@media screen and (min-width: $tab) {
		margin-top: 0;
	}
}

.menu-list__news-lists {
	display: flex;
	@media screen and (min-width: $tab) {
		margin-top: 11px;
	}
}

ul.menu-list__news-list:nth-child(1) {
	padding-right: rem(43);
}

.menu-list__other {
	margin-top: 47px;
	width: 100%;
	@media screen and (min-width: $tab) {
		margin-top: 0;
		max-width: min(33.4375vw, 428px);
	}
}

.menu-list__other-content {
	max-width: rem(315);
	width: 100%;
	@media screen and (min-width: $tab) {
		max-width: min(23.125vw, 314px);
	}
}

.menu-list__menu-title {
	margin-top: 20px;
}
.menu-list__other-content > a:nth-child(1) > div {
	margin-top: 0px;
}

.menu-list__arrow {
	transition: transform 0.5s ease;
}
.menu-list__arrow.arrow {
	position: relative;
}

.menu-list__arrow.arrow::before {
	left: 4px;
	width: 25px;
}

.menu-list__arrow.arrow::after {
	left: 18px;
	width: 8px;
	height: 8px;
}

.menu-title__en {
	max-width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.menu-title__ja {
	margin-top: -5px;
	font-size: 12px;
	line-height: 1;
}

.menu-list__menu-title:hover .menu-list__arrow {
	transform: translateX(8px);
}
