@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//セミナー情報 サイドバー

.seminer-side__category-title {
    background-color: $yellow;
}

.seminer-side__category-list,
.seminer-side__tag-list {
    margin-top: rem(15);
}

.seminer-side__tag {
    margin-top: rem(40);
}

.seminer-side__recommendーarticles {
    margin-top: rem(36);
    @media screen and (min-width: $tab) {
        margin-top: rem(16);
    }
}

//おすすめ記事の配置切り替えメディアクエリ
.seminer-side__recommend-bg.sub-side__articles-bg {
    display: none;
    @media screen and (min-width: $tab) {
        display: block;
    }
}

.sp-seminer-side__recommend-bg.sub-side__articles-bg {
    @media screen and (min-width: $tab) {
        display: none;
    }
}