@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//inner 
.inner {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(1228);
        padding-inline: rem(25);
    }
}



