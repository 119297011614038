@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//about-section

.about-section__number {
    max-width: rem(68);
    width: 100%;
    height: auto;
    margin-inline: auto;
    position: relative;
    margin-bottom: rem(80);
}

.about-section__number::after {
    position: absolute;
    content: "";
    bottom: -80%;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #707070;
    height: rem(69);
}

.about-section__title {
    font-size: rem(26);
    line-height: calc(39 / 26);
    text-align: center;
    color: $blue;
    @media screen and (min-width: $tab) {
        font-size: rem(40);
        line-height: calc(60 / 40);
    }
}

.about-section__text {
    font-weight: bold;
    font-size: rem(20);
    letter-spacing: 0.1em;
    color: $black;
    @media screen and (min-width: $tab) {
        font-size: rem(22);
    }
}
