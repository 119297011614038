@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//menu-info

.menu__info {
	display: flex;
	flex-direction: column;
	text-align: right;
	@media screen and (min-width: 1130px) {
		margin-top: 242px;
		margin-left: -200px;
	}
}

.menu__info-wrap1 {
	display: flex;
	flex-direction: column;
	gap: rem(8);
	@media screen and (min-width: $tab) {
		gap: rem(21);
	}
}

.menu__info-wrap1 a {
	font-size: 10px;
	line-height: calc(15 / 10);
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	@media screen and (min-width: $tab) {
		font-size: rem(13);
		line-height: calc(20 / 13);
	}
}

.menu__info-wrap2 {
	margin-top: rem(12);
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: end;
	@media screen and (min-width: 1130px) {
		margin-top: rem(21);
		flex-direction: column;
	}
}

.menu-info__quote {
	font-size: 10px;
	line-height: calc(15 / 10);
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	@media screen and (min-width: $tab) {
		font-size: rem(13);
		line-height: calc(13 / 20);
	}
}

.menu-info__quote-icon {
	max-width: rem(12);
	width: 100%;
	margin-right: rem(4);
	color: $black;
	@media screen and (min-width: $tab) {
		max-width: rem(14);
		margin-right: rem(5);
	}
}

.menu-info__contact {
	margin-top: rem(6);
	font-size: 10px;
	line-height: calc(15 / 10);
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	@media screen and (min-width: $tab) {
		font-size: rem(13);
		line-height: calc(13 / 20);
	}
}

.menu-info__contact-icon {
	max-width: rem(18);
	width: 100%;
	margin-right: rem(4);
	@media screen and (min-width: $tab) {
		max-width: rem(21);
		width: 100%;
		margin-right: rem(5);
	}
}

.menu-info__search {
	width: 151px;
	height: 19px;
	display: flex;
	justify-content: flex-end;
	margin-left: auto;
}

#form {
	position: relative;
	max-width: 151px;
	width: 100%;
	height: inherit;
	border-radius: 10px;
	background-color: transparent;
	padding-right: 18px;
}
#sbox {
	height: inherit;
	padding: 0 10px;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 10px;
	border: 1px solid $black;
	outline: 0;
	background-color: transparent;
	color: $black;
	width: 100%;
}
#sbox::placeholder {
	color: $black;
}
button#sbtn {
	border: none;
	background: none;
	position: absolute;
	top: 0;
	right: 10px;
	height: inherit;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
#sbtn::before {
	content: "";
	width: 13px;
	height: 13px;
	background-image: url("../images/common/search-icon.webp");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	flex-shrink: 0;
	z-index: 10;
}
.menu-info__search-icon:hover {
	color: #7fbfff;
}

.menu-info__sns {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.menu-info__sns-icon--x {
	width: 27px;
}

.menu-info__sns-icon--facebook {
	width: 30px;
}

a.menu-info__sns-icon {
	font-family: $roboto;
	font-weight: bold;
	font-size: 13px;
	line-height: calc(13 / 33);
}

.menu-info__sns > div:nth-child(1) {
	margin-right: rem(36);
}

.menu-info__sns > div:nth-child(2) {
	margin-right: rem(19);
}

.menu-info__copyright {
	font-size: 11px;
	line-height: 1;
}
