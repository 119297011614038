@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-marketing {
    margin-top: rem(80);
    margin-bottom: rem(80);
    @media screen and (min-width: $tab) {
        margin-top: rem(73);
        margin-bottom: rem(150);
    }   
}
