@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//一覧はこちらボタンのアイコン
.icon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.icon__flame {
	position: relative;
	width: 10px;
	height: 10px;
	border: 1px solid black;
}

.icon__background {
	position: absolute;
	top: -4px;
	right: -4px;
	width: 8px;
	height: 8px;
	background-color: $white;
}

.icon__arrow {
	position: relative;
	width: 100%;
	height: 100%;
	transition: all 0.3s;

	.icon__bar {
		&:nth-child(1) {
			position: absolute;
			top: 50%;
			width: 8px;
			height: 1px;
			background-color: $txt-color;
			transform: rotate(-45deg);
		}
		&:nth-child(2) {
			position: absolute;
			top: 5%;
			right: 0;
			width: 6px;
			height: 1px;
			background-color: $txt-color;
		}

		&:nth-child(3) {
			position: absolute;
			top: 5%;
			right: 0;
			width: 1px;
			height: 6px;
			background-color: $txt-color;
		}
	}
}

//一覧はこちらボタンのアイコンホバーの動き
.works__icon-wrap:hover .icon__arrow {
	/* 右ななめ上 */
	transform: translate(2px, -2px);
}

.seminer__icon-wrap:hover .icon__arrow {
	/* 右ななめ上 */
	transform: translate(2px, -2px);
}

.icon__text {
	font-family: $roboto;
	font-weight: bold;
	font-size: 14px;
	line-height: calc(36 / 14);
	display: inline-flex;
	@media screen and (min-width: $tab) {
		font-size: 16px;
		line-height: calc(41 / 16);
	}
}
