@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//お役立ち資料 詳細

.document-detail__inner {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(18);
    @media screen and (min-width: $tab) {
        max-width: rem(1130);
        padding-inline: rem(25);
    }
}

.document-detail__container {
    margin-top: rem(20);
    display: flex;
    flex-direction: column;
    border: 1px solid #ededed;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    @media screen and (min-width: $tab) {
        margin-top: rem(29);
        flex-direction: row;
    }
}

.document-detail__img-content {
    max-width: rem(638);
    width: 100%;
    margin-inline: auto;
    padding: rem(40) rem(8) rem(42);
    @media screen and (min-width: $tab) {
        max-width: rem(583);
        padding: rem(88) rem(46) rem(91) rem(40);
    }
}

.document-detail__title {
    font-weight: bold;
    font-size: rem(16);
    line-height: calc(24 / 16);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(30);
        line-height: calc(46 / 30);
        color: $txt-color;
    }
}

.document-detail__img {
    width: 100%;
    margin-top: rem(10);
    @media screen and (min-width: $tab) {
        max-width: rem(500);
        margin-top: rem(27);
    }
}

.document-detail__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 319 / 176;
    @media screen and (min-width: $tab) {
        aspect-ratio: 500 / 275;
    }
}

.document-detail__text-content {
    background-color: #f0f0f0;
    background-image: url("../images/document-detail/document-detail-bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: rem(638);
    width: 100%;
    height: 100%;
    padding: rem(58) rem(10) rem(59) rem(13);
    @media screen and (min-width: $tab) {
        max-width: rem(497);
        padding: rem(164) rem(32) rem(131) rem(41);
    }
}

.document-detail__text-title {
    font-weight: bold;
    font-size: rem(16);
    line-height: calc(24 / 16);
    color: #4e2e12;
    padding-bottom: rem(2);
    border-bottom: 4px solid #4e2e12;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: rem(23);
    @media screen and (min-width: $tab) {
        font-size: rem(18);
        line-height: calc(28 / 18);
    }
}

.document-detail__text-title::before {
    position: absolute;
    content: "";
    left: 0;
    width: rem(17);
    height: rem(17);
    margin-right: rem(5);
    background-image: url("../images/document-detail/document-detail-icon.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
}

.document-detail__list {
    margin-top: rem(14);
    font-weight: 400;
    font-size: rem(14);
    line-height: calc(22 / 14);
    list-style: decimal;
    padding-left: rem(15);
    @media screen and (min-width: $tab) {
        margin-top: rem(13);
        font-size: rem(15);
        line-height: calc(30 / 15);
    }
}
