@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//セミナー情報　プロフィール

.seminer-detail-profile__content {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    background-color: #f0f0f0;
    padding: 0;
    @media screen and (min-width: 1120px) {
        max-width: rem(930);
        margin-bottom: rem(104);
    }
}

.seminer-detail-profile__flex {
    display: flex;
}

.seminer-detail-profile__img {
    max-width: rem(336);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: 1120px) {
        max-width: rem(345);
    }
}

.seminer-detail-profile__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 168 / 286;
    @media screen and (min-width: 1120px) {
        aspect-ratio: 345 / 589;
    }
}

.seminer-detail-profile__title-content {
    background-color: #f0f0f0;
    padding: 0 0 0 rem(9);
    max-width: 100%;
    width: 100%;
    @media screen and (min-width: 1120px) {
        max-width: 100%;
        padding: rem(42) rem(41) rem(43) rem(32);
    }
}

.seminer-detail-profile__title-wrap {
    margin-top: rem(66);
    @media screen and (min-width: 1120px) {
        margin-top: rem(0);
        display: flex;
        align-items: center;
        gap: rem(19);
    }
}

.seminer-detail-profile__title--en {
    max-width: rem(96);
    width: 100%;
    @media screen and (min-width: 1120px) {
        max-width: rem(126);
    }
}

.seminer-detail-profile__title--ja {
    font-weight: bold;
    font-size: rem(12);
    line-height: calc(19 / 12);
    @media screen and (min-width: 1120px) {
        font-size: rem(15);
        line-height: calc(23 / 15);
    }
}

.seminer-detail-profile__title-name1 {
    margin-top: rem(37);
    font-weight: bold;
    font-size: rem(17);
    line-height: calc(23 / 17);
    @media screen and (min-width: 1120px) {
        margin-top: rem(12);
        font-size: rem(20);
        line-height: calc(30 / 20);
    }
}

.seminer-detail-profile__title-name2 {
    margin-top: rem(32);
    font-weight: bold;
    font-size: rem(15);
    line-height: calc(23 / 15);
    @media screen and (min-width: 1120px) {
        margin-top: rem(0);
        font-size: rem(20);
        line-height: calc(30 / 20);
    }
}

.seminer-detail-profile__text-body {
    padding: rem(21) rem(12) rem(23);
    @media screen and (min-width: 1120px) {
        margin-top: rem(-400);
        margin-left: rem(377);
        padding: 0;
        max-width: rem(455);
    }
}

.seminer-detail-profile__text {
    font-weight: 400;
    font-size: rem(14);
    line-height: 2;
    @media screen and (min-width: 1120px) {
        font-size: rem(15);
        padding: 0;
    }
}
