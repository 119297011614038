@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-knowledge-others {
    margin-top: rem(81);
    margin-bottom: rem(80);
    @media screen and (min-width: $tab) {
        margin-top: rem(14);
        margin-bottom: rem(100);
    }   
}
