@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-top-document {
    margin-top: rem(80);
    margin-bottom: rem(42);
    @media screen and (min-width: $tab) {
        margin-top: rem(72);
        margin-bottom: rem(108);
    }
}
