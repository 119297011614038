@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-seminer-popular-articles {
    margin-top: rem(51);
    @media screen and (min-width: $tab) {
        margin-top: rem(44);
    }   
}
