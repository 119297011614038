@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//下層ページ　サイドバー

.sub-side {
    width: 100%;
    @media screen and (min-width: $tab) {
        margin-top: rem(51);
        max-width: rem(230);
    }
}

.sub-side__category,
.sub-side__tag {
    display: none;
    @media screen and (min-width: $tab) {
        display: block;
    }
}

p.sub-side-category-title {
    font-weight: bold;
    font-size: rem(20);
    line-height: 1.3;
    color: $white;
    padding: 0 rem(11);
}

.sub-side__category-list {
    font-weight: bold;
    font-size: rem(16);
    line-height: calc(24 / 16);
    color: $white;
}

.sub-side__category-item {
    margin-top: rem(10);
    color: $txt-color;
}

.sub-side__category-item + .sub-side__category-item {
    margin-top: rem(15);
}

.knowledge-side__tag {
    margin-top: rem(40);
}

.sub-side__tag-title {
    font-weight: bold;
    font-size: rem(20);
    line-height: 1.3;
    background-color: #f0f0f0;
    padding: 0 rem(11);
}

.sub-side__tag-list {
    display: flex;
    flex-wrap: wrap;
}

.sub-side__tag-item {
    font-weight: 500;
    font-size: rem(13);
    line-height: calc(20 / 13);
    padding-right: rem(20);
}

.sub-side__tag-item + .sub-side__tag-item + .sub-side__tag-item {
    margin-top: rem(10);
}

//下層ページサイドバー sub-side__card-list
.sub-side__articles-bg {
    background-color: #e2e2e2;
    padding-top: rem(58);
    padding-bottom: rem(59);
    @media screen and (min-width: $tab) {
        padding-top: rem(0);
    padding-bottom: rem(0);
        background-color: transparent;
    }
}
.sub-side__articles-inner {
    max-width: rem(702);
    width: 100%;
    padding-inline: rem(23);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: 100%;
        padding-right: rem(26);
        padding-left: 0;
    }
}

.sub-side__articles-title {
    font-weight: bold;
    font-size: rem(26);
    line-height: calc(30 / 26);
    color: $sub-black2;
    @media screen and (min-width: $tab) {
        margin-top: rem(51);
        font-size: rem(20);
        line-height: calc(36 / 20);
        color: $txt-color;
    }
}

.sub-side__card-list {
    margin-top: rem(41);
    display: flex;
    flex-direction: column;
}

.sub-side__card-list-item {
    margin-top: rem(35);
    position: relative;
    max-width: rem(656);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: rem(204);
    }
}
a.sub-side__card-list-link:nth-child(1) > article {
    margin-top: rem(0);
}

.sub-side__card-list-img {
    max-width: rem(618);
    width: 100%;
    margin-inline: auto;
    padding: 0 rem(14);
    @media screen and (min-width: $tab) {
        max-width: rem(209);
        padding: 0;
    }
}

.sub-side__card-list-img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 300 / 165;
    @media screen and (min-width: $tab) {
        aspect-ratio: 204 / 112;
    }
}

.sub-side__card-list-body {
    margin-top: rem(5);
}

.sub-side__card-list-title {
    font-size: rem(16);
    line-height: calc(24 / 16);
    font-weight: bold;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: $sub-black1;

    @media screen and (min-width: $tab) {
        font-size: rem(13);
        line-height: calc(18 / 18);
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
    }
}

.sub-side__card-list-tag-wrap {
    margin-top: rem(5);
    display: flex;
    @media screen and (min-width: $tab) {
        display: none;
    }
}

.sub-side__card-list-tag {
    font-weight: 500;
    font-size: rem(13);
    line-height: calc(20 / 13);
    color: $sub-black1;
    margin-right: rem(20);
    @media screen and (min-width: $tab) {
        display: none;
    }
}

//sub-side__card-list-linkホバー画像拡大
.sub-side__card-list-img {
    overflow: hidden;
}

.sub-side__card-list-img img {
    transition: transform 0.5s ease;
}

.sub-side__card-list-link:hover .sub-side__card-list-img img {
    transform: scale(1.1);
}


