@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-top-powerOfEditing {
    margin-top: rem(116);
    @media screen and (min-width: $tab) {
        margin-top: rem(73);
    }
}
