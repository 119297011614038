@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//arrow--white
.arrow--white {
	display: inline-block;
	color: $white;
	vertical-align: middle;
	text-decoration: none;
	font-size: 15px;
}
.arrow--white::before,
.arrow--white::after {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	content: "";
	vertical-align: middle;
}

.arrow--white::before {
	left: 2px;
	width: 8px;
	height: 1px;
	background: $white;
	@media screen and (min-width: $tab) {
		left: 4px;
		width: 12px;
	}
}
.arrow--white::after {
	left: 5px;
	width: 4px;
	height: 4px;
	border-top: 1px solid $white;
	border-right: 1px solid $white;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	@media screen and (min-width: $tab) {
		left: 8px;
		width: 8px;
		height: 8px;
	}
}

//arrow--black
.arrow--black {
	position: relative;
	display: inline-block;
	color: $txt-color;
	vertical-align: middle;
	text-decoration: none;
	font-size: 15px;
}
.arrow--black::before,
.arrow--black::after {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	content: "";
	vertical-align: middle;
}

.arrow--black::before {
	left: 2px;
	width: 8px;
	height: 1px;
	background: $txt-color;
	@media screen and (min-width: $tab) {
		left: 4px;
		width: 12px;
	}
}
.arrow--black::after {
	left: 5px;
	width: 4px;
	height: 4px;
	border-top: 1px solid $txt-color;
	border-right: 1px solid $txt-color;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	@media screen and (min-width: $tab) {
		left: 8px;
		width: 8px;
		height: 8px;
	}
}

