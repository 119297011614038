@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

._layout-seminer-document-form {
    margin-bottom: rem(30);
    @media screen and (min-width: $tab) {
        margin-bottom: rem(50);
    }   
}
