@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//お役立ち記事　詳細
.knowledge-detail__section-title {
    margin-top: rem(31);
    @media screen and (min-width: $tab) {
        margin-top: rem(25);
    }
}

.knowledge-detail__main-contents {
    @media screen and (min-width: $tab) {
        display: flex;
        gap: rem(40);
        margin-inline: auto;
        max-width: 1150px;
        width: 100%;
    }
}

.knowledge-detail__main {
    @media screen and (min-width: $tab) {
       width: 76.5217%;
        margin-left: auto;
        padding-left: rem(25);
    }
}

.knowledge-detail-selectBox__content.sub-selectBox__content {
    margin-top: rem(51);
}

.knowledge-detail__inner {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(880);
        width: 100%;
        padding-inline: rem(0);
    }
}

.knowledge-detail__menu-content {
    display: none;
    @media screen and (min-width: $tab) {
        display: block;
        margin-top: rem(30);
    }
}

.knowledge-detail__content {
    display: flex;
    flex-direction: column;
}

.knowledge-detail__head {
    margin-top: rem(20);
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $tab) {
        margin-top: rem(0);
    }
}

.knowledge-detail__title {
    font-weight: bold;
    font-size: rem(22);
    line-height: calc(29 / 22);
    color: $purple;
    border-left: 10px solid $purple;
    padding-left: rem(5);
    @media screen and (min-width: $tab) {
        font-size: rem(40);
        line-height: calc(50 / 40);
        padding-left: rem(15);
        padding-top: rem(5);
        padding-bottom: rem(5);
    }
}

.knowledge-detail__tag-wrap {
    margin-top: rem(10);
    display: flex;
}

.knowledge-detail__tag {
    font-weight: 500;
    font-size: rem(13);
    line-height: calc(20 / 13);
}

.knowledge-detail__date-wrap {
    margin-top: rem(3);
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: $tab) {
        margin-top: rem(9);
    }
}

.knowledge-detail__date {
    font-weight: 400;
    font-size: rem(12);
    line-height: calc(18 / 12);
    @media screen and (min-width: $tab) {
        font-size: rem(20);
        line-height: 1;
    }
}

.knowledge-detail__date:nth-child(1) {
    padding-right: rem(15);
    border-right: 1px solid $txt-color;
    @media screen and (min-width: $tab) {
        padding-right: rem(9);
    }
}

.knowledge-detail__date:nth-child(2) {
    padding-left: rem(15);
    @media screen and (min-width: $tab) {
        margin-top: rem(5);
        padding-left: rem(0);
    }
    @media screen and (min-width: 789px) {
        margin-top: rem(0);
        padding-left: rem(9);
    }
}

.knowledge-detail__flex {
    display: flex;
    flex-direction: column-reverse;
    @media screen and (min-width: $tab) {
        flex-direction: column;
    }
}

.knowledge-detail__sns-wrap {
    margin-top: rem(10);
    @media screen and (min-width: $tab) {
        margin-top: rem(24);
    }
}

.knowledge-detail__img {
    margin-top: rem(21);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        margin-top: rem(61);
        max-width: rem(880);
    }
}

.knowledge-detail__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 880 / 462;
}

.knowledge-detail__text-wrap {
    margin-top: rem(30);
    font-weight: 400;
    font-size: rem(14);
    line-height: calc(28 / 14);
    order: 5;
    @media screen and (min-width: $tab) {
        margin-top: rem(40);
        font-size: rem(15);
        line-height: calc(30 / 15);
    }
}

.knowledge-detail__text + .knowledge-detail__text {
    margin-top: rem(30);
    @media screen and (min-width: $tab) {
        margin-top: rem(40);
    }
}

.knowledge-detail__banner {
    margin-top: rem(30);
    max-width: rem(670);
    width: 100%;
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        margin-top: rem(41);
        max-width: rem(880);
    }
}
