@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//出版実績
.publication-detail__inner {
    max-width: rem(710);
    width: 100%;
    padding-inline: rem(20);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: rem(930);
        padding-inline: rem(25);
    }
}

.publication-detail__book-title {
    max-width: rem(271);
    width: 100%;
    margin-top: rem(40);
    font-weight: bold;
    font-size: rem(14);
    letter-spacing: 0.3em;
    line-height: calc(15 / 14);
    color: #8b7086;
    @media screen and (min-width: $tab) {
        margin-top: rem(31);
        font-size: rem(20);
        line-height: calc(22 / 20);
    }
}

.publication-detail__container {
    margin-top: rem(20);
    @media screen and (min-width: $tab) {
        margin-top: rem(29);
    }
}

.publication-detail__content-flex {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $tab) {
        flex-direction: row;
    }
}

.publication-detail__img {
    max-width: rem(271);
    width: 100%;
    margin: 0 auto;
    @media screen and (min-width: $tab) {
        max-width: rem(388);
        margin: 0 rem(52) 0 0;
    }
}

.publication-detail__img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 271 / 415;
    @media screen and (min-width: $tab) {
        aspect-ratio: 387 / 556;
    }
}

.publication-detail__body {
    margin-top: rem(40);
    @media screen and (min-width: $tab) {
        margin-top: rem(0);
    }
}

.publication-detail__title {
    font-weight: bold;
    font-size: rem(22);
    line-height: calc(29 / 22);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(30);
        line-height: calc(40 / 30);
    }
}

.publication-detail__list {
    margin-top: rem(21);
    @media screen and (min-width: $tab) {
        margin-top: rem(71);
    }
}

.publication-detail__item {
    font-weight: 400;
    font-size: rem(12);
    line-height: calc(26 / 12);
    color: #1c1c1c;
    @media screen and (min-width: $tab) {
        font-size: rem(15);
        line-height: calc(30 / 15);
    }
}

.publication-detail__tag-wrap {
    margin-top: rem(22);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: rem(11);
    grid-column-gap: rem(16);
    @media screen and (min-width: $tab) {
        margin-top: rem(43);
        grid-row-gap: rem(14);
        grid-column-gap: rem(48);
    }
}

.publication-detail__tag1,
.publication-detail__tag3 {
    max-width: rem(160);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(197);
    }
}

.publication-detail__tag2,
.publication-detail__tag4 {
    max-width: rem(158);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(195);
    }
}

.publication-detail__tag3,
.publication-detail__tag4 {
    font-weight: bold;
    font-size: rem(15);
    line-height: calc(26 / 15);
    text-align: center;
    color: $white;
    border-radius: 5px;
    padding: 8px 0;
    @media screen and (min-width: $tab) {
        font-size: rem(20);
        line-height: calc(35 / 20);
    }
}

.publication-detail__tag3 {
    background: #4f89a8;
}

.publication-detail__tag4 {
    background: #a88d4f;
}

.publication-detail__content-text {
    margin-top: rem(50);
    @media screen and (min-width: $tab) {
        margin-top: rem(45);
    }
}

.publication-detail__subtitle {
    font-weight: bold;
    font-size: rem(18);
    line-height: calc(27 / 18);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(26);
        line-height: calc(35 / 26);
    }
}

.publication-detail__subtext-content {
    margin-top: rem(21);
    @media screen and (min-width: $tab) {
        margin-top: rem(31);
    }
}

.publication-detail__subtext {
    font-weight: 400;
    font-size: rem(14);
    line-height: calc(24 / 14);
    @media screen and (min-width: $tab) {
    }
}

.publication-detail__subtext2,
.publication-detail__subtext3,
.publication-detail__subtext4 {
    margin-top: rem(33);
}

.publication-detail__author-content {
    margin-top: rem(44);
    border-left: 9px solid #005a2d;
    background: repeating-linear-gradient(-65deg, #eaeaea 2px, #eaeaea 5px, #f8f8f8 5px, #f8f8f8 6px);
    width: 100%;
    height: auto;
    padding: rem(35) rem(22) rem(26) rem(20);
    @media screen and (min-width: $tab) {
        margin-top: rem(53);
        padding: rem(37) rem(39) rem(54) rem(52);
    }
}

.publication-detail__author-title {
    font-weight: bold;
    font-size: rem(12);
    line-height: calc(20 / 12);
    @media screen and (min-width: $tab) {
        font-size: rem(13);
        line-height: calc(20 / 13);
    }
}

.publication-detail__author-name {
    font-weight: bold;
    font-size: rem(18);
    line-height: calc(20 / 28);
    @media screen and (min-width: $tab) {
        margin-top: rem(11);
        font-size: rem(20);
        line-height: 1;
    }
}

.publication-detail__author-text {
    margin-top: rem(21);
    font-weight: 400;
    font-size: rem(14);
    line-height: calc(20 / 14);
    @media screen and (min-width: $tab) {
        margin-top: rem(11);
        font-size: rem(14);
        line-height: calc(24 / 14);
    }
}
