@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.pc-footer__inner {
	display: none;
	@media screen and (min-width: $tab){
		display: block;
		max-width: rem(1101);
		width: 100%;
		margin-inline: auto;
		padding: 64px rem(25) 54px rem(25);
	}
}

.pc-footer__logo-wrap {
	@media screen and (min-width: $tab) {
		display: flex;
		justify-content: space-between;
	}
}

.pc-footer__logo {
	max-width: rem(344);
	width: 100%;
}

nav.pc-footer__menu-lists {
	@media screen and (min-width: $tab) {
		height: 645px;
	}
}

.pc-footer__nav {
	@media screen and (min-width: $tab){
		margin-top: rem(70);
		display: flex;
		flex-direction: column;
		gap: rem(20);
	}
}

.pc-footer__nav-items {
	@media screen and (min-width: $tab) {
		display: flex;
		gap: 2%;
		max-width: rem(728);
		width: 100%;
	}
}

.pc-footer__button-wrap {
	@media screen and (min-width: $tab) {
		margin-top: rem(25);
		display: flex;
		justify-content: flex-end;
	}
}

.pc-footer__quote {
	margin-right: rem(50);
}

.pc-footer__button {
	display: none;
	@media screen and (min-width: 1060px) {
		display: block;
		display: flex;
		align-items: center;
		margin-right: rem(26);
		font-size: rem(13);
		line-height: 1;
	}
}

.pc-footer__menu-info-wrap {
	@media screen and (min-width: $tab) {
		margin-top: rem(127);
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}
}

.pc-footer__menu-bottom {
	display: flex;
	flex-direction: column;
	gap: rem(20);
}


