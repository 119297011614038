@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-document-form {
    margin-top: rem(55);
    margin-bottom: rem(80);
    @media screen and (min-width: $tab) {
        margin-top: rem(93);
        margin-bottom: rem(150);
    }   
}
