@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//お役立ち記事

.knowledge__inner {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(1130);
        padding-inline: rem(25);
    }
}

.knowledge__menu-content {
    display: none;
    @media screen and (min-width: 1120px) {
        display: block;
        margin-top: rem(30);
    }
}

.knowledge__menu-list ul {
    margin-top: rem(20);
    display: flex;
    font-weight: bold;
    font-size: rem(16);
    line-height: calc(24 / 16);
}

.knowledge__menu-item {
    padding: rem(5) rem(29);
    border-right: 1px solid #1c1c1c;
}

.knowledge__menu-list > ul > li:nth-child(1) {
    padding-left: rem(24);
}

.knowledge__menu-list > ul > li:nth-child(7) {
    border-right: none;
}

.knowledge__tag-list ul {
    background: #f0f0f0;
    padding: rem(23) rem(70) rem(24) rem(45);
    margin-top: rem(18);
    display: flex;
    gap: rem(19);
    font-weight: 500;
    font-size: rem(13);
    line-height: calc(20 / 13);
}

.knowledge-selectBox__content.sub-selectBox__content {
    margin-top: rem(21);
    display: flex;
    @media screen and (min-width: 1120px) {
        display: none;
    }
}

//knowledge-card

.knowledge-card__article-content {
    margin-top: rem(25);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: rem(35);
    @media screen and (min-width: $tab) {
        max-width: rem(1176);
        grid-template-columns: repeat(3, 1fr);
        gap: rem(21);
    }
}

.knowledge-card__img {
    max-width: rem(600);
    width: 100%;
    padding-inline: rem(14);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: rem(338);
        padding-inline: rem(0);
    }
}

.knowledge-card__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 300 / 165;
    @media screen and (min-width: $tab) {
        aspect-ratio: 338 / 186;
    }
}

.knowledge-card__body {
    margin-top: rem(5);
    max-width: rem(600);
    width: 100%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media screen and (min-width: $tab) {
        margin-top: rem(10);
        max-width: 100%;
    }
}

.knowledge-card__title {
    font-weight: bold;
    font-size: rem(16);
    line-height: calc(24 / 16);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(20);
        line-height: calc(30 / 20);
    }
}

.knowledge-card__article-tag-wrap {
    display: flex;
    gap: rem(20);
}

.knowledge-card__article-tag {
    margin-top: rem(5);
    font-weight: 500;
    font-size: rem(13);
    line-height: calc(20 / 13);
}

//knowledge-card__linkホバー画像拡大
.knowledge-card__img {
    overflow: hidden;
}

.knowledge-card__img img {
    transition: transform 0.5s ease;
}

.knowledge-card__link:hover .knowledge-card__img img {
    transform: scale(1.1);
}

.knowledge-card__link:hover .knowledge__button__arrow {
    transform: translateX(8px);
}
