@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//top-card1
.is-sticky {
    @media screen and (min-width: $tab) {
    position: -webkit-sticky; /* Safariのサポート */
    position: sticky;
    top: 0; /* 画面の上部に固定 */
    z-index: 10; /* 必要に応じて他の要素より前面に */
    }
}

.top-card1__sticky-container {
    margin-top: rem(20);
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $tab) {
        margin-top: rem(45);
        flex-direction: row;
        gap: rem(42);
        min-height: 759px;
    }
}

.top-card1__content--main {
    width: 100%;
    @media screen and (min-width: $tab) {
        position: sticky;
        top: 149px; /* 画面の上端に固定 */
        height: 100vh;
        width: 44.3877%;
    }
}

.top-card1__bg {
    position: relative;
    display: flex;
    align-items: center;
    max-width: rem(670);
    width: 100%;
    object-fit: cover;
    justify-content: center;
    aspect-ratio: 335 / 458;
    @media screen and (min-width: $tab) {
        max-width: rem(522);
        aspect-ratio: 522 / 570;
    }
}
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(52, 53, 83, 0.45);
}

.top-card1__text-content {
    position: relative;
    max-width: rem(267);
    width: 100%;
    @media screen and (min-width: 400px) {
        max-width: 100%;
        padding-inline: rem(20);
    }
    @media screen and (min-width: $tab) {
        max-width: rem(300);
    }
    @media screen and (min-width: 1050px) {
        max-width: rem(417);
    }
}

.top-card1__title--en {
    max-width: rem(249);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(378);
    }
}

.top-card1__title--ja {
    font-weight: 900;
    font-size: rem(15);
    letter-spacing: 0.1em;
    color: $white;
    @media screen and (min-width: $tab) {
        font-size: rem(20);
    }
    @media screen and (min-width: 1050px) {
        font-size: rem(25);
    }
}

.top-card1__text {
    margin-top: rem(17);
    font-weight: 500;
    font-size: rem(20);
    letter-spacing: 0.1em;
    line-height: calc(25 / 20);
    color: $white;
    @media screen and (min-width: 1050px) {
        margin-top: rem(24);
    }
}

.top-sixstrength__button {
    margin-top: rem(12);
}

.top-card1__sticky-scroll {
    @media screen and (min-width: $tab) {
        width: 52.0208%;
    }
}

.top-card1__article-content {
    margin-top: rem(23);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: rem(10);
    @media screen and (min-width: $tab) {
        margin-top: 0;
        max-width: rem(612);
        grid-template-columns: 1fr 1fr;
        gap: rem(44);
        row-gap: rem(21);
    }
}

.top-card1__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (min-width: $tab) {
        flex-direction: column;
        align-items: baseline;
    }
}

.top-card1__img {
    max-width: rem(136);
    width: 100%;
    margin-right: rem(15);
    flex-shrink: 0;
    @media screen and (min-width: $tab) {
        max-width: rem(285);
        margin-right: 0;
    }
}

.top-card1__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 136 / 154;
    @media screen and (min-width: $tab) {
        aspect-ratio: 285 / 400;
    }
}

.top-card1__body {
    @media screen and (min-width: $tab) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

.top-card1__category {
    width: fit-content;
    padding: 0 17px;
    @media screen and (min-width: $tab) {
        margin-top: rem(10);
        padding: 2px 10px;
    }
}

.top-card1__category img {
    max-width: rem(47);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(61);
    }
}

.top-card1__title {
    margin-top: rem(5);
    font-weight: 900;
    font-size: rem(18);
    line-height: calc(21 / 18);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(20);
    }
    @media screen and (min-width: 1050px) {
        font-size: rem(22);
        line-height: calc(26 / 22);
    }
}

//top-card1ホバー画像拡大
.top-card1__img {
    overflow: hidden;
}

.top-card1__img img {
    transition: transform 0.5s ease;
}

.top-card1__link:hover .top-card1__img img {
    transform: scale(1.1);
}
