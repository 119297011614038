@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.footer {
	background: #eaeaea;
}

.footer__nav-item {
	font-size: rem(13);
	line-height: calc(20 / 13);
}

.footer__button-wrap {
	width: 100%;
	font-size: rem(13);
	line-height: calc(20 / 13);
}

.footer__button {
	display: none;
	@media screen and (min-width: 1060px) {
		display: block;
		display: flex;
		align-items: center;
		margin-right: rem(26);
		font-size: rem(13);
		line-height: 1;
	}
}

.footer__aboutUs-icon {
	display: inline-block;
	width: rem(15);
	height: rem(14)
}

.footer__contact,
.footer__quote {
	display: flex;
	align-items: center;
	gap: rem(5);
}

.footer__contact-icon {
	width: rem(21);
}

.footer__quote-icon {
	width: rem(14);
}

.footer__search-icon {
	width: rem(14);
}

.footer__menu-info-wrap {
	margin-top: rem(127);
	display: flex;
	align-items: center;
	justify-content: space-between;
}
