@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//セミナー情報 contents
.seminer-detail-contents {
    margin-top: rem(30);
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        margin-top: rem(40);
        max-width: rem(880);
        width: 100%;
        padding-inline: rem(0);
    }
}

.seminer-detail-contents {
    h3 {
        margin-bottom: rem(31);
        background-image: url("../images/seminer-detail/seminer-detail-title-bg.webp");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        font-weight: bold;
        font-size: rem(18);
        line-height: calc(27 / 18);
        color: $white;
        padding: 0 rem(4);
        @media screen and (min-width: $tab) {
            margin-bottom: rem(40);
            font-size: rem(26);
            line-height: calc(35 / 26);
            padding: 0 rem(20);
        }
    }
    p.seminer-detail-content__text-l {
        margin-bottom: rem(30);
        font-weight: 400;
        font-size: rem(16);
        line-height: calc(20 / 16);
        color: $sub-black1;
        @media screen and (min-width: $tab) {
            margin-bottom: rem(40);
            font-size: rem(20);
            line-height: 1;
        }
    }
    p.seminer-detail-content__text-s {
        margin-bottom: rem(30);
        font-weight: 400;
        font-size: rem(14);
        line-height: calc(28 / 14);
        color: $sub-black1;
        @media screen and (min-width: $tab) {
            margin-bottom: rem(40);
            font-size: rem(15);
            line-height: 2;
        }
    }
    .seminer-detail-content__img {
        margin-bottom: rem(50);
        max-width: rem(340);
        width: 100%;
        margin-inline: auto;
        @media screen and (min-width: $tab) {
            margin-bottom: rem(80);
            max-width: rem(880);
        }
    }
    .seminer-detail-content__img img {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 340 / 133;
        @media screen and (min-width: $tab) {
            aspect-ratio: 880 / 345;
        }
    }
}

.seminer-detail-content__button-wrap1 {
    margin-top: rem(42);
    text-align: center;
    margin-bottom: rem(50);
    @media screen and (min-width: $tab) {
        margin-top: rem(45);
        margin-bottom: rem(40);
    }
}

.seminer-detail-content__button-wrap2 {
    margin-top: rem(42);
    text-align: center;
    margin-bottom: rem(50);
    @media screen and (min-width: $tab) {
        margin-top: rem(45);
        margin-bottom: rem(80);
    }
}

.seminer-detail-content__button {
    display: inline-block;
    background-color: $yellow;
    color: $white;
    font-weight: 500;
    font-size: rem(16);
    line-height: calc(24 / 16);
    padding: rem(12) rem(59);
    @media screen and (min-width: $tab) {
        font-size: rem(23);
        line-height: calc(35 / 23);
        padding: rem(14) rem(85);
    }
}

.seminer-contents__inner {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(880);
        padding-inline: rem(0);
    }
}

.seminer-contents__bg {
    background-color: #f0f0f0;
    border-left: 9px solid $yellow;
    padding: rem(27) rem(8) rem(40) rem(10);
    margin-bottom: rem(50);
    @media screen and (min-width: $tab) {
        border-left: 11px solid $yellow;
        padding: rem(55) rem(25) rem(55) rem(71);
        margin-bottom: rem(80);
    }
}

.seminer-contents__title-content {
    display: flex;
    align-items: center;
    gap: rem(7);
    @media screen and (min-width: $tab) {
        gap: rem(21);
    }
}

.seminer-contents__title--en {
    max-width: rem(158);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(257);
    }
}

.seminer-contents__title--ja {
    font-weight: bold;
    font-size: rem(14);
    line-height: 1;
    @media screen and (min-width: $tab) {
        font-size: rem(20);
    }
}

.seminer-contents__list-title {
    font-weight: bold;
    font-size: rem(16);
    line-height: calc(18 / 16);
    @media screen and (min-width: $tab) {
        font-size: rem(20);
        line-height: 1;
    }
}

.seminer-contents__list-title:nth-child(3) {
    margin-top: rem(25);
    @media screen and (min-width: $tab) {
        margin-top: rem(24);
    }
}

.seminer-contents__list {
    margin-top: rem(15);
    @media screen and (min-width: $tab) {
        margin-top: rem(13);
    }
}

.seminer-contents__list-item {
    font-weight: 400;
    font-size: rem(14);
    line-height: 2;
    position: relative;
    padding-left: rem(20);
    @media screen and (min-width: $tab) {
        font-size: rem(15);
        line-height: 2;
        padding-left: rem(24);
    }
}

.seminer-contents__list-item::before {
    position: absolute;
    content: "";
    top: rem(7);
    left: 0;
    width: rem(11);
    height: rem(11);
    border-radius: 50px;
    background: $yellow;
    flex-shrink: 0;
    @media screen and (min-width: $tab) {
        top: rem(10);
        width: rem(14);
        height: rem(14);
    }
}

.seminer-contents__list-sub-title {
    font-weight: 400;
    font-size: rem(14);
    line-height: 2;
    position: relative;
    padding-left: rem(26);
    @media screen and (min-width: $tab) {
        font-size: rem(18);
        padding-left: rem(20);
    }
}

.seminer-contents__list-sub-title::before {
    position: absolute;
    content: "";
    top: rem(8);
    left: rem(11);
    width: rem(11);
    height: rem(11);
    background-image: url("../images/common/seminer-contents-icon.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    @media screen and (min-width: $tab) {
        top: rem(11);
        left: 0;
        width: rem(14);
        height: rem(14);
    }
}

