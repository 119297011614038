@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//マーケティング
.marketing__inner {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(1130);
        padding-inline: rem(25);
    }
}

.marketing__article-content {
    margin-top: rem(25);
    @media screen and (min-width: $tab) {
        margin-top: rem(40);
    }
}
