@charset "UTF-8";

body {
  font-family: "Noto Sans JP", sans-serif;
}

/* pcの電話番号発信対応 */

/* ホバー */

a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
  cursor: pointer;
}

a:hover {
  opacity: 0.8;
}

/* ハンバーガーメニュークリックで背景固定 */

body {
  overflow-y: auto;
}

body.is-fixed {
  overflow-y: hidden;
}

/* スクロール中にヘッダーを消す */

.hide {
  transform: translateY(-100%);
  /* 上に消えるようにする */
}

#scrollArea {
  position: fixed;
  /* 固定して表示されるようにする */
  top: 0;
  /* 表示場所を一番上に */
  transition: 0.5s;
  /* スクロール時に一瞬で消えると物足りないので.hideの処理を0.5秒で行う */
  z-index: 100;
  /* 前面に表示されるようにする */
}

/* base-color */

body {
  color: #1C1C1C;
}

.pc-br {
  display: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Set core root defaults */

html {
  scroll-behavior: smooth;
}

/* Set core body defaults */

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */

ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */

a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img {
  max-width: 100%;
  display: block;
  width: 100%;
}

/* Inherit fonts for inputs and buttons */

textarea,
select {
  font: inherit;
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.icon__flame {
  position: relative;
  width: 10px;
  height: 10px;
  border: 1px solid black;
}

.icon__background {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 8px;
  height: 8px;
  background-color: white;
}

.icon__arrow {
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}

.icon__arrow .icon__bar:nth-child(1) {
  position: absolute;
  top: 50%;
  width: 8px;
  height: 1px;
  background-color: #1C1C1C;
  transform: rotate(-45deg);
}

.icon__arrow .icon__bar:nth-child(2) {
  position: absolute;
  top: 5%;
  right: 0;
  width: 6px;
  height: 1px;
  background-color: #1C1C1C;
}

.icon__arrow .icon__bar:nth-child(3) {
  position: absolute;
  top: 5%;
  right: 0;
  width: 1px;
  height: 6px;
  background-color: #1C1C1C;
}

.works__icon-wrap:hover .icon__arrow {
  /* 右ななめ上 */
  transform: translate(2px, -2px);
}

.seminer__icon-wrap:hover .icon__arrow {
  /* 右ななめ上 */
  transform: translate(2px, -2px);
}

.icon__text {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 2.5714285714;
  display: inline-flex;
}

.inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.header {
  background-color: white;
  position: fixed;
  width: 100vw;
  height: auto;
  z-index: 999;
}

.header__inner {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 0.8125rem 0 1.0625rem;
  margin-inline: auto;
}

.header__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__button-wrap {
  max-width: 78.125rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__logo {
  display: flex;
  flex-direction: column;
}

.header__logo img {
  width: 100%;
  max-width: 15rem;
}

.header__logo-text {
  font-size: 11px;
  line-height: 1;
  margin-left: 0.9375rem;
  font-weight: 500;
}

.header__button {
  display: none;
}

.header__menu-items {
  display: flex;
  gap: 2.25rem;
}

.header__menu-item a {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  padding: 13px 0;
}

.header__aboutUs-icon {
  display: inline-block;
  width: 0.9375rem;
  height: 0.875rem;
}

.header__contact-icon {
  display: inline-block;
  width: 1.3125rem;
}

.header__quote-icon {
  display: inline-block;
  width: 0.875rem;
}

.header__search-icon {
  width: 0.875rem;
  cursor: pointer;
}

.header__nav {
  display: none;
}

.header__nav-item {
  font-size: 0.8125rem;
  line-height: 1;
  height: inherit;
}

.header__nav-item a {
  display: inline-block;
  padding: 10px 0;
}

.drawer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.drawer__bg {
  background-image: url("../images/common/drawer-bg-sp.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 48px 0 28px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.drawer__inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.25rem;
}

.drawer__logo {
  max-width: 23.125rem;
  width: 100%;
}

.drawer__content {
  margin-top: 29px;
}

.drawer__nav-lists {
  display: flex;
  flex-direction: column;
}

.drawer__nav-title {
  margin-top: 0.6875rem;
  display: flex;
  align-items: center;
}

.drawer__nav-list:nth-child(1) > a:nth-child(1) > .drawer__nav-title {
  margin-top: 0;
}

.nav-title__en {
  margin-right: 1rem;
}

.nav-title__en-about {
  width: 5rem;
}

.nav-title__ja {
  font-size: 11px;
  line-height: 1.0909090909;
}

.nav-title__en-publication {
  width: 9.75rem;
}

.nav-title__en-6strength {
  width: 9.4375rem;
}

.drawer__menu-item {
  margin-top: 0.3125rem;
  font-weight: 900;
  font-size: 11px;
  line-height: 1.0909090909;
  color: #0077B4;
}

.nav-title__en-powerOfEditiing {
  width: 14.5625rem;
}

.nav-title__en-caseStudy {
  width: 9.375rem;
}

.nav-title__en-seminer {
  width: 7.0625rem;
}

.nav-title__en-knowledge {
  width: 9.5rem;
}

.nav-title__en-document {
  width: 9.5rem;
}

.drawer__menu-info__copyright {
  margin-top: 1.125rem;
  text-align: right;
}

.js-drawer {
  transition: opacity 0.8s;
  opacity: 0;
  visibility: hidden;
}

.js-drawer.fade {
  opacity: 1;
  visibility: visible;
}

.hamburger {
  position: relative;
  width: 100%;
  max-width: 37px;
  height: 37px;
}

.hamburger__btn {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  z-index: 999;
  /*追記：いつも一番上*/
  cursor: pointer;
}

button.hamburger__btn {
  border: none;
  outline: none;
  background: transparent;
}

.bar {
  width: 37px;
  height: 2px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #7e7e7e;
  pointer-events: none;
}

.bar-top {
  top: 8px;
}

.bar-middle {
  top: 50%;
  transform: translate(-50%, -50%);
}

.bar-bottom {
  bottom: 8px;
}

.hamburger__btn.close .bar-top {
  transform: translate(-50%, 10px) rotate(45deg);
  transition: transform 0.3s;
}

.hamburger__btn.close .bar-middle {
  opacity: 0;
  transition: opacity 0.3s;
}

.hamburger__btn.close .bar-bottom {
  transform: translate(-50%, -10px) rotate(-45deg);
  transition: transform 0.3s;
}

.menu-lists {
  padding: 2.875rem 0 0.375rem;
  background: transparent linear-gradient(179deg, #0097e5 0%, #003c5a 100%) 0% 0% no-repeat padding-box;
}

.menu-lists__inner {
  max-width: 68.125rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.menu-list {
  display: flex;
  flex-direction: column;
  color: white;
}

.menu-list__service {
  max-width: 20rem;
  width: 100%;
}

.menu-list__title img {
  width: 100%;
  height: 100%;
}

.menu-list__title--service {
  max-width: 6rem;
  width: 100%;
}

.menu-list__title--news {
  max-width: 4.1875rem;
  width: 100%;
}

.menu-title__en-powerOfEditing img {
  max-width: 13.375rem;
  width: 100%;
}

.menu-title__en-publication img {
  max-width: 9.25rem;
  width: 100%;
}

.menu-title__en-seminar img {
  max-width: 6.5rem;
  width: 100%;
}

.menu-title__en-company img {
  max-width: 6.9375rem;
  width: 100%;
}

.menu-title__en-philosophy img {
  max-width: 9rem;
  width: 100%;
}

.menu-title__en-recruit img {
  max-width: 6rem;
  width: 100%;
}

.menu-list__service > ul,
.menu-list__news > ul {
  margin-top: 11px;
}

.menu-list__service > ul > li,
.menu-list__news > ul > li {
  font-weight: bold;
  font-size: 12px;
}

.menu-list__news-lists > ul > li {
  font-weight: bold;
  font-size: 12px;
}

.menu-list__service > ul > li {
  margin-top: 15px;
}

.menu-list__news > ul > li {
  margin-top: 8px;
}

.menu-list__news {
  margin-top: 47px;
  max-width: 18.5rem;
  width: 100%;
}

.menu-list__news-lists {
  display: flex;
}

ul.menu-list__news-list:nth-child(1) {
  padding-right: 2.6875rem;
}

.menu-list__other {
  margin-top: 47px;
  width: 100%;
}

.menu-list__other-content {
  max-width: 19.6875rem;
  width: 100%;
}

.menu-list__menu-title {
  margin-top: 20px;
}

.menu-list__other-content > a:nth-child(1) > div {
  margin-top: 0px;
}

.menu-list__arrow {
  transition: transform 0.5s ease;
}

.menu-list__arrow.arrow {
  position: relative;
}

.menu-list__arrow.arrow::before {
  left: 4px;
  width: 25px;
}

.menu-list__arrow.arrow::after {
  left: 18px;
  width: 8px;
  height: 8px;
}

.menu-title__en {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-title__ja {
  margin-top: -5px;
  font-size: 12px;
  line-height: 1;
}

.menu-list__menu-title:hover .menu-list__arrow {
  transform: translateX(8px);
}

.menu__info {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.menu__info-wrap1 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.menu__info-wrap1 a {
  font-size: 10px;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.menu__info-wrap2 {
  margin-top: 0.75rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: end;
}

.menu-info__quote {
  font-size: 10px;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.menu-info__quote-icon {
  max-width: 0.75rem;
  width: 100%;
  margin-right: 0.25rem;
  color: #000000;
}

.menu-info__contact {
  margin-top: 0.375rem;
  font-size: 10px;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.menu-info__contact-icon {
  max-width: 1.125rem;
  width: 100%;
  margin-right: 0.25rem;
}

.menu-info__search {
  width: 151px;
  height: 19px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

#form {
  position: relative;
  max-width: 151px;
  width: 100%;
  height: inherit;
  border-radius: 10px;
  background-color: transparent;
  padding-right: 18px;
}

#sbox {
  height: inherit;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
  border: 1px solid #000000;
  outline: 0;
  background-color: transparent;
  color: #000000;
  width: 100%;
}

#sbox::placeholder {
  color: #000000;
}

button#sbtn {
  border: none;
  background: none;
  position: absolute;
  top: 0;
  right: 10px;
  height: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#sbtn::before {
  content: "";
  width: 13px;
  height: 13px;
  background-image: url("../images/common/search-icon.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  z-index: 10;
}

.menu-info__search-icon:hover {
  color: #7fbfff;
}

.menu-info__sns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu-info__sns-icon--x {
  width: 27px;
}

.menu-info__sns-icon--facebook {
  width: 30px;
}

a.menu-info__sns-icon {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 13px;
  line-height: 0.3939393939;
}

.menu-info__sns > div:nth-child(1) {
  margin-right: 2.25rem;
}

.menu-info__sns > div:nth-child(2) {
  margin-right: 1.1875rem;
}

.menu-info__copyright {
  font-size: 11px;
  line-height: 1;
}

.footer-logo {
  background: #ddd;
  padding: 22px 0;
}

.footer-logo__inner {
  max-width: 66.25rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.footer-logo__items {
  display: flex;
}

.footer-logo__item-wrap {
  display: flex;
  align-items: center;
}

.footer-logo__item {
  display: inline-block;
  margin-right: 13.0625rem;
}

.footer-logo__item.footer-logo__item--last {
  margin-right: 0;
}

.button {
  text-align: right;
}

.button__link {
  display: inline-flex;
  align-items: center;
  padding-right: 1.25rem;
}

.button__arrow,
.button__arrow--white {
  position: absolute;
  left: 10px;
  background-color: transparent;
  border-radius: 50px;
  width: 15px;
  height: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease;
}

.button__arrow {
  border: 1px solid #1C1C1C;
}

.button__arrow--white {
  border: 1px solid white;
}

.button__link:hover .button__arrow {
  transform: translateX(8px);
}

.button__text--white {
  font-family: "Roboto", sans-serif;
  font-size: 0.8125rem;
  line-height: 2.5384615385;
  font-weight: bold;
}

.button__text {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 2.5;
}

.button__text--white {
  color: white;
}

.button-text--white:hover .button__arrow--white {
  transform: translateX(8px);
}

.arrow--white {
  display: inline-block;
  color: white;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}

.arrow--white::before,
.arrow--white::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}

.arrow--white::before {
  left: 2px;
  width: 8px;
  height: 1px;
  background: white;
}

.arrow--white::after {
  left: 5px;
  width: 4px;
  height: 4px;
  border-top: 1px solid white;
  border-right: 1px solid white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.arrow--black {
  position: relative;
  display: inline-block;
  color: #1C1C1C;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}

.arrow--black::before,
.arrow--black::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}

.arrow--black::before {
  left: 2px;
  width: 8px;
  height: 1px;
  background: #1C1C1C;
}

.arrow--black::after {
  left: 5px;
  width: 4px;
  height: 4px;
  border-top: 1px solid #1C1C1C;
  border-right: 1px solid #1C1C1C;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header-spacer {
  height: 50px;
}

.hide {
  transform: translateY(-100%);
  /* 上に消えるようにする */
}

#scrollArea {
  position: fixed;
  /* 固定して表示されるようにする */
  top: 0;
  /* 表示場所を一番上に */
  transition: 0.5s;
  /* スクロール時に一瞬で消えると物足りないので.hideの処理を0.5秒で行う */
  z-index: 100;
  /* 前面に表示されるようにする */
}

.js-fadeIn {
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  transition: opacity 0.5s, visibility 0.5s, transform 1s;
}

.scroll {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.footer {
  background: #eaeaea;
}

.footer__nav-item {
  font-size: 0.8125rem;
  line-height: 1.5384615385;
}

.footer__button-wrap {
  width: 100%;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
}

.footer__button {
  display: none;
}

.footer__aboutUs-icon {
  display: inline-block;
  width: 0.9375rem;
  height: 0.875rem;
}

.footer__contact,
.footer__quote {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
}

.footer__contact-icon {
  width: 1.3125rem;
}

.footer__quote-icon {
  width: 0.875rem;
}

.footer__search-icon {
  width: 0.875rem;
}

.footer__menu-info-wrap {
  margin-top: 7.9375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pc-footer__inner {
  display: none;
}

.pc-footer__logo {
  max-width: 21.5rem;
  width: 100%;
}

.pc-footer__quote {
  margin-right: 3.125rem;
}

.pc-footer__button {
  display: none;
}

.pc-footer__menu-bottom {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.sp-footer__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding: 30px 1.25rem 7px 1.25rem;
}

.sp-footer__logo {
  max-width: 20.9375rem;
}

.sp-footer__flex-wrap {
  margin-top: 1.75rem;
  display: flex;
  justify-content: space-between;
}

.sp-footer__nav-items {
  display: flex;
  flex-direction: column;
}

.sp-footer__nav-item {
  margin-top: 0.8125rem;
}

.sp-footer__nav-item:nth-child(1) {
  margin-top: 0;
}

.sp-footer__button-wrap {
  margin-top: 7.4375rem;
  display: flex;
  flex-direction: column;
  max-width: 10.8125rem;
  align-items: end;
  gap: 1.0625rem;
}

.sp-footer__info {
  margin-top: 1.125rem;
}

.sp-footer__menu-info-wrap {
  margin-top: 1.875rem;
  display: flex;
  flex-direction: column;
}

.sp-footer__copyright {
  margin-top: 2.5rem;
  text-align: right;
}

.sub-section-title__contents {
  text-align: center;
}

.sub-section-title__en {
  max-width: 13.75rem;
  width: 100%;
  margin-inline: auto;
}

.sub-section-title__ja {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.6666666667;
  text-align: center;
}

.detail-section-title__contents {
  display: flex;
  justify-content: space-between;
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.detail-section-title__back {
  display: inline;
  font-weight: bold;
  font-size: 0.9375rem;
  letter-spacing: 0.3em;
  line-height: 1.1333333333;
  color: #343434;
  text-transform: uppercase;
}

.publication-detail__arrow {
  position: relative;
  display: inline-block;
  padding: 0 0 0 16px;
  color: #000;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}

.publication-detail__arrow::before,
.publication-detail__arrow::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}

.publication-detail__arrow::before {
  right: 2px;
  width: 10px;
  height: 2px;
  background-color: #000000;
}

.publication-detail__arrow::after {
  left: 3px;
  width: 6px;
  height: 6px;
  border-top: 2px solid #000000;
  border-right: 2px solid #000000;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.detail-section-title__content {
  text-align: right;
}

.detail-section-title__en {
  max-width: 12.875rem;
  width: 100%;
}

.detail-section-title__ja {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.2857142857;
}

.card-list {
  margin-top: 2.5625rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2.0625rem;
  column-gap: 1.6875rem;
}

.card-list__item {
  position: relative;
  max-width: 9.5625rem;
  width: 100%;
  margin-inline: auto;
}

.card-list__item.new-mark::after {
  content: "NEW";
  font-family: "Space Grotesk", sans-serif;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1;
  color: white;
  width: 2.25rem;
  height: 2.1875rem;
  background: #8b7086;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 150px;
  left: -10px;
  z-index: 10;
}

.card-list__img {
  max-width: 8.625rem;
  width: 100%;
  margin-inline: auto;
}

.card-list__img img {
  object-fit: contain;
  width: 100%;
  aspect-ratio: 138/192;
}

.card-list__body {
  padding-top: 0.625rem;
  display: flex;
  flex-direction: column;
  min-height: 4.625rem;
}

.card-list__number {
  margin-top: -35px;
  width: 70px;
  height: 35px;
  border-radius: 70px 70px 0 0;
  color: white;
  font-size: 23px;
  font-weight: bold;
  margin-inline: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.card-list__title {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  font-weight: bold;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.pagination {
  display: flex;
}

.pagination__inner {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.pagination__item {
  margin-right: 1rem;
}

.pagination__link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.25;
  color: #a7a7a7;
  transition: 0.3s;
}

.pagination__link--active {
  color: #1C1C1C;
  pointer-events: none;
}

.pagination__link--next,
.pagination__link--prev {
  color: #1C1C1C;
}

.pagination__item:nth-child(5),
.pagination__item:nth-child(6) {
  display: none;
}

.card2__article-content {
  margin-top: 2.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 3.125rem;
}

.card2__img {
  max-width: 37.5rem;
  width: 100%;
  padding-inline: 0.875rem;
  margin-inline: auto;
}

.card2__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 300/165;
}

.card2__body {
  margin-top: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.card2__title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  color: #383838;
}

.card2__download {
  margin-top: 0.9375rem;
  max-width: 11.4375rem;
  width: 100%;
  background-color: #404040;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3125rem;
  padding: 0.1875rem 0;
  margin-inline: auto;
}

span.card2__download-icon img {
  width: 0.9375rem;
  height: 0.6875rem;
}

.card2__download img {
  width: 4.5rem;
}

.card2__img {
  overflow: hidden;
}

.card2__img img {
  transition: transform 0.5s ease;
}

.card2__link:hover .card2__img img {
  transform: scale(1.1);
}

.sub-sns__wrap1,
.sub-sns__wrap2 {
  display: flex;
  gap: 0.625rem;
}

.beliveInEdithing-detail__sns-wrap1.sub-sns__wrap1 > a:nth-child(1) {
  display: none;
}

.beliveInEdithing-detail__sns-wrap1.sub-sns__wrap1 > a:nth-child(2) {
  display: block;
}

.sub-sp-sns__item--good {
  height: 1.0625rem;
  border-radius: 3px;
  background-color: #6080b8;
  color: white;
  font-weight: 400;
  font-size: 0.6875rem;
  line-height: 1.6363636364;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0 0.4375rem 0 0.375rem;
}

.good-icon img {
  width: 0.8125rem;
  height: 0.875rem;
}

.sub-sns__item--shere {
  height: 1.0625rem;
  border-radius: 5px;
  background-color: #117df0;
  color: white;
  font-weight: 400;
  font-size: 0.6875rem;
  line-height: 1.2727272727;
  display: flex;
  align-items: center;
  padding: 0 0.3125rem;
}

.sub-sns__item--x {
  height: 1.0625rem;
  border-radius: 5px;
  background-color: #505050;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 0.5625rem;
}

.x-icon img {
  width: 0.625rem;
  height: 0.625rem;
}

.sub-sns__item--hatena {
  height: 1.0625rem;
  border-radius: 5px;
  background-color: #47a3d9;
  color: white;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.1666666667;
  display: flex;
  align-items: center;
  padding: 0 0.375rem;
}

.hatena-icon img {
  width: 1rem;
  height: 0.8125rem;
}

.knowledge-detail__sns-wrap2 {
  margin-top: 0.3125rem;
}

.sub-sns__item--pocket {
  height: 1.0625rem;
  border-radius: 5px;
  background-color: white;
  color: #404040;
  font-weight: 400;
  font-size: 0.6875rem;
  line-height: 1.2727272727;
  display: flex;
  align-items: center;
  gap: 0.1875rem;
  padding: 0 0.375rem;
  border: 1px solid #d5d5d5;
}

.pocket-icon img {
  width: 0.6875rem;
  height: 0.6875rem;
}

.sub-sns__item--line {
  height: 1.0625rem;
  border-radius: 5px;
  background-color: #59b75c;
  color: white;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 1.2;
  display: flex;
  align-items: center;
  gap: 0.0625rem;
  padding: 0 0.5rem 0 0.375rem;
}

.line-icon img {
  width: 1rem;
  height: 0.875rem;
}

.sub-side {
  width: 100%;
}

.sub-side__category,
.sub-side__tag {
  display: none;
}

p.sub-side-category-title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.3;
  color: white;
  padding: 0 0.6875rem;
}

.sub-side__category-list {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
}

.sub-side__category-item {
  margin-top: 0.625rem;
  color: #1C1C1C;
}

.sub-side__category-item + .sub-side__category-item {
  margin-top: 0.9375rem;
}

.knowledge-side__tag {
  margin-top: 2.5rem;
}

.sub-side__tag-title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.3;
  background-color: #f0f0f0;
  padding: 0 0.6875rem;
}

.sub-side__tag-list {
  display: flex;
  flex-wrap: wrap;
}

.sub-side__tag-item {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
  padding-right: 1.25rem;
}

.sub-side__tag-item + .sub-side__tag-item + .sub-side__tag-item {
  margin-top: 0.625rem;
}

.sub-side__articles-bg {
  background-color: #e2e2e2;
  padding-top: 3.625rem;
  padding-bottom: 3.6875rem;
}

.sub-side__articles-inner {
  max-width: 43.875rem;
  width: 100%;
  padding-inline: 1.4375rem;
  margin-inline: auto;
}

.sub-side__articles-title {
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.1538461538;
  color: #404040;
}

.sub-side__card-list {
  margin-top: 2.5625rem;
  display: flex;
  flex-direction: column;
}

.sub-side__card-list-item {
  margin-top: 2.1875rem;
  position: relative;
  max-width: 41rem;
  width: 100%;
  margin-inline: auto;
}

a.sub-side__card-list-link:nth-child(1) > article {
  margin-top: 0rem;
}

.sub-side__card-list-img {
  max-width: 38.625rem;
  width: 100%;
  margin-inline: auto;
  padding: 0 0.875rem;
}

.sub-side__card-list-img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 300/165;
}

.sub-side__card-list-body {
  margin-top: 0.3125rem;
}

.sub-side__card-list-title {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: bold;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #383838;
}

.sub-side__card-list-tag-wrap {
  margin-top: 0.3125rem;
  display: flex;
}

.sub-side__card-list-tag {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
  color: #383838;
  margin-right: 1.25rem;
}

.sub-side__card-list-img {
  overflow: hidden;
}

.sub-side__card-list-img img {
  transition: transform 0.5s ease;
}

.sub-side__card-list-link:hover .sub-side__card-list-img img {
  transform: scale(1.1);
}

.changeByEdit__bg {
  width: 100%;
  background: rgba(110, 0, 84, 0.1);
  padding: 3.5625rem 0 1.875rem;
}

.changeByEdit__inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.25rem;
  margin-inline: auto;
}

.changeByEdit__section-title {
  max-width: 11.6875rem;
  width: 100%;
}

.changeByEdit__items {
  margin-top: 2.0625rem;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 2.1875rem;
}

.changeByEdit__body {
  margin-top: 0.3125rem;
  max-width: 39.5rem;
  width: 100%;
  padding-inline: 1rem;
}

.changeByEdit__img {
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.125rem;
}

.changeByEdit__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 300/165;
}

.changeByEdit__title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  color: #383838;
}

.changeByEdit__article-tag-wrap {
  margin-top: 0.3125rem;
  display: flex;
}

.changeByEdit__article-tag {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
  color: #383838;
  margin-right: 1.25rem;
}

.changeByEdit__img {
  overflow: hidden;
}

.changeByEdit__img img {
  transition: transform 0.5s ease;
}

.changeByEdit__link:hover .changeByEdit__img img {
  transform: scale(1.1);
}

.changeByEdit__link:hover .knowledge__button__arrow {
  transform: translateX(8px);
}

.top-card1__sticky-container {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
}

.top-card1__content--main {
  width: 100%;
}

.top-card1__bg {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 41.875rem;
  width: 100%;
  object-fit: cover;
  justify-content: center;
  aspect-ratio: 335/458;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(52, 53, 83, 0.45);
}

.top-card1__text-content {
  position: relative;
  max-width: 16.6875rem;
  width: 100%;
}

.top-card1__title--en {
  max-width: 15.5625rem;
  width: 100%;
}

.top-card1__title--ja {
  font-weight: 900;
  font-size: 0.9375rem;
  letter-spacing: 0.1em;
  color: white;
}

.top-card1__text {
  margin-top: 1.0625rem;
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.1em;
  line-height: 1.25;
  color: white;
}

.top-sixstrength__button {
  margin-top: 0.75rem;
}

.top-card1__article-content {
  margin-top: 1.4375rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.625rem;
}

.top-card1__content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top-card1__img {
  max-width: 8.5rem;
  width: 100%;
  margin-right: 0.9375rem;
  flex-shrink: 0;
}

.top-card1__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 136/154;
}

.top-card1__category {
  width: fit-content;
  padding: 0 17px;
}

.top-card1__category img {
  max-width: 2.9375rem;
  width: 100%;
}

.top-card1__title {
  margin-top: 0.3125rem;
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 1.1666666667;
  color: #383838;
}

.top-card1__img {
  overflow: hidden;
}

.top-card1__img img {
  transition: transform 0.5s ease;
}

.top-card1__link:hover .top-card1__img img {
  transform: scale(1.1);
}

.top-card2__article-content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.25rem;
}

.top-card2__img {
  max-width: 41.875rem;
  width: 100%;
}

.top-card2__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 335/174;
}

.top-card2__body {
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.top-card2__category {
  width: fit-content;
  padding: 0 17px;
  line-height: 0.6875rem;
}

.top-card2__category img {
  display: inline-block;
  max-width: 3.5rem;
  width: 100%;
}

.top-card2__title {
  margin-top: 0.3125rem;
  font-weight: 900;
  font-size: 0.8125rem;
  line-height: 1.1538461538;
  color: #383838;
}

.top-knowledge__topics {
  margin-top: 0.3125rem;
  font-size: 0.625rem;
  line-height: 2.2;
  color: #383838;
}

.top-card2__img {
  overflow: hidden;
}

.top-card2__img img {
  transition: transform 0.5s ease;
}

.top-card2__link:hover .top-card2__img img {
  transform: scale(1.1);
}

.top-mv {
  width: 100%;
  position: relative;
}

.top-mv__bg {
  background-image: url("../images/top/top-mv-bg-sp.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.top-mv__inner {
  padding-top: 11.3125rem;
  width: 100%;
  margin-inline: auto;
  position: relative;
  padding-bottom: 2.625rem;
  height: 100%;
}

.swiper {
  transform: translateY(-70px);
}

.swiper-wrapper {
  width: 100%;
  margin-inline: auto;
}

.swiper-slide {
  width: 100%;
}

.swiper-slide img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 268/363;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -10px;
}

span.swiper-pagination-bullet {
  height: 11px;
  width: 11px;
}

.section-title__contents {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #1c1c1c;
  padding-bottom: 0.375rem;
}

.section-title__ja {
  font-weight: 900;
  font-size: 1.0625rem;
  letter-spacing: 0.1em;
  color: #000000;
}

.top-sixstrength__section-title {
  max-width: 15.5rem;
  width: 100%;
}

.top-sixstrength__top-bg {
  background: url(../images/top/6strength-bg.webp) no-repeat center/cover;
}

.top-sixstrength__category {
  background-color: #0077B4;
}

.top-sixstrength__top-button {
  display: inline-flex;
  align-items: center;
}

.top-sixstrength__top-button__arrow {
  left: 2.5rem;
}

.top-sixstrength__button {
  margin-top: 1.125rem;
  justify-content: right;
  padding-right: 5px;
}

.top-card1__top-link:hover .top-sixstrength__top-button__arrow {
  transform: translateX(8px);
}

.layout-top-sixstrength {
  margin-top: 5.625rem;
}

.card-category {
  display: inline;
  border-radius: 14px;
  font-weight: bold;
  font-size: 15px;
  line-height: 1.5;
  color: white;
}

.top-publication__section-title {
  max-width: 16.625rem;
  width: 100%;
}

.top-publication__top-bg {
  background: url(../images/top/publication-bg.webp) no-repeat center/cover;
}

.top-publication__article-contents {
  display: flex;
  flex-direction: column;
}

.top-publication__article-content {
  display: flex;
  flex-direction: column;
}

.top-publication__article-wrap {
  display: flex;
}

.top-publication__article {
  max-width: 100%;
  width: 100%;
}

figure.top-publication__article-img1.top-card1__img img {
  aspect-ratio: 136/154;
}

figure.top-publication__article-img2.top-card1__img img {
  aspect-ratio: 136/154;
}

figure.top-publication__article-img3.top-card1__img img {
  aspect-ratio: 136/154;
}

figure.top-publication__article-img4.top-card1__img img {
  aspect-ratio: 136/154;
}

figure.top-publication__article-img5.top-card1__img img {
  aspect-ratio: 136/154;
}

figure.top-publication__article-img6.top-card1__img img {
  aspect-ratio: 136/154;
}

.top-publication__article-content:nth-child(1) {
  margin-top: 1.25rem;
}

h3.top-publication__article-title.top-card1__title {
  margin-top: 0.625rem;
  font-size: 0.8125rem;
  line-height: 1.1538461538;
}

.top-publication__category {
  background-color: #005a2d;
}

.top-publication__category img {
  max-width: 3.625rem;
}

.top-publication__top-button {
  display: inline-flex;
  align-items: center;
}

.top-publication__top-button__arrow {
  left: 2.5rem;
}

.top-publication__button {
  justify-content: right;
  padding-right: 5px;
  margin-top: 1.25rem;
}

.top-card1__top-link:hover .top-publication__top-button__arrow {
  transform: translateX(8px);
}

.top-powerOfEditing__section-title {
  max-width: 20.375rem;
  width: 100%;
}

.top-powerOfEditing__top-bg {
  background: url(../images/top/powerOfEditing-bg.webp) no-repeat center/cover;
}

.top-powerOfEditing__top-title--en.top-card1__title--en {
  max-width: 12.25rem;
}

.top-powerOfEditing__top-text.top-card1__text {
  margin-top: 1.25rem;
}

.top-powerOfEditing__top-button {
  display: inline-flex;
  align-items: center;
}

.top-powerOfEditing__article-img1,
.top-powerOfEditing__article-img2,
.top-powerOfEditing__article-img3,
.top-powerOfEditing__article-img4,
.top-powerOfEditing__article-img5,
.top-powerOfEditing__article-img6 {
  border-radius: 20px;
}

.top-powerOfEditing__article-img1.top-card1__img img,
.top-powerOfEditing__article-img2.top-card1__img img,
.top-powerOfEditing__article-img3.top-card1__img img,
.top-powerOfEditing__article-img4.top-card1__img img,
.top-powerOfEditing__article-img5.top-card1__img img,
.top-powerOfEditing__article-img6.top-card1__img img {
  aspect-ratio: 136/204;
}

.top-powerOfEditing__category {
  background-color: #FF0926;
}

.top-powerOfEditing__category.top-card1__category img {
  max-width: 5.1875rem;
}

h3.top-powerOfEditing__article-title.top-card1__title {
  margin-top: 0.625rem;
  font-size: 0.8125rem;
  line-height: 1.1538461538;
}

.top-powerOfEditing__article-text {
  margin-top: 0.3125rem;
  font-size: 0.8125rem;
  line-height: 1.1538461538;
  color: #383838;
}

.top-powerOfEditing__topics {
  margin-top: 0.3125rem;
  font-size: 10px;
  line-height: 2.2;
  color: #383838;
}

.top-powerOfEditing__top-button__arrow {
  left: 2.5rem;
}

.top-powerOfEditing__button {
  margin-top: 1.125rem;
  justify-content: right;
  padding-right: 5px;
}

.top-card1__top-link:hover .top-powerOfEditing__top-button__arrow {
  transform: translateX(8px);
}

.top-seminer__section-title {
  max-width: 11.625rem;
  width: 100%;
}

.top-seminer__top-bg {
  background: url(../images/top/seminer-bg.webp) no-repeat center/cover;
}

.top-seminer__top-title--en.top-card1__title--en {
  max-width: 10.875rem;
}

.top-seminer__top-button {
  display: inline-flex;
  align-items: center;
}

.top-seminer__article-img1,
.top-seminer__article-img2,
.top-seminer__article-img3,
.top-seminer__article-img4,
.top-seminer__article-img5,
.top-seminer__article-img6 {
  border-radius: 20px;
}

.top-seminer__article-img1.top-card1__img img,
.top-seminer__article-img2.top-card1__img img,
.top-seminer__article-img3.top-card1__img img,
.top-seminer__article-img4.top-card1__img img,
.top-seminer__article-img5.top-card1__img img,
.top-seminer__article-img6.top-card1__img img {
  aspect-ratio: 136/204;
}

.top-seminer__category {
  background-color: #d0b627;
}

.top-seminer__category.top-card1__category img {
  max-width: 2.5rem;
}

h3.top-seminer__article-title.top-card1__title {
  margin-top: 0.625rem;
  font-size: 0.8125rem;
  line-height: 1.1538461538;
}

.top-seminer__article-text {
  margin-top: 0.3125rem;
  font-size: 0.8125rem;
  line-height: 1.1538461538;
  color: #383838;
}

.top-seminer__topics {
  margin-top: 0.3125rem;
  font-size: 10px;
  line-height: 2.2;
  color: #383838;
}

.top-seminer__top-button__arrow {
  left: 2.5rem;
}

.top-seminer__button {
  margin-top: 1.125rem;
  justify-content: right;
  padding-right: 5px;
}

.top-card1__top-link:hover .top-seminer__top-button__arrow {
  transform: translateX(8px);
}

.top-knowledge__section-title {
  max-width: 16.25rem;
  width: 100%;
}

.top-knowledge__article-content {
  margin-top: 1.875rem;
}

.top-knowledge__category {
  background-color: #6e0054;
}

.top-knowledge__button {
  margin-top: 1.25rem;
  justify-content: flex-end;
}

.top-document__section-title {
  max-width: 14.125rem;
  width: 100%;
}

.top-document__article-content {
  margin-top: 1.875rem;
}

figure.top-document__article-img1.top-card2__img {
  max-width: 20.9375rem;
}

figure.top-document__article-img1.top-card2__img img {
  aspect-ratio: 335/233;
}

.top-document__download {
  margin-top: 0.4375rem;
  width: 100%;
  background-color: #404040;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  padding: 0.1875rem 0;
}

span.download-icon img {
  width: 1.3125rem;
  height: 0.9375rem;
}

.top-document__download img {
  width: 6.375rem;
}

.top-document__category {
  background-color: #4e2e12;
}

.top-document__button {
  margin-top: 2rem;
  justify-content: flex-end;
}

.layout-top-mv {
  margin-top: 0rem;
}

.layout-top-publication {
  margin-top: 3.9375rem;
}

.layout-top-powerOfEditing {
  margin-top: 7.25rem;
}

.layout-top-seminer {
  margin-top: 7.25rem;
}

.layout-top-knowledge {
  margin-top: 6.3125rem;
}

.layout-top-document {
  margin-top: 5rem;
  margin-bottom: 2.625rem;
}

.stripe {
  background: repeating-linear-gradient(-65deg, #fff 3px, #fff 5px, #d0d0d0 5px, #d0d0d0 6px);
  width: 100%;
  height: 7px;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.about__mv-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__mv-title--en {
  max-width: 12.75rem;
  width: 100%;
  text-align: center;
}

.about__mv-title--ja {
  font-size: 2.125rem;
  line-height: 1.5294117647;
}

.about__mv-bg {
  margin-top: 2rem;
  background-color: #efefef;
  padding: 3.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about__mv-text {
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.1em;
  line-height: 1.8125;
  color: #000000;
  text-align: center;
  max-width: 62.875rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.3125rem;
}

.about__mv-text2,
.about__mv-text3 {
  margin-top: 2rem;
}

.about01__inner {
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.about-section__text {
  margin-top: 1.3125rem;
}

.about01__lists {
  margin-top: 1.25rem;
}

.about01__list {
  margin-top: 0.625rem;
}

.about01__title {
  font-weight: bold;
  font-size: 1.0625rem;
  letter-spacing: 0.1em;
  color: white;
  background-color: #0077B4;
  padding: 0.3125rem 0 0.6875rem;
}

.about01__text {
  margin-top: 0.8125rem;
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 2;
  color: #000000;
}

.about02__inner {
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.about02__section-text.about-section__text {
  font-weight: 400;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 2;
  color: #000000;
}

figure.about02__img1 img {
  margin-top: 1.25rem;
  max-width: 37.5rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.125rem;
}

figure.about02__img2 img {
  margin-top: 1.25rem;
  max-width: 37.5rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.about02__figcaption {
  margin-top: 1.25rem;
  font-weight: 400;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 2;
  color: #000000;
  width: 100%;
}

.about03__inner {
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.about03__section-text.about-section__text {
  margin-top: 1.3125rem;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 2;
  letter-spacing: 0.1em;
  color: #000000;
}

.about03__img img {
  margin-top: 1.25rem;
  max-width: 37.5rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.about03__text {
  margin-top: 1.25rem;
  font-weight: 400;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 2;
  color: #000000;
}

.about03__text2 {
  margin-top: 2.5rem;
}

.about04__inner {
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.about04__section-text.about-section__text {
  margin-top: 1.3125rem;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 2;
  letter-spacing: 0.1em;
  color: #000000;
}

.about04__text {
  margin-top: 1.25rem;
  font-weight: 400;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 2;
  color: #000000;
}

.about04__text2,
.about04__text3 {
  margin-top: 2.375rem;
}

.about04__img img {
  margin-top: 1.25rem;
  max-width: 37.5rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.layout-about {
  margin-top: 2rem;
}

.layout-about01 {
  margin-top: 3.125rem;
}

.layout-about02 {
  margin-top: 3.125rem;
}

.layout-about03 {
  margin-top: 3.125rem;
}

.layout-about04 {
  margin-top: 3.125rem;
  margin-bottom: 5rem;
}

.about-section__number {
  max-width: 4.25rem;
  width: 100%;
  height: auto;
  margin-inline: auto;
  position: relative;
  margin-bottom: 5rem;
}

.about-section__number::after {
  position: absolute;
  content: "";
  bottom: -80%;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #707070;
  height: 4.3125rem;
}

.about-section__title {
  font-size: 1.625rem;
  line-height: 1.5;
  text-align: center;
  color: #0077B4;
}

.about-section__text {
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: 0.1em;
  color: #000000;
}

.sixstrength__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.sixstrength__bg {
  background: url(../images/sixstrength/sp-sixstrength-bg.webp) no-repeat center/cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  padding-inline: 1.25rem;
  aspect-ratio: 335/459;
}

.sixstrength__category {
  background-color: #0077B4;
}

.sixstrength__text-content {
  position: relative;
  max-width: 37.5rem;
  width: 100%;
}

.sixstrength__title--en {
  max-width: 37.5rem;
  margin-inline: auto;
  width: 100%;
}

.sixstrength__title--ja {
  margin-top: 0.9375rem;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.53125;
  color: white;
  text-align: center;
}

.sixstrength__text {
  margin-top: 1.6875rem;
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: 0.1em;
  color: white;
  text-align: center;
}

.sixstrength__article-container {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding: 0.9375rem 0;
}

.sixstrength__article-content {
  display: flex;
  flex-direction: column;
  padding: 0.9375rem 1.25rem;
}

article.sixstrength__article:nth-child(1) {
  margin-top: 0;
}

.sixstrength__article:nth-child(2n) > .sixstrength__article-content {
  background-color: #f4f4f4;
}

.sixstrength__article-img {
  margin-top: 0.625rem;
  max-width: 41.875rem;
  width: 100%;
  flex-shrink: 0;
  order: 0;
}

.sixstrength__article-img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 335/160;
  object-position: center;
}

.sixstrength__article-body {
  display: contents;
}

.sixstrength__category {
  order: -1;
  width: fit-content;
  padding: 0 10px;
}

.sixstrength__category img {
  width: 2.9375rem;
}

.sixstrength__article-number {
  order: -1;
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 1.175;
  color: #383838;
}

.sixstrength__article-title {
  order: -1;
  font-weight: 900;
  font-size: 1.4375rem;
  line-height: 1.2608695652;
  color: #383838;
}

.sixstrength__article-text {
  margin-top: 0.625rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #383838;
}

.layout-sixstrength {
  margin-top: 1.9375rem;
  margin-bottom: 5rem;
}

.publication__inner {
  width: 100%;
  padding: 0 1rem 0 1.1875rem;
}

.publication__pagination {
  margin-top: 3.0625rem;
}

.card-list__img {
  overflow: hidden;
}

.card-list__img img {
  transition: transform 0.5s ease;
}

.card-list__link:hover .card-list__img img {
  transform: scale(1.1);
}

.layout-publication {
  margin-top: 1.9375rem;
  margin-bottom: 5.0625rem;
}

.publication-detail__inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.25rem;
  margin-inline: auto;
}

.publication-detail__book-title {
  max-width: 16.9375rem;
  width: 100%;
  margin-top: 2.5rem;
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: 0.3em;
  line-height: 1.0714285714;
  color: #8b7086;
}

.publication-detail__container {
  margin-top: 1.25rem;
}

.publication-detail__content-flex {
  display: flex;
  flex-direction: column;
}

.publication-detail__img {
  max-width: 16.9375rem;
  width: 100%;
  margin: 0 auto;
}

.publication-detail__img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 271/415;
}

.publication-detail__body {
  margin-top: 2.5rem;
}

.publication-detail__title {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.3181818182;
  color: #383838;
}

.publication-detail__list {
  margin-top: 1.3125rem;
}

.publication-detail__item {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 2.1666666667;
  color: #1c1c1c;
}

.publication-detail__tag-wrap {
  margin-top: 1.375rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0.6875rem;
  grid-column-gap: 1rem;
}

.publication-detail__tag1,
.publication-detail__tag3 {
  max-width: 10rem;
  width: 100%;
}

.publication-detail__tag2,
.publication-detail__tag4 {
  max-width: 9.875rem;
  width: 100%;
}

.publication-detail__tag3,
.publication-detail__tag4 {
  font-weight: bold;
  font-size: 0.9375rem;
  line-height: 1.7333333333;
  text-align: center;
  color: white;
  border-radius: 5px;
  padding: 8px 0;
}

.publication-detail__tag3 {
  background: #4f89a8;
}

.publication-detail__tag4 {
  background: #a88d4f;
}

.publication-detail__content-text {
  margin-top: 3.125rem;
}

.publication-detail__subtitle {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #383838;
}

.publication-detail__subtext-content {
  margin-top: 1.3125rem;
}

.publication-detail__subtext {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.7142857143;
}

.publication-detail__subtext2,
.publication-detail__subtext3,
.publication-detail__subtext4 {
  margin-top: 2.0625rem;
}

.publication-detail__author-content {
  margin-top: 2.75rem;
  border-left: 9px solid #005a2d;
  background: repeating-linear-gradient(-65deg, #eaeaea 2px, #eaeaea 5px, #f8f8f8 5px, #f8f8f8 6px);
  width: 100%;
  height: auto;
  padding: 2.1875rem 1.375rem 1.625rem 1.25rem;
}

.publication-detail__author-title {
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1.6666666667;
}

.publication-detail__author-name {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 0.7142857143;
}

.publication-detail__author-text {
  margin-top: 1.3125rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

.layout-publication-detail {
  margin-top: 1.9375rem;
}

.layout-sub-contents {
  margin-top: 3.1875rem;
}

.layout-sub-recomend {
  margin-top: 3.75rem;
}

.accordion__button {
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  padding-right: 21px;
}

.accordion__button:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  /*横線*/
  width: 15px;
  height: 2px;
  background: #101010;
}

.accordion__button:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
  transition: all 0.3s;
  /*縦線*/
  width: 2px;
  height: 15px;
  background: #101010;
}

/* 縦線（after）だけ縦回転させる */

.accordion__button.open:after {
  top: 25%;
  transform: rotate(90deg);
  opacity: 0;
}

.hidden-text {
  display: none;
}

.sub-contents__inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.25rem;
  margin-inline: auto;
}

.sub-contents__title-content {
  display: flex;
  align-items: center;
}

.sub-contents__title--en {
  max-width: 9.3125rem;
  width: 100%;
  margin-right: 1.1875rem;
}

.sub-contents__title--ja {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

.accordion__title-wrap {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion__button {
  font-family: "Space Grotesk", sans-serif;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1;
  color: #6e6e6e;
}

.accordion__title1 {
  font-weight: bold;
  font-size: 0.9375rem;
  line-height: 1.2;
}

.accordion__title {
  margin-top: 2.3125rem;
  font-weight: bold;
  font-size: 0.9375rem;
  line-height: 1.2;
}

.accordion__text {
  margin-top: 1.5625rem;
  text-indent: 1.125rem;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.3333333333;
}

.accordion__text-list {
  margin-top: 1.5625rem;
}

.accordion__text-item {
  text-indent: 1.125rem;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.3333333333;
}

.sub-contents__stripe {
  background: repeating-linear-gradient(-65deg, #fff 3px, #fff 5px, #d0d0d0 5px, #d0d0d0 6px);
  width: 100%;
  height: 13px;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.sub-contents__stripe.sub-contents__stripe--top {
  margin-top: 2.5rem;
}

.sub-contents__news {
  margin: 1.25rem 0;
}

.sub-contents__news-text {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

.sub-contents__pdf {
  margin-top: 1.625rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #0177b4;
  text-decoration: underline;
}

.sub-contents__infomation {
  margin: 1.3125rem 0 1.25rem;
}

.sub-contents__infomation-title {
  max-width: 13.625rem;
  width: 100%;
}

.sub-contents__infomation-text {
  margin-top: 0.8125rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.7142857143;
}

.sub-recomend__bg {
  background: rgba(0, 90, 45, 0.1);
  padding: 3.1875rem 0 3.125rem;
}

.sub-recomend__inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.25rem;
  margin-inline: auto;
}

.sub-recomend__title {
  max-width: 9.9375rem;
  width: 100%;
}

.sub-recomend__items {
  margin-top: 2rem;
}

.sub-recomend__img {
  max-width: 8.5rem;
  width: 100%;
}

.sub-recomend__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 136/181;
}

.caseStudy__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.caseStudy__selectBox-content {
  margin-top: 1.25rem;
}

.caseStudy__container {
  margin-top: 3.125rem;
  grid-template-columns: 1fr;
  gap: 28px;
  row-gap: 56px;
}

.caseStudy__article {
  margin-top: 3.1875rem;
  max-width: 44.375rem;
  width: 100%;
}

.caseStudy__title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #383838;
}

.caseStudy__content {
  margin-top: 0.4375rem;
  display: flex;
  padding-bottom: 0.875rem;
  border-bottom: 1px solid #707070;
}

.caseStudy__img {
  max-width: 7.25rem;
  width: 100%;
  margin-right: 0.4375rem;
}

.caseStudy__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 116/161;
}

.caseStudy__author-img {
  max-width: 5rem;
  width: 100%;
  border-radius: 50%;
}

.caseStudy__author-img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
}

.caseStudy__author-text {
  margin-top: 0.4375rem;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  color: #383838;
}

.caseStudy__category-content {
  display: flex;
  justify-content: space-between;
}

.caseStudy__category-wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 16.875rem;
  width: 100%;
}

.caseStudy__category {
  display: inline;
  background: #5f9a4a;
  font-weight: 500;
  font-size: 10px;
  color: white;
  padding: 0 1rem;
  margin: 0.4375rem 0.4375rem 0 0;
  display: flex;
  align-items: center;
}

.caseStudy__button {
  display: flex;
  align-items: center;
  padding-right: 0.8125rem;
}

p.caseStudy__button-text.button__text {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 10px;
}

.caseStudy__button__arrow {
  width: 0.75rem;
  height: 0.75rem;
  position: relative;
  display: inline-block;
  color: #1C1C1C;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}

.caseStudy__button__arrow::before,
.caseStudy__button__arrow::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}

.caseStudy__button__arrow::before {
  left: 2px;
  width: 6px;
  height: 1px;
  background: #1C1C1C;
}

.caseStudy__button__arrow::after {
  left: 5px;
  width: 2px;
  height: 2px;
  border-top: 1px solid #1C1C1C;
  border-right: 1px solid #1C1C1C;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.caseStudy__img {
  overflow: hidden;
}

.caseStudy__img img {
  transition: transform 0.5s ease;
}

.caseStudy__link:hover .caseStudy__img img {
  transform: scale(1.1);
}

.caseStudy__link:hover .caseStudy__button__arrow {
  transform: translateX(8px);
}

.layout-caseStudy {
  margin-top: 1.9375rem;
  margin-bottom: 5rem;
}

.sub-selectBox__content {
  display: flex;
  justify-content: center;
}

.select {
  position: relative;
  height: 25px;
  cursor: pointer;
  background-color: #5f9a4a;
}

.select1 {
  margin-right: 0.5625rem;
  max-width: 130px;
  width: 100%;
}

.select2 {
  max-width: 130px;
  width: 100%;
}

.select__expand {
  width: 0;
  height: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.select__expand::after {
  content: "";
  display: block;
  width: 8px;
  height: 9px;
  background-image: url("../images/common/triangle-icon.webp");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 2;
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
}

.select__expand:checked::after {
  transform: translate(-50%, -50%) rotate(180deg) scaleX(-1);
}

.select__expandLabel {
  display: block;
  width: 100%;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.select__close {
  display: none;
}

.select__closeLabel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}

.select__items {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 25px;
}

.select__input {
  display: none;
}

.select__label {
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
  display: block;
  height: 0;
  font-size: 9px;
  line-height: 25px;
  overflow: hidden;
  color: white;
  background-color: #5f9a4a;
  cursor: pointer;
  padding-left: 13px;
  position: relative;
}

.select__label::before {
  content: "▶︎";
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 5px;
}

span.select__label.select__label-placeholder::before {
  display: none;
}

.select__label-placeholder {
  height: 25px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: -10px;
  background-color: transparent;
}

.select__expand:checked + .select__closeLabel {
  display: block;
}

.select__expand:checked + .select__closeLabel + .select__options .select__label {
  height: 25px;
}

.select__expand:checked + .select__closeLabel + .select__expandLabel {
  display: none;
}

.select__input:checked + .select__label {
  height: 25px;
  margin-top: -25px;
}

.caseStudy-detail__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.caseStudy-detail__container {
  margin-top: 2.5rem;
}

.caseStudy-detail__content-flex {
  display: flex;
  flex-direction: column;
  max-width: 44.375rem;
  width: 100%;
  margin-bottom: 3.3125rem;
}

.caseStudy-detail__img {
  max-width: 17.5rem;
  width: 100%;
  margin: 0 auto;
}

.caseStudy-detail__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 280/396;
}

.caseStudy-detail__body {
  margin-top: 2.0625rem;
  max-width: 41.875rem;
  width: 100%;
}

.caseStudy-detail__title {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.3181818182;
  color: #383838;
}

.caseStudy-detail__category-wrap {
  margin-top: 0.3125rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.caseStudy__category {
  display: inline;
  background: #5f9a4a;
  font-weight: 500;
  font-size: 0.8125rem;
  color: white;
  line-height: 1.5384615385;
  padding: 0 1.25rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

.caseStudy-detail__auther-wrap {
  margin-top: 1.375rem;
}

.caseStudy-detail__auther-name {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.75;
  color: #383838;
}

.caseStudy-detail__auther-text {
  margin-top: 0.625rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #383838;
}

.layout-caseStudy-detail {
  margin-top: 2.125rem;
  margin-bottom: 3.125rem;
}

.caseStudy-detail-content h3 {
  margin-bottom: 1.9375rem;
  background-image: url("../images/caseStudy-detail/caseStudy-detail-title-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5;
  color: white;
  padding: 0 0.25rem;
}

.caseStudy-detail-content p.caseStudy-detail-content__text-l {
  margin-bottom: 1.875rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25;
  color: #383838;
}

.caseStudy-detail-content p.caseStudy-detail-content__text-s {
  margin-bottom: 1.875rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2;
  color: #383838;
}

.caseStudy-detail-content .caseStudy-detail-content__img {
  margin-bottom: 1.875rem;
  max-width: 20.9375rem;
  width: 100%;
  margin-inline: auto;
}

.caseStudy-detail-content .caseStudy-detail-content__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 335/224;
}

.sub-caseStudy-detail__bg {
  background: rgba(0, 90, 45, 0.1);
  padding: 3.1875rem 0 2.3125rem;
}

.sub-caseStudy-detail__inner {
  max-width: 44.375rem;
  width: 100%;
  padding-inline: 1.25rem;
  margin-inline: auto;
}

.sub-caseStudy-detail__section-title {
  max-width: 10.75rem;
  width: 100%;
}

.sub-caseStudy-detail__container {
  margin-top: 0.75rem;
  grid-template-columns: 1fr;
  gap: 28px;
  row-gap: 56px;
}

.sub-caseStudy-detail__article {
  margin-top: 3.1875rem;
  max-width: 44.375rem;
  width: 100%;
}

.sub-caseStudy-detail__title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #383838;
}

.sub-caseStudy-detail__content {
  margin-top: 0.4375rem;
  display: flex;
  padding-bottom: 0.875rem;
  border-bottom: 1px solid #707070;
}

.sub-caseStudy-detail__img {
  max-width: 7.25rem;
  width: 100%;
  margin-right: 0.4375rem;
}

.sub-caseStudy-detail__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 116/161;
}

.sub-caseStudy-detail__author-img {
  max-width: 5rem;
  width: 100%;
  border-radius: 50%;
}

.sub-caseStudy-detail__author-img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
}

.sub-caseStudy-detail__author-text {
  margin-top: 0.4375rem;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  color: #383838;
}

.sub-caseStudy-detail__category-content {
  display: flex;
  justify-content: space-between;
}

.sub-caseStudy-detail__category-wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 16.875rem;
  width: 100%;
}

.sub-caseStudy-detail__category {
  display: inline;
  background: #5f9a4a;
  font-weight: 500;
  font-size: 10px;
  color: white;
  padding: 0 1rem;
  margin: 0.4375rem 0.4375rem 0 0;
  display: flex;
  align-items: center;
}

.sub-caseStudy-detail__button {
  display: flex;
  align-items: center;
  padding-right: 0.8125rem;
}

p.sub-caseStudy-detail__button-text.button__text {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 10px;
}

.sub-caseStudy-detail__button__arrow {
  width: 0.75rem;
  height: 0.75rem;
  position: relative;
  display: inline-block;
  color: #1C1C1C;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}

.sub-caseStudy-detail__button__arrow::before,
.sub-caseStudy-detail__button__arrow::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}

.sub-caseStudy-detail__button__arrow::before {
  left: 2px;
  width: 6px;
  height: 1px;
  background: #1C1C1C;
}

.sub-caseStudy-detail__button__arrow::after {
  left: 5px;
  width: 2px;
  height: 2px;
  border-top: 1px solid #1C1C1C;
  border-right: 1px solid #1C1C1C;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sub-caseStudy-detail__img {
  overflow: hidden;
}

.sub-caseStudy-detail__img img {
  transition: transform 0.5s ease;
}

.sub-caseStudy-detail__link:hover .sub-caseStudy-detail__img img {
  transform: scale(1.1);
}

.sub-caseStudy-detail__link:hover .sub-caseStudy-detail__button__arrow {
  transform: translateX(8px);
}

.document {
  position: relative;
  background-color: #f7f7f7;
  padding: 2.4375rem 0.5rem 1.75rem;
  text-align: center;
}

.document::after {
  position: absolute;
  content: "";
  top: 2.4375rem;
  left: 0;
  background-image: url("../images/document/document-mv-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 83%;
  z-index: 1;
}

.document__section-title-contents {
  margin-top: -0.5rem;
  z-index: 3;
  position: relative;
}

.document__sub-section-title.sub-section-title__en {
  max-width: 11.75rem;
}

.document__bg {
  position: relative;
  z-index: 3;
}

.document__mv-img {
  overflow: hidden;
}

.document__mv-img img {
  transition: transform 0.5s ease;
}

.document__link:hover .document__mv-img img {
  transform: scale(1.1);
}

.document__inner {
  display: inline-block;
  margin-top: 0.5625rem;
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  border-radius: 13px;
  background-color: #fff;
  padding: 1.25rem 0 2.8125rem;
}

.document__mv-img {
  max-width: 39.875rem;
  width: 100%;
  margin-inline: auto;
}

.document__mv-img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 319/176;
}

.document__mv-title {
  margin-top: 0.625rem;
  max-width: 17rem;
  width: 100%;
  margin-inline: auto;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  color: #383838;
}

.document__download {
  display: inline-flex;
  position: absolute;
  bottom: -1.125rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: 15.3125rem;
  width: 100%;
  margin-inline: auto;
  background-color: #404040;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  padding: 0.5rem 0;
}

.document__download-icon {
  display: inline-block;
}

span.document__download-icon img {
  width: 0.9375rem;
  height: 0.6875rem;
}

.document__download img {
  width: 4.5rem;
}

.document-menu___menu-list {
  margin-top: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 37.5rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.document-menu___flex {
  display: flex;
}

.document-menu__item {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5;
}

.document-menu___flex:nth-child(1) > .document-menu__item:nth-child(1) {
  padding: 0 2.25rem 0 0;
  border-right: 1px solid #1c1c1c;
}

.document-menu___flex:nth-child(1) > .document-menu__item:nth-child(2) {
  padding: 0 0 0 3.0625rem;
}

.document-menu___flex:nth-child(2) {
  margin-top: 0.625rem;
}

.document-menu___flex:nth-child(2) > .document-menu__item:nth-child(1) {
  padding: 0 2.625rem 0 0;
  border-right: 1px solid #1c1c1c;
}

.document-menu___flex:nth-child(2) > .document-menu__item:nth-child(2) {
  padding: 0 0 0 2.6875rem;
}

.card-list__img {
  overflow: hidden;
}

.card-list__img img {
  transition: transform 0.5s ease;
}

.card-list__link:hover .card-list__img img {
  transform: scale(1.1);
}

.corporate-publishing__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.corporate-publishing__article-content {
  margin-top: 1.5625rem;
}

.branding__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.branding__article-content {
  margin-top: 1.5625rem;
}

.publication-marketing__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.publication-marketing__article-content {
  margin-top: 1.5625rem;
}

.marketing__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.marketing__article-content {
  margin-top: 1.5625rem;
}

.document-section-title__contents {
  max-width: 43.5rem;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-inline: auto;
  padding: 0 0 0.25rem 0.375rem;
  border-bottom: 1px solid #404040;
  border-left: 4px solid #404040;
}

.document-section-title__en {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.2222222222;
}

.section-button {
  text-align: right;
}

.section-button__link {
  display: inline-flex;
  align-items: center;
  padding-right: 1.25rem;
}

.section-button__arrow {
  position: absolute;
  left: 10px;
  background-color: transparent;
  border-radius: 50px;
  width: 16px;
  height: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease;
}

.section-button__arrow {
  border: 1px solid #1C1C1C;
}

.section-button__link:hover .section-button__arrow {
  transform: translateX(8px);
}

.section-button__text {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1;
}

.section-arrow--black {
  position: relative;
  display: inline-block;
  color: #1C1C1C;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}

.section-arrow--black::before,
.section-arrow--black::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}

.section-arrow--black::before {
  left: 2px;
  width: 8px;
  height: 1px;
  background: #1C1C1C;
}

.section-arrow--black::after {
  left: 5px;
  width: 4px;
  height: 4px;
  border-top: 1px solid #1C1C1C;
  border-right: 1px solid #1C1C1C;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.layout-corporate-publishing {
  margin-top: 3.875rem;
}

.layout-branding {
  margin-top: 5rem;
}

.layout-publication-marketing {
  margin-top: 5rem;
}

.layout-marketing {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.document-detail__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.125rem;
}

.document-detail__container {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #ededed;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.document-detail__img-content {
  max-width: 39.875rem;
  width: 100%;
  margin-inline: auto;
  padding: 2.5rem 0.5rem 2.625rem;
}

.document-detail__title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  color: #383838;
}

.document-detail__img {
  width: 100%;
  margin-top: 0.625rem;
}

.document-detail__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 319/176;
}

.document-detail__text-content {
  background-color: #f0f0f0;
  background-image: url("../images/document-detail/document-detail-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 39.875rem;
  width: 100%;
  height: 100%;
  padding: 3.625rem 0.625rem 3.6875rem 0.8125rem;
}

.document-detail__text-title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  color: #4e2e12;
  padding-bottom: 0.125rem;
  border-bottom: 4px solid #4e2e12;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 1.4375rem;
}

.document-detail__text-title::before {
  position: absolute;
  content: "";
  left: 0;
  width: 1.0625rem;
  height: 1.0625rem;
  margin-right: 0.3125rem;
  background-image: url("../images/document-detail/document-detail-icon.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.document-detail__list {
  margin-top: 0.875rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  list-style: decimal;
  padding-left: 0.9375rem;
}

.layout-document-detail {
  margin-top: 1.9375rem;
  margin-bottom: 3.5rem;
}

.document-form__inner {
  max-width: 41.875rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.document-form__content {
  width: 100%;
}

.document-form__label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
}

.document-form__label-name {
  margin-right: 0.375rem;
}

form > div:nth-child(7) > label > span.document-form__required {
  margin-right: 0;
}

.document-form__required {
  width: 1.9375rem;
  height: 0.875rem;
  font-weight: 400;
  font-size: 10px;
  color: white;
  background-color: #760303;
  margin-right: 0.625rem;
  padding: 0 0.3125rem;
}

.document-form__input {
  border: none;
  background-color: #f7f7f7;
  height: 2.375rem;
  width: 100%;
  max-width: 45.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #868686;
  padding: 0.5rem 0.5rem;
  margin: 0.25rem 0 0.625rem 0;
}

.form-control {
  border: none;
  background-color: #f7f7f7;
  height: 2.375rem;
  width: 100%;
  padding: 0.5rem 0.5rem;
  margin: 0.25rem 0 0.625rem 0;
}

.document-form__text {
  font-weight: 400;
  font-size: 11px;
}

.document-form__text--right {
  font-weight: 400;
  font-size: 11px;
  margin-left: 0;
}

.document-form__select {
  border: none;
  background-color: #f7f7f7;
  height: 2.375rem;
  width: 100%;
  max-width: 26.6875rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #868686;
  padding: 0.5rem 0.5rem;
  margin: 0.25rem 0 0.625rem 0;
}

.document-form__select-content.document-form__select-content--middle {
  max-width: 33.6875rem;
}

.document-form__select-content {
  position: relative;
  max-width: 26.6875rem;
  width: 100%;
}

.document-form__select.document-form__select--middle {
  max-width: 33.6875rem;
}

.document-form__select {
  -webkit-appearance: none;
  appearance: none;
  /* デフォルトの矢印を非表示 */
}

.document-form__select::-ms-expand {
  display: none;
  /* デフォルトの矢印を非表示(IE用) */
}

/* セレクトボックスの矢印デザイン変更 */

.document-form__select-content::before {
  background: black;
  height: calc(tan(60deg) * 14px / 2);
  width: 13px;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  content: "";
  position: absolute;
  right: 12px;
  top: 18px;
}

.document-form__optional {
  width: 1.9375rem;
  height: 0.875rem;
  font-weight: 400;
  font-size: 10px;
  color: white;
  background-color: #4a81ff;
  margin-right: 0.625rem;
  padding: 0 0.3125rem;
}

.document-form__textarea {
  border: none;
  background-color: #f7f7f7;
  height: 10.0625rem;
  width: 100%;
  max-width: 45.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #868686;
  padding: 0.5rem 1.25rem;
  margin: 0.25rem 0 0.625rem 0;
}

.document-form__privacy {
  margin-top: 1.25rem;
}

.document-form__privacy-text,
.document-form__acceptance {
  font-weight: 400;
  font-size: 14px;
}

.document-form__privacy-text > a {
  text-decoration: underline;
  color: #2680eb;
}

.document-form__privacy input[type=checkbox] + span {
  margin-top: 1.875rem;
  display: inline-block;
  position: relative;
}

.contact__form-privacy input[type=checkbox]:checked + span::before {
  background: #f7f7f7;
}

.contact__form-privacy input[type=checkbox] + span::before {
  background: #f7f7f7;
  border: 1px solid #707070;
  content: "";
  display: block;
  width: 0.9375rem;
  height: 0.9375rem;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.document-form__button-wrap {
  margin-top: 1.75rem;
  max-width: 19.6875rem;
  width: 100%;
  margin-inline: auto;
  border-radius: 10px;
  background: #2680eb;
  text-align: center;
}

button.document-form__button {
  padding: 0.625rem 0;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5555555556;
  color: white;
  display: inline-block;
  width: 100%;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.layout-document-form {
  margin-top: 3.4375rem;
  margin-bottom: 5rem;
}

.knowledge__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.knowledge__menu-content {
  display: none;
}

.knowledge__menu-list ul {
  margin-top: 1.25rem;
  display: flex;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
}

.knowledge__menu-item {
  padding: 0.3125rem 1.8125rem;
  border-right: 1px solid #1c1c1c;
}

.knowledge__menu-list > ul > li:nth-child(1) {
  padding-left: 1.5rem;
}

.knowledge__menu-list > ul > li:nth-child(7) {
  border-right: none;
}

.knowledge__tag-list ul {
  background: #f0f0f0;
  padding: 1.4375rem 4.375rem 1.5rem 2.8125rem;
  margin-top: 1.125rem;
  display: flex;
  gap: 1.1875rem;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
}

.knowledge-selectBox__content.sub-selectBox__content {
  margin-top: 1.3125rem;
  display: flex;
}

.knowledge-card__article-content {
  margin-top: 1.5625rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2.1875rem;
}

.knowledge-card__img {
  max-width: 37.5rem;
  width: 100%;
  padding-inline: 0.875rem;
  margin-inline: auto;
}

.knowledge-card__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 300/165;
}

.knowledge-card__body {
  margin-top: 0.3125rem;
  max-width: 37.5rem;
  width: 100%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.knowledge-card__title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  color: #383838;
}

.knowledge-card__article-tag-wrap {
  display: flex;
  gap: 1.25rem;
}

.knowledge-card__article-tag {
  margin-top: 0.3125rem;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
}

.knowledge-card__img {
  overflow: hidden;
}

.knowledge-card__img img {
  transition: transform 0.5s ease;
}

.knowledge-card__link:hover .knowledge-card__img img {
  transform: scale(1.1);
}

.knowledge-card__link:hover .knowledge__button__arrow {
  transform: translateX(8px);
}

.layout-knowledge {
  margin-top: 1.9375rem;
}

.knowledge-selectBox-content.sub-selectBox__content {
  width: 100%;
  margin-top: 3.1875rem;
  display: flex;
  justify-content: center;
}

.knowledge-select {
  position: relative;
  height: 25px;
  cursor: pointer;
}

.knowledge-select1 {
  margin-right: 0.5625rem;
  max-width: 10.25rem;
  width: 100%;
  background-color: #6e0054;
  color: white;
  z-index: 10;
}

.knowledge-select2 {
  max-width: 10.25rem;
  width: 100%;
  background-color: #F0F0F0;
  color: #404040;
  z-index: 10;
}

.knowledge-select__expand {
  width: 0;
  height: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.knowledge-select__expand::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
  font-size: 10px;
  pointer-events: none;
  z-index: 2;
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
}

.knowledge-select__expand:checked::after {
  transform: translate(-50%, -50%) rotate(180deg) scaleX(-1);
}

.knowledge-select__expand1::after {
  color: white;
}

.knowledge-select__expand2::after {
  color: #1C1C1C;
}

.knowledge-select__expandLabel {
  display: block;
  width: 100%;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.knowledge-select__close {
  display: none;
}

.knowledge-select__closeLabel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}

.knowledge-select__items {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 25px;
}

.knowledge-select__input {
  display: none;
}

.knowledge-select__label {
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
  display: block;
  height: 0;
  font-size: 9px;
  line-height: 25px;
  overflow: hidden;
  color: white;
  background-color: #6e0054;
  cursor: pointer;
  padding-left: 11px;
  position: relative;
}

.knowledge-select__label2.knowledge-select__label {
  color: #404040;
  background-color: #F0F0F0;
}

.knowledge-select__label1.knowledge-select__label.select__label-placeholder {
  color: white;
  height: 25px;
  left: 0px;
}

.knowledge-select__label2.knowledge-select__label.select__label-placeholder {
  color: #404040;
  background-color: #F0F0F0;
  height: 25px;
  left: 0px;
}

span.knowledge-select__label.knowledge-select__label-placeholder::before {
  display: none;
}

.knowledge-select__label-placeholder {
  height: 25px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0px;
  background-color: transparent;
}

.knowledge-select__expand:checked + .knowledge-select__closeLabel {
  display: block;
}

.knowledge-select__expand:checked + .knowledge-select__closeLabel + .knowledge-select__options .knowledge-select__label {
  height: 25px;
}

.knowledge-select__expand:checked + .knowledge-select__closeLabel + .knowledge-select__expandLabel {
  display: none;
}

.knowledge-select__input:checked + .knowledge-select__label {
  height: 25px;
  margin-top: -25px;
}

.knowledge__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.knowledge___menu-list ul {
  display: none;
}

.knowledge__menu-item {
  border-right: 1px solid #1c1c1c;
  padding-right: 1.875rem;
}

.knowledge___tag-list ul {
  display: none;
}

.knowledge__article {
  margin-top: 3.1875rem;
  max-width: 44.375rem;
  width: 100%;
}

.knowledge__title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #383838;
}

.knowledge__content {
  margin-top: 0.4375rem;
  display: flex;
  padding-bottom: 0.875rem;
  border-bottom: 1px solid #707070;
}

.knowledge__img {
  max-width: 7.25rem;
  width: 100%;
  margin-right: 0.4375rem;
}

.knowledge__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 116/161;
}

.knowledge__author-img {
  max-width: 5rem;
  width: 100%;
  border-radius: 50%;
}

.knowledge__author-img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
}

.knowledge__author-text {
  margin-top: 0.4375rem;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  color: #383838;
}

.knowledge__category-content {
  display: flex;
  justify-content: space-between;
}

.knowledge__category-wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 16.875rem;
  width: 100%;
}

.knowledge__category {
  display: inline;
  background: #5f9a4a;
  font-weight: 500;
  font-size: 10px;
  color: white;
  padding: 0 1rem;
  margin: 0.4375rem 0.4375rem 0 0;
  display: flex;
  align-items: center;
}

.knowledge__button {
  display: flex;
  align-items: center;
  padding-right: 0.8125rem;
}

p.knowledge__button-text.button__text {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 10px;
}

.knowledge__button__arrow {
  width: 0.75rem;
  height: 0.75rem;
  position: relative;
  display: inline-block;
  color: #1C1C1C;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}

.knowledge__button__arrow::before,
.knowledge__button__arrow::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}

.knowledge__button__arrow::before {
  left: 2px;
  width: 6px;
  height: 1px;
  background: #1C1C1C;
}

.knowledge__button__arrow::after {
  left: 5px;
  width: 2px;
  height: 2px;
  border-top: 1px solid #1C1C1C;
  border-right: 1px solid #1C1C1C;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.knowledge__img {
  overflow: hidden;
}

.knowledge__img img {
  transition: transform 0.5s ease;
}

.knowledge__link:hover .knowledge__img img {
  transform: scale(1.1);
}

.knowledge__link:hover .knowledge__button__arrow {
  transform: translateX(8px);
}

.layout-knowledge-new-articles {
  margin-top: 5.0625rem;
}

.layout-knowledge-publication {
  margin-top: 5.0625rem;
}

.layout-knowledge-marketing {
  margin-top: 5.0625rem;
}

.layout-knowledge-branding {
  margin-top: 5.0625rem;
}

.layout-knowledge-inner-branding {
  margin-top: 5.0625rem;
}

.layout-knowledge-recruiting {
  margin-top: 5.0625rem;
}

.layout-knowledge-purpose {
  margin-top: 5.0625rem;
}

.layout-knowledge-others {
  margin-top: 5.0625rem;
  margin-bottom: 5rem;
}

.layout-knowledge-popular-articles {
  margin-top: 3.1875rem;
}

.knowledge-detail__section-title {
  margin-top: 1.9375rem;
}

.knowledge-detail-selectBox__content.sub-selectBox__content {
  margin-top: 3.1875rem;
}

.knowledge-detail__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.knowledge-detail__menu-content {
  display: none;
}

.knowledge-detail__content {
  display: flex;
  flex-direction: column;
}

.knowledge-detail__head {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
}

.knowledge-detail__title {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.3181818182;
  color: #6e0054;
  border-left: 10px solid #6e0054;
  padding-left: 0.3125rem;
}

.knowledge-detail__tag-wrap {
  margin-top: 0.625rem;
  display: flex;
}

.knowledge-detail__tag {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
}

.knowledge-detail__date-wrap {
  margin-top: 0.1875rem;
  display: flex;
  flex-wrap: wrap;
}

.knowledge-detail__date {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5;
}

.knowledge-detail__date:nth-child(1) {
  padding-right: 0.9375rem;
  border-right: 1px solid #1C1C1C;
}

.knowledge-detail__date:nth-child(2) {
  padding-left: 0.9375rem;
}

.knowledge-detail__flex {
  display: flex;
  flex-direction: column-reverse;
}

.knowledge-detail__sns-wrap {
  margin-top: 0.625rem;
}

.knowledge-detail__img {
  margin-top: 1.3125rem;
  width: 100%;
  margin-inline: auto;
}

.knowledge-detail__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 880/462;
}

.knowledge-detail__text-wrap {
  margin-top: 1.875rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2;
  order: 5;
}

.knowledge-detail__text + .knowledge-detail__text {
  margin-top: 1.875rem;
}

.knowledge-detail__banner {
  margin-top: 1.875rem;
  max-width: 41.875rem;
  width: 100%;
  margin-inline: auto;
}

.knowledge-contents__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.knowledge-contents__bg {
  background-color: #f0f0f0;
  border-left: 9px solid #6e0054;
  padding: 1.3125rem 0.5rem 1.9375rem 0.625rem;
  margin-bottom: 3.125rem;
}

.knowledge-contents__title-content {
  display: flex;
  align-items: center;
  gap: 0.4375rem;
}

.knowledge-contents__title--en {
  max-width: 9.875rem;
  width: 100%;
}

.knowledge-contents__title--ja {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1;
}

.knowledge-contents__list {
  margin-top: 2.3125rem;
}

.knowledge-contents__list-title {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 2;
  position: relative;
  padding-left: 1.25rem;
}

.knowledge-contents__list-title::before {
  position: absolute;
  content: "";
  top: 0.4375rem;
  left: 0;
  width: 0.9375rem;
  height: 0.9375rem;
  background-image: url("../images/common/knowledge-contents-icon.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.knowledge-contents__list-sub-title {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2;
  position: relative;
  padding-left: 1.625rem;
}

.knowledge-contents__list-sub-title::before {
  position: absolute;
  content: "";
  top: 0.5rem;
  left: 0.6875rem;
  width: 0.6875rem;
  height: 0.6875rem;
  background-image: url("../images/common/knowledge-contents-icon.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.knowledge-detail-contents h3 {
  margin-bottom: 1.9375rem;
  background-image: url("../images/knowledge-detail/knowledge-detail-title-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5;
  color: white;
  padding: 0 0.25rem;
}

.knowledge-detail-contents p.knowledge-detail-content__text-l {
  margin-bottom: 1.875rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25;
  color: #383838;
}

.knowledge-detail-contents p.knowledge-detail-content__text-s {
  margin-bottom: 1.875rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2;
  color: #383838;
}

.knowledge-detail-contents .knowledge-detail-content__img {
  margin-bottom: 3.125rem;
  max-width: 21.25rem;
  width: 100%;
  margin-inline: auto;
}

.knowledge-detail-contents .knowledge-detail-content__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 340/133;
}

.knowledge-download__content {
  background: #eaeaea;
  padding: 1.875rem 0.8125rem 1.875rem;
}

.knowledge-download__title {
  font-size: 1.3125rem;
  line-height: 1.380952381;
  max-width: 19.3125rem;
  width: 100%;
  margin-inline: auto;
  text-align: center;
}

.knowledge-download__title > p:nth-child(1) {
  font-weight: bold;
  background-color: #272727;
  color: white;
  padding: 0.375rem 0;
}

.knowledge-download__title > p:nth-child(2) {
  font-weight: 400;
  background-color: #eaeaea;
  color: #000000;
  border: 1px solid #707070;
  padding: 0.375rem 0;
}

.knowledge-download__text {
  margin-top: 1.4375rem;
  text-align: center;
}

.knowledge-download__text p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1;
}

.knowledge-download__text p:nth-child(2) {
  margin-top: 1.25rem;
}

.knowledge-download__img {
  margin-top: 2.5rem;
  max-width: 38.625rem;
  width: 100%;
  margin-inline: auto;
}

.knowledge-download__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 309/162;
}

.knowledge-download__button-wrap {
  margin-top: 2.625rem;
  text-align: center;
}

.knowledge-download__button {
  display: inline-block;
  background-color: #5c5c5c;
  color: white;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.75rem 3.6875rem;
}

.knowledge-side__category-title {
  background-color: #6e0054;
}

.knowledge-side__category-list,
.knowledge-side__tag-list {
  margin-top: 0.9375rem;
}

.knowledge-side__recommendーarticles {
  margin-top: 2.25rem;
}

.knowledge-side__recommend-bg.sub-side__articles-bg {
  display: none;
}

.layout-knowledge-detail {
  margin-top: 1.375rem;
}

.layout-knowledge-contents {
  margin-top: 1.875rem;
}

.layout-changeByEdit {
  margin-top: 2.5rem;
}

.seminer__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.seminer__sub-section-title.sub-section-title__en {
  max-width: 9.625rem;
  width: 100%;
}

.seminer__menu-content {
  display: none;
}

.seminer__menu-list ul {
  margin-top: 1.25rem;
  display: flex;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
}

.seminer__menu-item {
  padding: 0.3125rem 1.8125rem;
  border-right: 1px solid #1c1c1c;
}

.seminer__menu-list > ul > li:nth-child(1) {
  padding-left: 1.25rem;
}

.seminer__menu-list > ul > li:nth-child(5) {
  border-right: none;
  padding-right: 1.25rem;
}

.seminer__tag-list ul {
  background: #f0f0f0;
  padding: 1.4375rem 4.375rem 1.5rem 2.8125rem;
  margin-top: 1.125rem;
  display: flex;
  gap: 1.1875rem;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
}

.seminer-selectBox__content.sub-selectBox__content {
  margin-top: 1.3125rem;
  display: flex;
}

.seminerーarchive__article-content.seminer-card__article-content {
  row-gap: 3.0625rem;
}

.seminer-card__article-content {
  margin-top: 1.5625rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2.1875rem;
}

.seminer-card__img {
  max-width: 39.625rem;
  width: 100%;
  padding-inline: 1.0625rem;
  margin-inline: auto;
}

.seminer-card__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 300/165;
}

.seminer-card__body {
  margin-top: 0.3125rem;
  max-width: 37.5rem;
  width: 100%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.seminer-card__title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  color: #383838;
}

.seminer-card__article-tag-wrap {
  display: flex;
  gap: 1.25rem;
}

.seminer-card__article-tag {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
}

.seminer-card__img {
  overflow: hidden;
}

.seminer-card__img img {
  transition: transform 0.5s ease;
}

.seminer-card__link:hover .seminer-card__img img {
  transform: scale(1.1);
}

.seminer-card__link:hover .seminer__button__arrow {
  transform: translateX(8px);
}

.layout-seminer {
  margin-top: 1.9375rem;
}

.seminer-selectBox-content.sub-selectBox__content {
  width: 100%;
  margin-top: 3.1875rem;
  display: flex;
  justify-content: center;
}

.seminer-select {
  position: relative;
  height: 25px;
  cursor: pointer;
}

.seminer-select1 {
  margin-right: 0.5625rem;
  max-width: 10.25rem;
  width: 100%;
  background-color: #d0b627;
  color: white;
  z-index: 10;
}

.seminer-select2 {
  max-width: 10.25rem;
  width: 100%;
  background-color: #F0F0F0;
  color: #404040;
  z-index: 10;
}

.seminer-select__expand {
  width: 0;
  height: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.seminer-select__expand::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  font-size: 10px;
  pointer-events: none;
  z-index: 2;
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
}

.seminer-select__expand:checked::after {
  transform: translate(-50%, -50%) rotate(180deg) scaleX(-1);
}

.seminer-select__expand1::after {
  color: white;
}

.seminer-select__expand2::after {
  color: #1C1C1C;
}

.seminer-select__expandLabel {
  display: block;
  width: 100%;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.seminer-select__close {
  display: none;
}

.seminer-select__closeLabel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}

.seminer-select__items {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 25px;
}

.seminer-select__input {
  display: none;
}

.seminer-select__label {
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
  display: block;
  height: 0;
  font-size: 9px;
  line-height: 25px;
  overflow: hidden;
  color: white;
  background-color: #d0b627;
  cursor: pointer;
  padding-left: 5px;
  position: relative;
}

.seminer-select__label2.seminer-select__label {
  color: #404040;
  background-color: #F0F0F0;
}

.seminer-select__label1.seminer-select__label.select__label-placeholder {
  color: white;
  height: 25px;
  left: 0px;
}

.seminer-select__label2.seminer-select__label.select__label-placeholder {
  color: #404040;
  background-color: #F0F0F0;
  height: 25px;
  left: 0px;
}

span.seminer-select__label.seminer-select__label-placeholder::before {
  display: none;
}

.seminer-select__label-placeholder {
  height: 25px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0px;
  background-color: transparent;
}

.seminer-select__expand:checked + .seminer-select__closeLabel {
  display: block;
}

.seminer-select__expand:checked + .seminer-select__closeLabel + .seminer-select__options .seminer-select__label {
  height: 25px;
}

.seminer-select__expand:checked + .seminer-select__closeLabel + .seminer-select__expandLabel {
  display: none;
}

.seminer-select__input:checked + .seminer-select__label {
  height: 25px;
  margin-top: -25px;
}

.seminer__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.seminer___menu-list ul {
  display: none;
}

.seminer__menu-item {
  border-right: 1px solid #1c1c1c;
  padding-right: 1.875rem;
}

.seminer___tag-list ul {
  display: none;
}

.seminer__article {
  margin-top: 3.1875rem;
  max-width: 44.375rem;
  width: 100%;
}

.seminer__title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #383838;
}

.seminer__content {
  margin-top: 0.4375rem;
  display: flex;
  padding-bottom: 0.875rem;
  border-bottom: 1px solid #707070;
}

.seminer__img {
  max-width: 7.25rem;
  width: 100%;
  margin-right: 0.4375rem;
}

.seminer__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 116/161;
}

.seminer__author-img {
  max-width: 5rem;
  width: 100%;
  border-radius: 50%;
}

.seminer__author-img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
}

.seminer__author-text {
  margin-top: 0.4375rem;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  color: #383838;
}

.seminer__category-content {
  display: flex;
  justify-content: space-between;
}

.seminer__category-wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 16.875rem;
  width: 100%;
}

.seminer__category {
  display: inline;
  background: #5f9a4a;
  font-weight: 500;
  font-size: 10px;
  color: white;
  padding: 0 1rem;
  margin: 0.4375rem 0.4375rem 0 0;
  display: flex;
  align-items: center;
}

.seminer__button {
  display: flex;
  align-items: center;
  padding-right: 0.8125rem;
}

p.seminer__button-text.button__text {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 10px;
}

.seminer__button__arrow {
  width: 0.75rem;
  height: 0.75rem;
  position: relative;
  display: inline-block;
  color: #1C1C1C;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}

.seminer__button__arrow::before,
.seminer__button__arrow::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}

.seminer__button__arrow::before {
  left: 2px;
  width: 6px;
  height: 1px;
  background: #1C1C1C;
}

.seminer__button__arrow::after {
  left: 5px;
  width: 2px;
  height: 2px;
  border-top: 1px solid #1C1C1C;
  border-right: 1px solid #1C1C1C;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.seminer__img {
  overflow: hidden;
}

.seminer__img img {
  transition: transform 0.5s ease;
}

.seminer__link:hover .seminer__img img {
  transform: scale(1.1);
}

.seminer__link:hover .seminer__button__arrow {
  transform: translateX(8px);
}

.seminer-archive-seminer__section-title-contents.document-section-title__contents {
  align-items: end;
}

.layout-seminer-archive-seminer {
  margin-top: 3.125rem;
}

.layout-seminer-archive {
  margin-top: 3.1875rem;
  margin-bottom: 5rem;
}

.layout-seminer-popular-articles {
  margin-top: 3.1875rem;
}

.seminer-detail__section-title {
  margin-top: 1.9375rem;
}

.seminer-detail__sub-section-title.detail-section-title__en {
  max-width: 6.0625rem;
  width: 100%;
}

.seminer-detail__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.seminer-detail__menu-content {
  display: none;
}

.seminer-detail__content {
  display: flex;
  flex-direction: column;
}

.seminer-detail__head {
  margin-top: 1.625rem;
  display: flex;
  flex-direction: column;
}

.seminer-detail__title {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.3181818182;
  color: #d0b627;
  border-left: 10px solid #d0b627;
  padding-left: 0.3125rem;
}

.seminer-detail__tag-wrap {
  margin-top: 0.625rem;
  display: flex;
}

.seminer-detail__tag {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
}

.seminer-detail__date-wrap {
  margin-top: 0.625rem;
  display: flex;
  flex-wrap: wrap;
}

.seminer-detail__date {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5;
}

.seminer-detail__date:nth-child(1) {
  padding-right: 0.9375rem;
  border-right: 1px solid #1C1C1C;
}

.seminer-detail__date:nth-child(2) {
  padding-left: 0.9375rem;
}

.seminer-detail__flex {
  display: flex;
  flex-direction: column-reverse;
}

.seminer-detail__sns-wrap {
  margin-top: 0.625rem;
}

.seminer-detail__sns-wrap2.sub-sns__wrap2 {
  margin-top: 0.3125rem;
}

.seminer-detail__img {
  margin-top: 1.3125rem;
  width: 100%;
  margin-inline: auto;
}

.seminer-detail__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 335/176;
}

.seminer-detail__text-wrap {
  margin-top: 1.875rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2;
  order: 5;
}

.seminer-detail__text + .seminer-detail__text {
  margin-top: 1.875rem;
}

.seminer-detail__changeByEdit__bg.changeByEdit__bg {
  background-color: rgba(208, 182, 39, 0.1);
}

.seminer-detail-contents {
  margin-top: 1.875rem;
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.seminer-detail-contents h3 {
  margin-bottom: 1.9375rem;
  background-image: url("../images/seminer-detail/seminer-detail-title-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5;
  color: white;
  padding: 0 0.25rem;
}

.seminer-detail-contents p.seminer-detail-content__text-l {
  margin-bottom: 1.875rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25;
  color: #383838;
}

.seminer-detail-contents p.seminer-detail-content__text-s {
  margin-bottom: 1.875rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2;
  color: #383838;
}

.seminer-detail-contents .seminer-detail-content__img {
  margin-bottom: 3.125rem;
  max-width: 21.25rem;
  width: 100%;
  margin-inline: auto;
}

.seminer-detail-contents .seminer-detail-content__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 340/133;
}

.seminer-detail-content__button-wrap1 {
  margin-top: 2.625rem;
  text-align: center;
  margin-bottom: 3.125rem;
}

.seminer-detail-content__button-wrap2 {
  margin-top: 2.625rem;
  text-align: center;
  margin-bottom: 3.125rem;
}

.seminer-detail-content__button {
  display: inline-block;
  background-color: #d0b627;
  color: white;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.75rem 3.6875rem;
}

.seminer-contents__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.seminer-contents__bg {
  background-color: #f0f0f0;
  border-left: 9px solid #d0b627;
  padding: 1.6875rem 0.5rem 2.5rem 0.625rem;
  margin-bottom: 3.125rem;
}

.seminer-contents__title-content {
  display: flex;
  align-items: center;
  gap: 0.4375rem;
}

.seminer-contents__title--en {
  max-width: 9.875rem;
  width: 100%;
}

.seminer-contents__title--ja {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1;
}

.seminer-contents__list-title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.125;
}

.seminer-contents__list-title:nth-child(3) {
  margin-top: 1.5625rem;
}

.seminer-contents__list {
  margin-top: 0.9375rem;
}

.seminer-contents__list-item {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2;
  position: relative;
  padding-left: 1.25rem;
}

.seminer-contents__list-item::before {
  position: absolute;
  content: "";
  top: 0.4375rem;
  left: 0;
  width: 0.6875rem;
  height: 0.6875rem;
  border-radius: 50px;
  background: #d0b627;
  flex-shrink: 0;
}

.seminer-contents__list-sub-title {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2;
  position: relative;
  padding-left: 1.625rem;
}

.seminer-contents__list-sub-title::before {
  position: absolute;
  content: "";
  top: 0.5rem;
  left: 0.6875rem;
  width: 0.6875rem;
  height: 0.6875rem;
  background-image: url("../images/common/seminer-contents-icon.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.seminer-download {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.seminer-download__content {
  background: #eaeaea;
  padding: 1.875rem 0.8125rem 1.875rem;
}

.seminer-download__title {
  font-size: 1.3125rem;
  line-height: 1.380952381;
  max-width: 19.3125rem;
  width: 100%;
  margin-inline: auto;
  text-align: center;
}

.seminer-download__title > p:nth-child(1) {
  font-weight: bold;
  background-color: #272727;
  color: white;
  padding: 0.375rem 0;
}

.seminer-download__title > p:nth-child(2) {
  font-weight: 400;
  background-color: #eaeaea;
  color: #000000;
  border: 1px solid #707070;
  padding: 0.375rem 0;
}

.seminer-download__text {
  margin-top: 1.4375rem;
  text-align: center;
}

.seminer-download__text p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1;
}

.seminer-download__text p:nth-child(2) {
  margin-top: 1.25rem;
}

.seminer-download__img {
  margin-top: 2.5rem;
  max-width: 38.625rem;
  width: 100%;
  margin-inline: auto;
}

.seminer-download__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 309/162;
}

.seminer-download__button-wrap {
  margin-top: 2.625rem;
  text-align: center;
}

.seminer-download__button {
  display: inline-block;
  background-color: #5c5c5c;
  color: white;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.75rem 3.6875rem;
}

.seminer-detail-selectBox__content.sub-selectBox__content {
  margin-top: 3.1875rem;
}

.seminer-side__category-title {
  background-color: #d0b627;
}

.seminer-side__category-list,
.seminer-side__tag-list {
  margin-top: 0.9375rem;
}

.seminer-side__tag {
  margin-top: 2.5rem;
}

.seminer-side__recommendーarticles {
  margin-top: 2.25rem;
}

.seminer-side__recommend-bg.sub-side__articles-bg {
  display: none;
}

.seminer-document-form {
  max-width: 100%;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.seminer-document-form__title {
  border: 1px solid #0177b4;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  text-align: center;
  color: #007ccf;
  padding: 0.25rem 0;
}

.seminer-document-form__inner {
  max-width: 41.875rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 0.625rem;
}

.seminer-document-form-wrap {
  margin-top: 3.3125rem;
}

.seminer-document-form__content {
  width: 100%;
}

.seminer-document-form__label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
}

.seminer-document-form__label-name {
  margin-right: 0.375rem;
}

form > div:nth-child(7) > label > span.seminer-document-form__required {
  margin-right: 0;
}

.seminer-document-form__required {
  width: 1.9375rem;
  height: 0.875rem;
  font-weight: 400;
  font-size: 10px;
  color: white;
  background-color: #760303;
  margin-right: 0.625rem;
  padding: 0 0.3125rem;
}

.seminer-document-form__input {
  border: none;
  background-color: #f7f7f7;
  height: 2.375rem;
  width: 100%;
  max-width: 45.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #868686;
  padding: 0.5rem 0.5rem;
  margin: 0.25rem 0 0.625rem 0;
}

.form-control {
  border: none;
  background-color: #f7f7f7;
  height: 2.375rem;
  width: 100%;
  padding: 0.5rem 0.5rem;
  margin: 0.25rem 0 0.625rem 0;
}

.seminer-document-form__text {
  font-weight: 400;
  font-size: 11px;
}

.seminer-document-form__text--right {
  font-weight: 400;
  font-size: 11px;
  margin-left: 0;
}

.seminer-document-form__select {
  border: none;
  background-color: #f7f7f7;
  height: 2.375rem;
  width: 100%;
  max-width: 26.6875rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #868686;
  padding: 0.5rem 0.5rem;
  margin: 0.25rem 0 0.625rem 0;
}

.seminer-document-form__select-content.seminer-document-form__select-content--middle {
  max-width: 33.6875rem;
}

.seminer-document-form__select-content {
  position: relative;
  max-width: 26.6875rem;
  width: 100%;
}

.seminer-document-form__select.seminer-document-form__select--middle {
  max-width: 33.6875rem;
}

.seminer-document-form__select {
  -webkit-appearance: none;
  appearance: none;
  /* デフォルトの矢印を非表示 */
}

.seminer-document-form__select::-ms-expand {
  display: none;
  /* デフォルトの矢印を非表示(IE用) */
}

/* セレクトボックスの矢印デザイン変更 */

.seminer-document-form__select-content::before {
  background: black;
  height: calc(tan(60deg) * 14px / 2);
  width: 13px;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  content: "";
  position: absolute;
  right: 12px;
  top: 18px;
}

.seminer-document-form__optional {
  width: 1.9375rem;
  height: 0.875rem;
  font-weight: 400;
  font-size: 10px;
  color: white;
  background-color: #4a81ff;
  margin-right: 0.625rem;
  padding: 0 0.3125rem;
}

.seminer-document-form__textarea {
  border: none;
  background-color: #f7f7f7;
  height: 10.0625rem;
  width: 100%;
  max-width: 45.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #868686;
  padding: 0.5rem 0.5rem;
  margin: 0.25rem 0 0.625rem 0;
}

.seminer-document-form__privacy {
  margin-top: 1.25rem;
}

.seminer-document-form__privacy-text,
.seminer-document-form__acceptance {
  font-weight: 400;
  font-size: 14px;
}

.seminer-document-form__privacy-text > a {
  text-decoration: underline;
  color: #2680eb;
}

.seminer-document-form__privacy input[type=checkbox] + span {
  margin-top: 1.875rem;
  display: inline-block;
  position: relative;
}

.contact__form-privacy input[type=checkbox]:checked + span::before {
  background: #f7f7f7;
}

.contact__form-privacy input[type=checkbox] + span::before {
  background: #f7f7f7;
  border: 1px solid #707070;
  content: "";
  display: block;
  width: 0.9375rem;
  height: 0.9375rem;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.seminer-document-form__button-wrap {
  margin-top: 1.75rem;
  width: 100%;
  border-radius: 10px;
  background: #2680eb;
  text-align: center;
}

button.seminer-document-form__button {
  padding: 0.625rem 0;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5555555556;
  color: white;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.seminer-detail-profile__content {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  background-color: #f0f0f0;
  padding: 0;
}

.seminer-detail-profile__flex {
  display: flex;
}

.seminer-detail-profile__img {
  max-width: 21rem;
  width: 100%;
  margin-inline: auto;
}

.seminer-detail-profile__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 168/286;
}

.seminer-detail-profile__title-content {
  background-color: #f0f0f0;
  padding: 0 0 0 0.5625rem;
  max-width: 100%;
  width: 100%;
}

.seminer-detail-profile__title-wrap {
  margin-top: 4.125rem;
}

.seminer-detail-profile__title--en {
  max-width: 6rem;
  width: 100%;
}

.seminer-detail-profile__title--ja {
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1.5833333333;
}

.seminer-detail-profile__title-name1 {
  margin-top: 2.3125rem;
  font-weight: bold;
  font-size: 1.0625rem;
  line-height: 1.3529411765;
}

.seminer-detail-profile__title-name2 {
  margin-top: 2rem;
  font-weight: bold;
  font-size: 0.9375rem;
  line-height: 1.5333333333;
}

.seminer-detail-profile__text-body {
  padding: 1.3125rem 0.75rem 1.4375rem;
}

.seminer-detail-profile__text {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2;
}

.layout-seminer-detail {
  margin-top: 1.375rem;
}

.layout-seminer-contents {
  margin-top: 1.875rem;
}

.layout-changeByEdit {
  margin-top: 2.5rem;
}

._layout-seminer-document-form {
  margin-bottom: 1.875rem;
}

.beliveInEdithing__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.beliveInEdithing__sub-section-title.sub-section-title__en {
  max-width: 20.375rem;
  width: 100%;
}

.beliveInEdithing__menu-content {
  display: none;
}

.beliveInEdithing__menu-list ul {
  margin-top: 1.25rem;
  display: flex;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  justify-content: center;
}

.beliveInEdithing__menu-item {
  padding: 0.3125rem 5.625rem;
  border-right: 1px solid #1c1c1c;
}

.beliveInEdithing__menu-list > ul > li:nth-child(1) {
  border-left: 1px solid #1c1c1c;
}

.beliveInEdithing__tag-list ul {
  background: #f0f0f0;
  padding: 1.4375rem 4.375rem 1.5rem 2.8125rem;
  margin-top: 1.125rem;
  display: flex;
  gap: 1.1875rem;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
}

.beliveInEdithing-selectBox__content.sub-selectBox__content {
  margin-top: 1.3125rem;
  display: flex;
}

.beliveInEdithingーarchive__article-content.beliveInEdithing-card__article-content {
  row-gap: 3.0625rem;
}

.beliveInEdithing-card__article-content {
  margin-top: 1.5625rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2.1875rem;
}

.beliveInEdithing-card__img {
  max-width: 39.625rem;
  width: 100%;
  padding-inline: 1.0625rem;
  margin-inline: auto;
}

.beliveInEdithing-card__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 300/165;
}

.beliveInEdithing-card__body {
  margin-top: 0.3125rem;
  max-width: 37.5rem;
  width: 100%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.beliveInEdithing-card__title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  color: #383838;
}

.beliveInEdithing-card__article-tag-wrap {
  display: flex;
  gap: 1.25rem;
}

.beliveInEdithing-card__article-tag {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
}

.beliveInEdithing-card__img {
  overflow: hidden;
}

.beliveInEdithing-card__img img {
  transition: transform 0.5s ease;
}

.beliveInEdithing-card__link:hover .beliveInEdithing-card__img img {
  transform: scale(1.1);
}

.beliveInEdithing-card__link:hover .beliveInEdithing__button__arrow {
  transform: translateX(8px);
}

.layout-beliveInEdithing {
  margin-top: 1.9375rem;
}

.beliveInEdithing-selectBox-content.sub-selectBox__content {
  width: 100%;
  margin-top: 3.1875rem;
  display: flex;
  justify-content: center;
}

.beliveInEdithing-select {
  position: relative;
  height: 25px;
  cursor: pointer;
}

.beliveInEdithing-select1 {
  margin-right: 0.5625rem;
  max-width: 10.25rem;
  width: 100%;
  background-color: #E60815;
  color: white;
  z-index: 10;
}

.beliveInEdithing-select2 {
  max-width: 10.25rem;
  width: 100%;
  background-color: #F0F0F0;
  color: #404040;
  z-index: 10;
}

.beliveInEdithing-select__expand {
  width: 0;
  height: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.beliveInEdithing-select__expand::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  font-size: 10px;
  pointer-events: none;
  z-index: 2;
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
}

.beliveInEdithing-select__expand:checked::after {
  transform: translate(-50%, -50%) rotate(180deg) scaleX(-1);
}

.beliveInEdithing-select__expand1::after {
  color: white;
}

.beliveInEdithing-select__expand2::after {
  color: #1C1C1C;
}

.beliveInEdithing-select__expandLabel {
  display: block;
  width: 100%;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.beliveInEdithing-select__close {
  display: none;
}

.beliveInEdithing-select__closeLabel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
}

.beliveInEdithing-select__items {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 25px;
}

.beliveInEdithing-select__input {
  display: none;
}

.beliveInEdithing-select__label {
  transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
  display: block;
  height: 0;
  font-size: 9px;
  line-height: 25px;
  overflow: hidden;
  color: white;
  background-color: #E60815;
  cursor: pointer;
  padding-left: 5px;
  position: relative;
}

.beliveInEdithing-select__label2.beliveInEdithing-select__label {
  color: #404040;
  background-color: #F0F0F0;
}

.beliveInEdithing-select__label1.beliveInEdithing-select__label.select__label-placeholder {
  color: white;
  height: 25px;
  left: 0px;
}

.beliveInEdithing-select__label2.beliveInEdithing-select__label.select__label-placeholder {
  color: #404040;
  background-color: #F0F0F0;
  height: 25px;
  left: 0px;
}

span.beliveInEdithing-select__label.beliveInEdithing-select__label-placeholder::before {
  display: none;
}

.beliveInEdithing-select__label-placeholder {
  height: 25px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0px;
  background-color: transparent;
}

.beliveInEdithing-select__expand:checked + .beliveInEdithing-select__closeLabel {
  display: block;
}

.beliveInEdithing-select__expand:checked + .beliveInEdithing-select__closeLabel + .beliveInEdithing-select__options .beliveInEdithing-select__label {
  height: 25px;
}

.beliveInEdithing-select__expand:checked + .beliveInEdithing-select__closeLabel + .beliveInEdithing-select__expandLabel {
  display: none;
}

.beliveInEdithing-select__input:checked + .beliveInEdithing-select__label {
  height: 25px;
  margin-top: -25px;
}

.beliveInEdithing__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.beliveInEdithing___menu-list ul {
  display: none;
}

.beliveInEdithing__menu-item {
  border-right: 1px solid #1c1c1c;
}

.beliveInEdithing___tag-list ul {
  display: none;
}

.beliveInEdithing__article {
  margin-top: 3.1875rem;
  max-width: 44.375rem;
  width: 100%;
}

.beliveInEdithing__title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #383838;
}

.beliveInEdithing__content {
  margin-top: 0.4375rem;
  display: flex;
  padding-bottom: 0.875rem;
  border-bottom: 1px solid #707070;
}

.beliveInEdithing__img {
  max-width: 7.25rem;
  width: 100%;
  margin-right: 0.4375rem;
}

.beliveInEdithing__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 116/161;
}

.beliveInEdithing__author-img {
  max-width: 5rem;
  width: 100%;
  border-radius: 50%;
}

.beliveInEdithing__author-img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
}

.beliveInEdithing__author-text {
  margin-top: 0.4375rem;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5714285714;
  color: #383838;
}

.beliveInEdithing__category-content {
  display: flex;
  justify-content: space-between;
}

.beliveInEdithing__category-wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 16.875rem;
  width: 100%;
}

.beliveInEdithing__category {
  display: inline;
  background: #5f9a4a;
  font-weight: 500;
  font-size: 10px;
  color: white;
  padding: 0 1rem;
  margin: 0.4375rem 0.4375rem 0 0;
  display: flex;
  align-items: center;
}

.beliveInEdithing__button {
  display: flex;
  align-items: center;
  padding-right: 0.8125rem;
}

p.beliveInEdithing__button-text.button__text {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 10px;
}

.beliveInEdithing__button__arrow {
  width: 0.75rem;
  height: 0.75rem;
  position: relative;
  display: inline-block;
  color: #1C1C1C;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}

.beliveInEdithing__button__arrow::before,
.beliveInEdithing__button__arrow::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
  vertical-align: middle;
}

.beliveInEdithing__button__arrow::before {
  left: 2px;
  width: 6px;
  height: 1px;
  background: #1C1C1C;
}

.beliveInEdithing__button__arrow::after {
  left: 5px;
  width: 2px;
  height: 2px;
  border-top: 1px solid #1C1C1C;
  border-right: 1px solid #1C1C1C;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.beliveInEdithing__img {
  overflow: hidden;
}

.beliveInEdithing__img img {
  transition: transform 0.5s ease;
}

.beliveInEdithing__link:hover .beliveInEdithing__img img {
  transform: scale(1.1);
}

.beliveInEdithing__link:hover .beliveInEdithing__button__arrow {
  transform: translateX(8px);
}

.layout-beliveInEdithing-archive-case {
  margin-top: 3.125rem;
}

.layout-beliveInEdithing-recommendation {
  margin-top: 3.1875rem;
}

.layout-beliveInEdithing-archive-howTo {
  margin-top: 3.1875rem;
  margin-bottom: 5rem;
}

.beliveInEdithing-detail__section-title {
  margin-top: 1.9375rem;
}

.beliveInEdithing-detail-selectBox__content.sub-selectBox__content {
  margin-top: 3.1875rem;
}

.beliveInEdithing-detail__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.beliveInEdithing-detail__menu-content {
  display: none;
}

.beliveInEdithing-detail__content {
  display: flex;
  flex-direction: column;
}

.beliveInEdithing-detail__head {
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
}

.beliveInEdithing-detail__title {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.3181818182;
  color: #FF0926;
  border-left: 10px solid #FF0926;
  padding-left: 0.3125rem;
}

.beliveInEdithing-detail__tag-wrap {
  margin-top: 0.625rem;
  display: flex;
}

.beliveInEdithing-detail__tag {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
}

.beliveInEdithing-detail__date-wrap {
  margin-top: 0.1875rem;
  display: flex;
  flex-wrap: wrap;
}

.beliveInEdithing-detail__date {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5;
}

.beliveInEdithing-detail__flex {
  display: flex;
  flex-direction: column-reverse;
}

.beliveInEdithing-detail__sns-wrap {
  margin-top: 0.625rem;
}

.beliveInEdithing-detail__img {
  margin-top: 1.3125rem;
  width: 100%;
  margin-inline: auto;
}

.beliveInEdithing-detail__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 880/462;
}

.beliveInEdithing-detail__text-wrap {
  margin-top: 1.875rem;
  font-size: 0.875rem;
  line-height: 2;
}

.beliveInEdithing-detail__introduction {
  margin-top: 3.125rem;
  margin-bottom: 4.375rem;
}

.beliveInEdithing-detail__introduction-wrap {
  background: #f0f0f0;
  border-left: 9px solid #FF0926;
  padding: 1.25rem 0 2.5rem;
}

.beliveInEdithing-detail__introduction-wrap + .beliveInEdithing-detail__introduction-wrap {
  margin-top: 3.25rem;
}

.beliveInEdithing-detail__introduction-content {
  display: flex;
  flex-direction: column;
  gap: 3.125rem;
  padding: 0 1.3125rem 0 1.6875rem;
}

.beliveInEdithing-detail__introduction-img {
  max-width: 15.125rem;
  width: 100%;
  margin-inline: auto;
}

.beliveInEdithing-detail__introduction-img img {
  width: 100%;
  aspect-ratio: 242/216;
  object-fit: cover;
}

.beliveInEdithing-detail__introduction-text {
  font-size: 0.875rem;
  line-height: 2;
}

.beliveInEdithing-contents__inner {
  max-width: 44.375rem;
  width: 100%;
  margin-inline: auto;
  padding-inline: 1.25rem;
}

.beliveInEdithing-detail-contents {
  margin-bottom: 3.125rem;
}

.beliveInEdithing-detail-contents h3 {
  margin-bottom: 1.9375rem;
  background-image: url("../images/beliveInEdithing-detail/beliveInEdithing-detail-title-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5;
  color: white;
  padding: 0 0.25rem;
}

.beliveInEdithing-detail-contents p.beliveInEdithing-detail-content__text-s {
  margin-bottom: 1.875rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2;
  color: #383838;
}

.beliveInEdithing-detail-contents .beliveInEdithing-detail-content__img {
  margin-bottom: 3.125rem;
  max-width: 21.25rem;
  width: 100%;
  margin-inline: auto;
}

.beliveInEdithing-detail-contents .beliveInEdithing-detail-content__img img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 340/220;
}

.beliveInEdithing-side__category-title {
  background-color: #FF0926;
}

.beliveInEdithing-side__tag.sub-side__tag {
  margin-top: 3.3125rem;
}

.beliveInEdithing-side__category-list,
.beliveInEdithing-side__tag-list {
  margin-top: 0.9375rem;
}

.beliveInEdithing-side__recommendーarticles {
  margin-top: 2.25rem;
}

.beliveInEdithing-side__recommend-bg.sub-side__articles-bg {
  display: none;
}

.layout-beliveInEdithing-detail {
  margin-top: 1.375rem;
}

.layout-beliveInEdithing-contents {
  margin-top: 1.875rem;
}

@media screen and (min-width: 370px) {
  .sixstrength__title--ja {
    font-size: 2rem;
  }

  .sixstrength__text {
    font-size: 1.0625rem;
  }
}

@media screen and (min-width: 375px) {
  a[href^="tel:"] {
    pointer-events: none;
  }

  .top-sixstrength__top-button__arrow {
    left: 3.75rem;
  }

  .top-publication__top-button__arrow {
    left: 3.75rem;
  }

  .top-powerOfEditing__top-button__arrow {
    left: 3.75rem;
  }

  .top-seminer__top-button__arrow {
    left: 3.75rem;
  }

  .select__label {
    font-size: 11px;
  }

  .document-form__inner {
    padding-inline: 1.8125rem;
  }

  .knowledge-select__label {
    font-size: 11px;
  }

  .seminer-select__label {
    font-size: 11px;
  }

  .beliveInEdithing-select__label {
    font-size: 11px;
  }
}

@media screen and (min-width: 400px) {
  .top-card1__text-content {
    max-width: 100%;
    padding-inline: 1.25rem;
  }
}

@media screen and (min-width: 500px) {
  .card-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 518px) {
  .document-form__text--right {
    margin-left: auto;
  }

  .seminer-document-form__text--right {
    margin-left: auto;
  }
}

@media screen and (min-width: 768px) {
  .sp-br {
    display: none;
  }

  .pc-br {
    display: block;
  }

  .icon__text {
    font-size: 16px;
    line-height: 2.5625;
  }

  .inner {
    max-width: 76.75rem;
    padding-inline: 1.5625rem;
  }

  .header__logo {
    flex-direction: row;
    align-items: center;
  }

  .header__logo img {
    max-width: 21.5rem;
    margin-right: 1.5625rem;
  }

  .drawer__inner {
    max-width: 69.1875rem;
    padding-inline: 1.5625rem;
  }

  .drawer__logo {
    max-width: 65.0625rem;
  }

  .drawer__content {
    margin-top: 75px;
  }

  .drawer__nav-title {
    margin-top: 1rem;
  }

  .nav-title__en {
    margin-right: 1.25rem;
  }

  .nav-title__en-about {
    width: 7.375rem;
  }

  .nav-title__ja {
    font-size: 0.8125rem;
    line-height: 1.5384615385;
  }

  .nav-title__en-publication {
    width: 14.5rem;
  }

  .nav-title__en-6strength {
    width: 13.5rem;
  }

  .drawer__menu-item {
    margin-top: 0.8125rem;
    font-size: 1rem;
    line-height: 1.1875;
  }

  .nav-title__en-powerOfEditiing {
    width: 20.75rem;
  }

  .nav-title__en-caseStudy {
    width: 13.375rem;
  }

  .nav-title__en-seminer {
    width: 10.125rem;
  }

  .nav-title__en-knowledge {
    width: 14.125rem;
  }

  .nav-title__en-document {
    width: 12.3125rem;
  }

  .drawer__menu-info__copyright {
    margin-top: 3.4375rem;
  }

  .menu-lists {
    padding: 8rem 0 3.4375rem;
  }

  .menu-list {
    flex-direction: row;
  }

  .menu-list__news {
    margin-top: 0;
  }

  .menu-list__news-lists {
    margin-top: 11px;
  }

  .menu-list__other {
    margin-top: 0;
    max-width: min(33.4375vw, 428px);
  }

  .menu-list__other-content {
    max-width: min(23.125vw, 314px);
  }

  .menu__info-wrap1 {
    gap: 1.3125rem;
  }

  .menu__info-wrap1 a {
    font-size: 0.8125rem;
    line-height: 1.5384615385;
  }

  .menu-info__quote {
    font-size: 0.8125rem;
    line-height: 0.65;
  }

  .menu-info__quote-icon {
    max-width: 0.875rem;
    margin-right: 0.3125rem;
  }

  .menu-info__contact {
    font-size: 0.8125rem;
    line-height: 0.65;
  }

  .menu-info__contact-icon {
    max-width: 1.3125rem;
    width: 100%;
    margin-right: 0.3125rem;
  }

  .button__arrow,
  .button__arrow--white {
    width: 23px;
    height: 23px;
  }

  .button__text--white {
    line-height: 2.5238095238;
    font-size: 1.3125rem;
  }

  .button__text {
    font-size: 1.5rem;
    font-size: 24px;
    line-height: 2.5;
  }

  .arrow--white::before {
    left: 4px;
    width: 12px;
  }

  .arrow--white::after {
    left: 8px;
    width: 8px;
    height: 8px;
  }

  .arrow--black::before {
    left: 4px;
    width: 12px;
  }

  .arrow--black::after {
    left: 8px;
    width: 8px;
    height: 8px;
  }

  .pc-footer__inner {
    display: block;
    max-width: 68.8125rem;
    width: 100%;
    margin-inline: auto;
    padding: 64px 1.5625rem 54px 1.5625rem;
  }

  .pc-footer__logo-wrap {
    display: flex;
    justify-content: space-between;
  }

  nav.pc-footer__menu-lists {
    height: 645px;
  }

  .pc-footer__nav {
    margin-top: 4.375rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .pc-footer__nav-items {
    display: flex;
    gap: 2%;
    max-width: 45.5rem;
    width: 100%;
  }

  .pc-footer__button-wrap {
    margin-top: 1.5625rem;
    display: flex;
    justify-content: flex-end;
  }

  .pc-footer__menu-info-wrap {
    margin-top: 7.9375rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .sp-footer__inner {
    display: none;
  }

  .sub-section-title__en {
    max-width: 27.875rem;
  }

  .sub-section-title__ja {
    margin-top: -0.9375rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .detail-section-title__contents {
    max-width: 70.5625rem;
    width: 100%;
    padding-inline: 1.5625rem;
  }

  .detail-section-title__en {
    max-width: 24.625rem;
  }

  .detail-section-title__ja {
    font-size: 1.4375rem;
    line-height: 1.5652173913;
  }

  .card-list {
    margin-top: 3.8125rem;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 4.8125rem;
    column-gap: 4.75rem;
  }

  .card-list__item {
    max-width: 13.8125rem;
  }

  .card-list__item.new-mark::after {
    font-size: 1.375rem;
    width: 3.5rem;
    height: 3.4375rem;
    top: 55%;
    left: -20px;
  }

  .card-list__img {
    max-width: 13.0625rem;
  }

  .card-list__img img {
    aspect-ratio: 209/292;
  }

  .card-list__body {
    padding-top: 1rem;
    min-height: 4rem;
  }

  .card-list__title {
    font-size: 1rem;
    line-height: 1.25;
  }

  .pagination__link {
    font-size: 16px;
    line-height: 1.25;
  }

  .pagination__item:nth-child(5),
  .pagination__item:nth-child(6) {
    display: block;
  }

  .card2__article-content {
    max-width: 73.5rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.3125rem;
  }

  .card2__img {
    max-width: 21.125rem;
    padding-inline: 0rem;
  }

  .card2__img img {
    aspect-ratio: 338/186;
  }

  .card2__body {
    margin-top: 0.5rem;
  }

  .card2__title {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .card2__download {
    margin-top: 0.5625rem;
    max-width: 21.125rem;
    gap: 0.375rem;
  }

  span.card2__download-icon img {
    width: 1.3125rem;
    height: 0.9375rem;
  }

  .card2__download img {
    width: 6.375rem;
  }

  .sub-sns__wrap {
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;
  }

  .beliveInEdithing-detail__sns-wrap1.sub-sns__wrap1 > a:nth-child(1) {
    display: block;
  }

  .beliveInEdithing-detail__sns-wrap1.sub-sns__wrap1 > a:nth-child(2) {
    display: none;
  }

  .sub-sns__item--good {
    height: 1.5rem;
    border-radius: 5px;
    background-color: #6080b8;
    color: white;
    font-weight: bold;
    font-size: 1.0625rem;
    line-height: 1.1764705882;
    display: flex;
    align-items: center;
    gap: 0.5625rem;
    padding: 0 0.875rem 0 0.75rem;
  }

  .good-icon img {
    width: 1.375rem;
    height: 1.375rem;
  }

  .sub-sns__item--shere {
    font-size: 0.75rem;
    line-height: 1.1666666667;
    height: 1.5rem;
    border-radius: 3px;
    font-weight: bold;
    padding: 0 0.6875rem;
  }

  .sub-sns__item--x {
    height: 1.5rem;
    border-radius: 3px;
    font-weight: bold;
    padding: 0 1.1875rem;
  }

  .x-icon img {
    width: 1rem;
    height: 1rem;
  }

  .sub-sns__item--hatena {
    height: 1.5rem;
    border-radius: 3px;
    font-weight: bold;
    font-size: 1.0625rem;
    line-height: 1.1764705882;
    padding: 0 0.6875rem;
  }

  .hatena-icon img {
    width: 1.4375rem;
    height: 1.25rem;
  }

  .knowledge-detail__sns-wrap2 {
    margin-top: 0;
  }

  .sub-sns__item--pocket {
    height: 1.5rem;
    border-radius: 3px;
    font-weight: bold;
    font-size: 1.0625rem;
    line-height: 1.1764705882;
    gap: 0.4375rem;
    padding: 0 0.75rem;
  }

  .pocket-icon img {
    width: 1.0625rem;
    height: 1.0625rem;
  }

  .sub-sns__item--line {
    height: 1.5rem;
    border-radius: 3px;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1.1666666667;
    gap: 0.1875rem;
    padding: 0 0.8125rem 0 0.5rem;
  }

  .line-icon img {
    width: 1.375rem;
    height: 1.25rem;
  }

  .sub-side {
    margin-top: 3.1875rem;
    max-width: 14.375rem;
  }

  .sub-side__category,
  .sub-side__tag {
    display: block;
  }

  .sub-side__articles-bg {
    padding-top: 0rem;
    padding-bottom: 0rem;
    background-color: transparent;
  }

  .sub-side__articles-inner {
    max-width: 100%;
    padding-right: 1.625rem;
    padding-left: 0;
  }

  .sub-side__articles-title {
    margin-top: 3.1875rem;
    font-size: 1.25rem;
    line-height: 1.8;
    color: #1C1C1C;
  }

  .sub-side__card-list-item {
    max-width: 12.75rem;
  }

  .sub-side__card-list-img {
    max-width: 13.0625rem;
    padding: 0;
  }

  .sub-side__card-list-img img {
    aspect-ratio: 204/112;
  }

  .sub-side__card-list-title {
    font-size: 0.8125rem;
    line-height: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }

  .sub-side__card-list-tag-wrap {
    display: none;
  }

  .sub-side__card-list-tag {
    display: none;
  }

  .changeByEdit__bg {
    padding: 2.25rem 0 3.8125rem;
  }

  .changeByEdit__inner {
    max-width: 70.5rem;
    padding-inline: 1.5625rem;
  }

  .changeByEdit__section-title {
    max-width: 16.1875rem;
  }

  .changeByEdit__items {
    margin-top: 1.375rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .changeByEdit__body {
    margin-top: 0.625rem;
    max-width: 21.125rem;
  }

  .changeByEdit__img {
    padding-inline: 0rem;
  }

  .changeByEdit__img img {
    aspect-ratio: 338/186;
  }

  .changeByEdit__title {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .is-sticky {
    position: -webkit-sticky;
    /* Safariのサポート */
    position: sticky;
    top: 0;
    /* 画面の上部に固定 */
    z-index: 10;
    /* 必要に応じて他の要素より前面に */
  }

  .top-card1__sticky-container {
    margin-top: 2.8125rem;
    flex-direction: row;
    gap: 2.625rem;
    min-height: 759px;
  }

  .top-card1__content--main {
    position: sticky;
    top: 149px;
    /* 画面の上端に固定 */
    height: 100vh;
    width: 44.3877%;
  }

  .top-card1__bg {
    max-width: 32.625rem;
    aspect-ratio: 522/570;
  }

  .top-card1__text-content {
    max-width: 18.75rem;
  }

  .top-card1__title--en {
    max-width: 23.625rem;
  }

  .top-card1__title--ja {
    font-size: 1.25rem;
  }

  .top-card1__sticky-scroll {
    width: 52.0208%;
  }

  .top-card1__article-content {
    margin-top: 0;
    max-width: 38.25rem;
    grid-template-columns: 1fr 1fr;
    gap: 2.75rem;
    row-gap: 1.3125rem;
  }

  .top-card1__content {
    flex-direction: column;
    align-items: baseline;
  }

  .top-card1__img {
    max-width: 17.8125rem;
    margin-right: 0;
  }

  .top-card1__img img {
    aspect-ratio: 285/400;
  }

  .top-card1__body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .top-card1__category {
    margin-top: 0.625rem;
    padding: 2px 10px;
  }

  .top-card1__category img {
    max-width: 3.8125rem;
  }

  .top-card1__title {
    font-size: 1.25rem;
  }

  .top-card2__article-content {
    max-width: 73.5rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.3125rem;
  }

  .top-card2__img {
    max-width: 23.625rem;
  }

  .top-card2__img img {
    aspect-ratio: 378/196;
  }

  .top-card2__category {
    padding: 0 10px;
    line-height: 0.9375rem;
  }

  .top-card2__category img {
    max-width: 4.5625rem;
  }

  .top-card2__title {
    font-size: 1.25rem;
  }

  .top-mv__bg {
    background-image: url("../images/top/top-mv-bg.webp");
  }

  .top-mv__inner {
    padding: 0;
    max-width: 73.375rem;
    padding-inline: 1.5625rem;
    padding-bottom: 0px;
  }

  .swiper {
    transform: translateY(30px);
  }

  .swiper-wrapper {
    max-width: 70.9375rem;
  }

  .swiper-slide {
    max-width: 21.625rem;
  }

  .swiper-slide img {
    aspect-ratio: 346/469;
  }

  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -115px;
  }

  .section-title__contents {
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    padding-bottom: 0.5rem;
    background-color: white;
  }

  .section-title__en {
    margin-bottom: -1.25rem;
  }

  .section-title__ja {
    font-size: 1.5rem;
  }

  .top-sixstrength__section-title {
    max-width: 33.4375rem;
  }

  .top-sixstrength__top-button__arrow {
    left: 5rem;
  }

  .top-sixstrength__button {
    margin-top: 1.75rem;
  }

  .layout-top-sixstrength {
    margin-top: 10.1875rem;
  }

  .top-publication__section-title {
    max-width: 35.8125rem;
  }

  .top-publication__article-content {
    flex-direction: row;
  }

  .top-publication__article {
    max-width: 17.8125rem;
  }

  .top-publication__article2,
  .top-publication__article4 {
    margin-bottom: 1.875rem;
  }

  .top-publication__article3 {
    margin-top: -4.625rem;
  }

  .top-publication__article5 {
    margin-top: -8.25rem;
  }

  .top-publication__article1,
  .top-publication__article3,
  .top-publication__article5 {
    margin-right: 2.625rem;
  }

  .top-publication__article {
    max-width: 17.8125rem;
  }

  figure.top-publication__article-img1.top-card1__img img {
    aspect-ratio: 285/321;
  }

  figure.top-publication__article-img2.top-card1__img img {
    aspect-ratio: 285/398;
  }

  figure.top-publication__article-img3.top-card1__img img {
    aspect-ratio: 285/246;
  }

  figure.top-publication__article-img4.top-card1__img img {
    aspect-ratio: 285/330;
  }

  figure.top-publication__article-img5.top-card1__img img {
    aspect-ratio: 285/358;
  }

  figure.top-publication__article-img6.top-card1__img img {
    aspect-ratio: 285/321;
  }

  .top-publication__article-content:nth-child(1) {
    margin-top: 0rem;
  }

  h3.top-publication__article-title.top-card1__title {
    font-size: 1.125rem;
  }

  .top-publication__category img {
    max-width: 4.6875rem;
  }

  .top-publication__top-button__arrow {
    left: 5rem;
  }

  .top-powerOfEditing__section-title {
    max-width: 38.1875rem;
  }

  .top-powerOfEditing__top-title--en.top-card1__title--en {
    max-width: 20rem;
  }

  .top-powerOfEditing__top-text.top-card1__text {
    margin-top: 3.6875rem;
  }

  .top-powerOfEditing__article-img1.top-card1__img img,
  .top-powerOfEditing__article-img2.top-card1__img img,
  .top-powerOfEditing__article-img3.top-card1__img img,
  .top-powerOfEditing__article-img4.top-card1__img img,
  .top-powerOfEditing__article-img5.top-card1__img img,
  .top-powerOfEditing__article-img6.top-card1__img img {
    aspect-ratio: 285/428;
  }

  .top-powerOfEditing__category.top-card1__category {
    margin-top: 0.8125rem;
  }

  .top-powerOfEditing__category.top-card1__category img {
    max-width: 6.75rem;
  }

  h3.top-powerOfEditing__article-title.top-card1__title {
    font-size: 1.125rem;
  }

  .top-powerOfEditing__article-text {
    display: none;
  }

  .top-powerOfEditing__topics {
    font-size: 0.8125rem;
    line-height: 1.6923076923;
  }

  .top-powerOfEditing__top-button__arrow {
    left: 5rem;
  }

  .top-seminer__section-title {
    max-width: 25.0625rem;
  }

  .top-seminer__top-title--en.top-card1__title--en {
    max-width: 17.6875rem;
  }

  .top-seminer__article-img1.top-card1__img img,
  .top-seminer__article-img2.top-card1__img img,
  .top-seminer__article-img3.top-card1__img img,
  .top-seminer__article-img4.top-card1__img img,
  .top-seminer__article-img5.top-card1__img img,
  .top-seminer__article-img6.top-card1__img img {
    aspect-ratio: 285/428;
  }

  .top-seminer__category.top-card1__category img {
    max-width: 3.3125rem;
  }

  h3.top-seminer__article-title.top-card1__title {
    font-size: 1.125rem;
  }

  .top-seminer__article-text {
    display: none;
  }

  .top-seminer__topics {
    font-size: 0.8125rem;
    line-height: 1.6923076923;
  }

  .top-seminer__top-button__arrow {
    left: 5rem;
  }

  .top-knowledge__section-title {
    max-width: 34.9375rem;
  }

  .top-document__section-title {
    max-width: 30.4375rem;
  }

  figure.top-document__article-img1.top-card2__img {
    max-width: 23.625rem;
  }

  figure.top-document__article-img1.top-card2__img img {
    aspect-ratio: 378/263;
  }

  .top-document__download {
    margin-top: 0.625rem;
  }

  .top-document__button {
    margin-top: 3.25rem;
  }

  .layout-top-mv {
    margin-top: 3.75rem;
  }

  .layout-top-publication {
    margin-top: 4.5rem;
  }

  .layout-top-powerOfEditing {
    margin-top: 4.5625rem;
  }

  .layout-top-seminer {
    margin-top: 1.5625rem;
  }

  .layout-top-knowledge {
    margin-top: 5.0625rem;
  }

  .layout-top-document {
    margin-top: 4.5rem;
    margin-bottom: 6.75rem;
  }

  .stripe {
    display: none;
  }

  .about__mv-title--en {
    max-width: 20.1875rem;
  }

  .about__mv-title--ja {
    font-size: 3.375rem;
    line-height: 1.537037037;
  }

  .about__mv-bg {
    margin-top: 1.9375rem;
    padding: 5.875rem 0 8.875rem;
  }

  .about__mv-text {
    max-width: 59rem;
    padding-inline: 1.5625rem;
    font-size: 1.375rem;
    line-height: 1.8181818182;
  }

  .about__mv-text2,
  .about__mv-text3 {
    margin-top: 2.5rem;
  }

  .about01__inner {
    max-width: 59.375rem;
    padding-inline: 1.5625rem;
  }

  .about-section__text {
    margin-top: 1.9375rem;
  }

  .about01__lists {
    margin-top: 2.375rem;
  }

  .about01__list {
    margin-top: 2.25rem;
  }

  .about01__title {
    font-size: 1.25rem;
    padding: 0.3125rem 0 0.4375rem;
  }

  .about01__text {
    font-size: 0.9375rem;
    line-height: 2;
  }

  .about02__inner {
    max-width: 63.625rem;
    padding-inline: 1.5625rem;
  }

  .about02__section-text.about-section__text {
    max-width: 56.25rem;
    width: 100%;
    margin-inline: auto;
    font-size: 0.9375rem;
    line-height: 2;
  }

  figure.about02__img1 img {
    margin-top: 1.9375rem;
    max-width: 60.5rem;
    padding-inline: 0;
  }

  figure.about02__img2 img {
    margin-top: 1.9375rem;
    max-width: 30.1875rem;
    padding-inline: 0;
  }

  .about02__figcaption {
    margin-top: 1.875rem;
    font-size: 0.9375rem;
    line-height: 2;
    max-width: 56.25rem;
    margin-inline: auto;
  }

  .about03__inner {
    max-width: 59.375rem;
    padding-inline: 1.5625rem;
  }

  .about03__section-text.about-section__text {
    margin-top: 1.9375rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .about03__img img {
    margin-top: 1.9375rem;
    max-width: 45.8125rem;
    padding-inline: 0;
  }

  .about03__text {
    margin-top: 1.875rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .about03__text2 {
    margin-top: 2.8125rem;
  }

  .about04__inner {
    max-width: 59.375rem;
    padding-inline: 1.5625rem;
  }

  .about04__section-text.about-section__text {
    margin-top: 1.9375rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .about04__text {
    margin-top: 1.875rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .about04__text2,
  .about04__text3 {
    margin-top: 2.9375rem;
  }

  .about04__img img {
    margin-top: 1.9375rem;
    max-width: 33.5625rem;
    padding-inline: 0;
  }

  .layout-about {
    margin-top: 4.375rem;
  }

  .layout-about01 {
    margin-top: 6.25rem;
  }

  .layout-about02 {
    margin-top: 6.3125rem;
  }

  .layout-about03 {
    margin-top: 6.25rem;
  }

  .layout-about04 {
    margin-top: 6.3125rem;
    margin-bottom: 6.25rem;
  }

  .about-section__title {
    font-size: 2.5rem;
    line-height: 1.5;
  }

  .about-section__text {
    font-size: 1.375rem;
  }

  .sixstrength__inner {
    max-width: 77.625rem;
    padding-inline: 1.5625rem;
  }

  .sixstrength__bg {
    background: url(../images/sixstrength/sixstrength-bg.webp) no-repeat center/cover;
    max-width: 77rem;
    aspect-ratio: 1192/620;
    padding-inline: 1.5625rem;
  }

  .sixstrength__text-content {
    max-width: 41.25rem;
  }

  .sixstrength__title--en {
    max-width: 31.25rem;
  }

  .sixstrength__title--ja {
    margin-top: 0;
    font-size: 3.375rem;
    line-height: 1.537037037;
  }

  .sixstrength__article-container {
    margin-top: 3.125rem;
    max-width: 77.125rem;
    padding: 0 1.5625rem;
  }

  .sixstrength__article-content {
    flex-direction: row;
    align-items: center;
    background-color: #f4f4f4;
    margin-top: 1.875rem;
    padding: 0;
  }

  .sixstrength__article:nth-child(2n) > .sixstrength__article-content {
    flex-direction: row-reverse;
  }

  .sixstrength__article-img {
    margin-top: 0;
    max-width: 22.5rem;
  }

  .sixstrength__article-img img {
    aspect-ratio: 360/506;
  }

  .sixstrength__article-body {
    display: flex;
    flex-direction: column;
    padding: 0 1.25rem;
  }

  .sixstrength__category {
    padding: 2px 10px;
  }

  .sixstrength__category img {
    width: 4.6875rem;
  }

  .layout-sixstrength {
    margin-top: 4.375rem;
    margin-bottom: 6.125rem;
  }

  .publication__inner {
    max-width: 68.5625rem;
  }

  .publication__pagination {
    margin-top: 4.5rem;
  }

  .layout-publication {
    margin-top: 1.625rem;
    margin-bottom: 7.1875rem;
  }

  .publication-detail__inner {
    max-width: 58.125rem;
    padding-inline: 1.5625rem;
  }

  .publication-detail__book-title {
    margin-top: 1.9375rem;
    font-size: 1.25rem;
    line-height: 1.1;
  }

  .publication-detail__container {
    margin-top: 1.8125rem;
  }

  .publication-detail__content-flex {
    flex-direction: row;
  }

  .publication-detail__img {
    max-width: 24.25rem;
    margin: 0 3.25rem 0 0;
  }

  .publication-detail__img {
    aspect-ratio: 387/556;
  }

  .publication-detail__body {
    margin-top: 0rem;
  }

  .publication-detail__title {
    font-size: 1.875rem;
    line-height: 1.3333333333;
  }

  .publication-detail__list {
    margin-top: 4.4375rem;
  }

  .publication-detail__item {
    font-size: 0.9375rem;
    line-height: 2;
  }

  .publication-detail__tag-wrap {
    margin-top: 2.6875rem;
    grid-row-gap: 0.875rem;
    grid-column-gap: 3rem;
  }

  .publication-detail__tag1,
  .publication-detail__tag3 {
    max-width: 12.3125rem;
  }

  .publication-detail__tag2,
  .publication-detail__tag4 {
    max-width: 12.1875rem;
  }

  .publication-detail__tag3,
  .publication-detail__tag4 {
    font-size: 1.25rem;
    line-height: 1.75;
  }

  .publication-detail__content-text {
    margin-top: 2.8125rem;
  }

  .publication-detail__subtitle {
    font-size: 1.625rem;
    line-height: 1.3461538462;
  }

  .publication-detail__subtext-content {
    margin-top: 1.9375rem;
  }

  .publication-detail__author-content {
    margin-top: 3.3125rem;
    padding: 2.3125rem 2.4375rem 3.375rem 3.25rem;
  }

  .publication-detail__author-title {
    font-size: 0.8125rem;
    line-height: 1.5384615385;
  }

  .publication-detail__author-name {
    margin-top: 0.6875rem;
    font-size: 1.25rem;
    line-height: 1;
  }

  .publication-detail__author-text {
    margin-top: 0.6875rem;
    font-size: 0.875rem;
    line-height: 1.7142857143;
  }

  .layout-publication-detail {
    margin-top: 1.5rem;
  }

  .layout-sub-contents {
    margin-top: 6.9375rem;
  }

  .layout-sub-recomend {
    margin-top: 6.25rem;
  }

  .accordion__button {
    padding-right: 60px;
  }

  .accordion__button:before {
    width: 39px;
  }

  .accordion__button:after {
    right: 20px;
    height: 39px;
  }

  .sub-contents__inner {
    max-width: 58.125rem;
    padding-inline: 1.5625rem;
  }

  .sub-contents__title--en {
    max-width: 21.25rem;
    margin-right: 3rem;
  }

  .sub-contents__title--ja {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }

  .accordion__title-wrap {
    margin-top: 1.5rem;
  }

  .accordion__button {
    font-size: 1.4375rem;
  }

  .accordion__title1 {
    font-size: 1.5625rem;
    line-height: 1.2;
  }

  .accordion__title {
    margin-top: 3.75rem;
    font-size: 1.5625rem;
    line-height: 1.2;
  }

  .accordion__text {
    margin-top: 1.875rem;
    text-indent: 2rem;
    font-size: 1.5625rem;
    line-height: 1.2;
  }

  .accordion__text-list {
    margin-top: 1.875rem;
  }

  .accordion__text-item {
    text-indent: 2rem;
    font-size: 1.5625rem;
    line-height: 1.2;
  }

  .sub-contents__stripe.sub-contents__stripe--top {
    margin-top: 5.5rem;
  }

  .sub-contents__news {
    margin: 1.875rem 0;
  }

  .sub-contents__news-text {
    line-height: 1.7142857143;
  }

  .sub-contents__pdf {
    margin-top: 2.125rem;
    line-height: 1.7142857143;
  }

  .sub-contents__infomation {
    margin: 1.9375rem 0 1.875rem;
  }

  .sub-recomend__bg {
    padding: 4.8125rem 0 7.875rem;
  }

  .sub-recomend__inner {
    max-width: 72rem;
    padding-inline: 1.5625rem;
  }

  .sub-recomend__title {
    max-width: 13.9375rem;
  }

  .sub-recomend__items {
    margin-top: 2.75rem;
  }

  .sub-recomend__img {
    max-width: 12.6875rem;
  }

  .sub-recomend__img img {
    aspect-ratio: 203/293;
  }

  .caseStudy__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .caseStudy__selectBox-content {
    margin-top: 2.5rem;
  }

  .caseStudy__container {
    margin-top: 3.75rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 68px;
  }

  .caseStudy__article {
    margin-top: 0rem;
    max-width: 32.8125rem;
  }

  .caseStudy__title {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-left: 10.3125rem;
  }

  .caseStudy__content {
    margin-top: 0rem;
    padding-bottom: 1.3125rem;
    margin-top: -1.875rem;
    align-items: center;
  }

  .caseStudy__img {
    max-width: 9.3125rem;
    margin-right: 1.25rem;
  }

  .caseStudy__img img {
    aspect-ratio: 149/211;
  }

  .caseStudy__author-wrap {
    display: flex;
    align-items: center;
    margin-top: 3.9375rem;
  }

  .caseStudy__author-img {
    margin-right: 1.1875rem;
    max-width: 6.9375rem;
  }

  .caseStudy__author-text {
    margin-top: 0rem;
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }

  .caseStudy__category-wrap {
    max-width: 21.125rem;
  }

  .caseStudy__category {
    font-size: 0.8125rem;
  }

  p.caseStudy__button-text.button__text {
    margin-top: 0rem;
    font-size: 0.875rem;
  }

  .caseStudy__button__arrow {
    width: 1rem;
    height: 1rem;
  }

  .caseStudy__button__arrow::before {
    left: 3px;
    width: 8px;
  }

  .caseStudy__button__arrow::after {
    left: 5px;
    width: 5px;
    height: 5px;
  }

  .layout-caseStudy {
    margin-top: 1.625rem;
    margin-bottom: 6.25rem;
  }

  .select {
    height: 29px;
  }

  .select1 {
    max-width: 126px;
    margin-right: 2.875rem;
  }

  .select2 {
    max-width: 173px;
  }

  .select__expand {
    height: 29px;
  }

  .select__expandLabel {
    height: 29px;
  }

  .select__items {
    padding-top: 29px;
  }

  .select__label {
    line-height: 29px;
    font-size: 0.8125rem;
    padding-left: 30px;
  }

  .select__label::before {
    left: 20px;
    font-size: 8px;
  }

  .select__label-placeholder {
    height: 29px;
  }

  .select__expand:checked + .select__closeLabel + .select__options .select__label {
    height: 29px;
  }

  .select__input:checked + .select__label {
    height: 29px;
    margin-top: -30px;
  }

  .caseStudy-detail__inner {
    max-width: 58.125rem;
    padding-inline: 1.5625rem;
  }

  .caseStudy-detail__container {
    margin-top: 1.875rem;
  }

  .caseStudy-detail__content-flex {
    flex-direction: row;
    max-width: 55rem;
    margin-bottom: 4.6875rem;
  }

  .caseStudy-detail__img {
    max-width: 24.125rem;
    margin: 0 3.125rem 0 0;
  }

  .caseStudy-detail__img img {
    aspect-ratio: 386/551;
  }

  .caseStudy-detail__body {
    margin-top: 0rem;
    max-width: 27.5rem;
  }

  .caseStudy-detail__title {
    font-size: 2.5rem;
    line-height: 1.25;
  }

  .caseStudy-detail__category-wrap {
    margin-top: 1.9375rem;
    max-width: 27.5rem;
  }

  .caseStudy-detail__auther-wrap {
    margin-top: 1.625rem;
  }

  .caseStudy-detail__auther-name {
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .caseStudy-detail__auther-text {
    margin-top: 0.875rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .layout-caseStudy-detail {
    margin-top: 1.5rem;
    margin-bottom: 3.6875rem;
  }

  .caseStudy-detail-content h3 {
    margin-bottom: 2.5rem;
    font-size: 1.625rem;
    line-height: 1.3461538462;
    padding: 0 1.25rem;
    background-image: url("../images/caseStudy-detail/sp-caseStudy-detail-title-bg.webp");
  }

  .caseStudy-detail-content p.caseStudy-detail-content__text-l {
    margin-bottom: 2.5rem;
    font-size: 1.25rem;
    line-height: 1;
  }

  .caseStudy-detail-content p.caseStudy-detail-content__text-s {
    margin-bottom: 2.5rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .caseStudy-detail-content .caseStudy-detail-content__img {
    margin-bottom: 2.5rem;
    max-width: 55rem;
  }

  .caseStudy-detail-content .caseStudy-detail-content__img img {
    aspect-ratio: 880/589;
  }

  .sub-caseStudy-detail__bg {
    padding: 1.875rem 0 5.3125rem;
  }

  .sub-caseStudy-detail__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .sub-caseStudy-detail__section-title {
    max-width: 15.4375rem;
  }

  .sub-caseStudy-detail__container {
    margin-top: 1.75rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 68px;
  }

  .sub-caseStudy-detail__article {
    margin-top: 0rem;
    max-width: 32.8125rem;
  }

  .sub-caseStudy-detail__title {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-left: 10.3125rem;
  }

  .sub-caseStudy-detail__content {
    margin-top: 0rem;
    padding-bottom: 1.3125rem;
    margin-top: -1.875rem;
    align-items: center;
  }

  .sub-caseStudy-detail__img {
    max-width: 9.3125rem;
    margin-right: 1.25rem;
  }

  .sub-caseStudy-detail__img img {
    aspect-ratio: 149/211;
  }

  .sub-caseStudy-detail__author-wrap {
    display: flex;
    align-items: center;
    margin-top: 3.9375rem;
  }

  .sub-caseStudy-detail__author-img {
    margin-right: 1.1875rem;
    max-width: 6.9375rem;
  }

  .sub-caseStudy-detail__author-text {
    margin-top: 0rem;
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }

  .sub-caseStudy-detail__category-wrap {
    max-width: 21.125rem;
  }

  .sub-caseStudy-detail__category {
    font-size: 0.8125rem;
  }

  p.sub-caseStudy-detail__button-text.button__text {
    margin-top: 0rem;
    font-size: 0.875rem;
  }

  .sub-caseStudy-detail__button__arrow {
    width: 1rem;
    height: 1rem;
  }

  .sub-caseStudy-detail__button__arrow::before {
    left: 3px;
    width: 8px;
  }

  .sub-caseStudy-detail__button__arrow::after {
    left: 5px;
    width: 5px;
    height: 5px;
  }

  .document {
    padding: 3.625rem 1.5625rem 2.5rem;
  }

  .document::after {
    top: 3.625rem;
  }

  .document__section-title-contents {
    margin-top: -2.25rem;
  }

  .document__sub-section-title.sub-section-title__en {
    max-width: 23.75rem;
  }

  .document__section-text.sub-section-title__ja {
    margin-top: -0.1875rem;
  }

  .document__inner {
    margin-top: 0.8125rem;
    max-width: 64.875rem;
    padding: 3.8125rem 0 3.8125rem;
  }

  .document__mv-img {
    max-width: 36.8125rem;
  }

  .document__mv-img img {
    aspect-ratio: 589/323;
  }

  .document__mv-title {
    margin-top: 1.8125rem;
    max-width: 100%;
    font-size: 1.75rem;
    line-height: 1.5;
  }

  .document__download {
    margin-top: -1.4375rem;
    gap: 0.375rem;
  }

  span.document__download-icon img {
    width: 1.3125rem;
    height: 0.9375rem;
  }

  .document__download img {
    width: 6.375rem;
  }

  .corporate-publishing__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .corporate-publishing__article-content {
    margin-top: 2.5rem;
  }

  .branding__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .branding__article-content {
    margin-top: 2.5rem;
  }

  .publication-marketing__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .publication-marketing__article-content {
    margin-top: 2.5rem;
  }

  .marketing__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .marketing__article-content {
    margin-top: 2.5rem;
  }

  .document-section-title__contents {
    max-width: 70rem;
    padding: 0 0 0.25rem 0.9375rem;
  }

  .document-section-title__en {
    font-size: 1.875rem;
    line-height: 1.0666666667;
  }

  .layout-corporate-publishing {
    margin-top: 4rem;
  }

  .layout-branding {
    margin-top: 4.5625rem;
  }

  .layout-publication-marketing {
    margin-top: 4.5625rem;
  }

  .layout-marketing {
    margin-top: 4.5625rem;
    margin-bottom: 9.375rem;
  }

  .document-detail__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .document-detail__container {
    margin-top: 1.8125rem;
    flex-direction: row;
  }

  .document-detail__img-content {
    max-width: 36.4375rem;
    padding: 5.5rem 2.875rem 5.6875rem 2.5rem;
  }

  .document-detail__title {
    font-size: 1.875rem;
    line-height: 1.5333333333;
    color: #1C1C1C;
  }

  .document-detail__img {
    max-width: 31.25rem;
    margin-top: 1.6875rem;
  }

  .document-detail__img img {
    aspect-ratio: 500/275;
  }

  .document-detail__text-content {
    max-width: 31.0625rem;
    padding: 10.25rem 2rem 8.1875rem 2.5625rem;
  }

  .document-detail__text-title {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }

  .document-detail__list {
    margin-top: 0.8125rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .layout-document-detail {
    margin-top: 1.5625rem;
    margin-bottom: 5.625rem;
  }

  .document-form__inner {
    max-width: 49rem;
    padding-inline: 1.5625rem;
  }

  .document-form__contents {
    display: flex;
    gap: 0.625rem;
  }

  .document-form__label {
    font-size: 14px;
  }

  .document-form__label-name {
    margin-right: 0.625rem;
  }

  form > div:nth-child(7) > label > span.document-form__required {
    margin-right: 0.625rem;
  }

  .document-form__input {
    padding: 0.5rem 1.25rem;
    margin: 0.25rem 0 1.875rem 0;
  }

  .form-control {
    max-width: 22.625rem;
    padding: 0.5rem 1.25rem;
    margin: 0.25rem 0 1.875rem 0;
  }

  .document-form__select {
    padding: 0.5rem 1.25rem;
    margin: 0.25rem 0 1.875rem 0;
  }

  .document-form__select-content::before {
    width: 16px;
    right: 28px;
    top: 16px;
  }

  .document-form__privacy {
    margin-top: 1.875rem;
  }

  .document-form__button-wrap {
    margin-top: 3.125rem;
    max-width: 45.875rem;
  }

  .layout-document-form {
    margin-top: 5.8125rem;
    margin-bottom: 9.375rem;
  }

  .knowledge__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .knowledge-card__article-content {
    max-width: 73.5rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.3125rem;
  }

  .knowledge-card__img {
    max-width: 21.125rem;
    padding-inline: 0rem;
  }

  .knowledge-card__img img {
    aspect-ratio: 338/186;
  }

  .knowledge-card__body {
    margin-top: 0.625rem;
    max-width: 100%;
  }

  .knowledge-card__title {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .layout-knowledge {
    margin-top: 1.625rem;
  }

  .knowledge__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .knowledge___menu-list ul {
    display: block;
    margin-top: 1.25rem;
    display: flex;
    gap: 1.875rem;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5;
  }

  .knowledge___tag-list ul {
    background: #f0f0f0;
    padding: 1.4375rem 4.375rem 1.5rem 2.8125rem;
    display: block;
    margin-top: 1.125rem;
    display: flex;
    gap: 1.25rem;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.5384615385;
  }

  .knowledge__article {
    margin-top: 0rem;
    max-width: 32.8125rem;
  }

  .knowledge__title {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-left: 10.3125rem;
  }

  .knowledge__content {
    margin-top: 0rem;
    padding-bottom: 1.3125rem;
    margin-top: -1.875rem;
    align-items: center;
  }

  .knowledge__img {
    max-width: 9.3125rem;
    margin-right: 1.25rem;
  }

  .knowledge__img img {
    aspect-ratio: 149/211;
  }

  .knowledge__author-wrap {
    display: flex;
    align-items: center;
    margin-top: 3.9375rem;
  }

  .knowledge__author-img {
    margin-right: 1.1875rem;
    max-width: 6.9375rem;
  }

  .knowledge__author-text {
    margin-top: 0rem;
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }

  .knowledge__category-wrap {
    max-width: 21.125rem;
  }

  .knowledge__category {
    font-size: 0.8125rem;
  }

  p.knowledge__button-text.button__text {
    margin-top: 0rem;
    font-size: 0.875rem;
  }

  .knowledge__button__arrow {
    width: 1rem;
    height: 1rem;
  }

  .knowledge__button__arrow::before {
    left: 3px;
    width: 8px;
  }

  .knowledge__button__arrow::after {
    left: 5px;
    width: 5px;
    height: 5px;
  }

  .layout-knowledge-new-articles {
    margin-top: 0.875rem;
  }

  .layout-knowledge-publication {
    margin-top: 0.875rem;
  }

  .layout-knowledge-marketing {
    margin-top: 0.875rem;
  }

  .layout-knowledge-branding {
    margin-top: 0.875rem;
  }

  .layout-knowledge-inner-branding {
    margin-top: 0.875rem;
  }

  .layout-knowledge-recruiting {
    margin-top: 0.875rem;
  }

  .layout-knowledge-purpose {
    margin-top: 0.875rem;
  }

  .layout-knowledge-others {
    margin-top: 0.875rem;
    margin-bottom: 6.25rem;
  }

  .layout-knowledge-popular-articles {
    margin-top: 2.75rem;
  }

  .knowledge-detail__section-title {
    margin-top: 1.5625rem;
  }

  .knowledge-detail__main-contents {
    display: flex;
    gap: 2.5rem;
    margin-inline: auto;
    max-width: 1150px;
    width: 100%;
  }

  .knowledge-detail__main {
    width: 76.5217%;
    margin-left: auto;
    padding-left: 1.5625rem;
  }

  .knowledge-detail__inner {
    max-width: 55rem;
    width: 100%;
    padding-inline: 0rem;
  }

  .knowledge-detail__menu-content {
    display: block;
    margin-top: 1.875rem;
  }

  .knowledge-detail__head {
    margin-top: 0rem;
  }

  .knowledge-detail__title {
    font-size: 2.5rem;
    line-height: 1.25;
    padding-left: 0.9375rem;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }

  .knowledge-detail__date-wrap {
    margin-top: 0.5625rem;
  }

  .knowledge-detail__date {
    font-size: 1.25rem;
    line-height: 1;
  }

  .knowledge-detail__date:nth-child(1) {
    padding-right: 0.5625rem;
  }

  .knowledge-detail__date:nth-child(2) {
    margin-top: 0.3125rem;
    padding-left: 0rem;
  }

  .knowledge-detail__flex {
    flex-direction: column;
  }

  .knowledge-detail__sns-wrap {
    margin-top: 1.5rem;
  }

  .knowledge-detail__img {
    margin-top: 3.8125rem;
    max-width: 55rem;
  }

  .knowledge-detail__text-wrap {
    margin-top: 2.5rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .knowledge-detail__text + .knowledge-detail__text {
    margin-top: 2.5rem;
  }

  .knowledge-detail__banner {
    margin-top: 2.5625rem;
    max-width: 55rem;
  }

  .knowledge-contents__inner {
    max-width: 55rem;
    padding-inline: 0rem;
  }

  .knowledge-contents__bg {
    border-left: 11px solid #6e0054;
    padding: 1.3125rem 3.1875rem 3.5rem 4.8125rem;
    margin-bottom: 5rem;
  }

  .knowledge-contents__title-content {
    gap: 1.3125rem;
  }

  .knowledge-contents__title--en {
    max-width: 16.0625rem;
  }

  .knowledge-contents__title--ja {
    font-size: 1.25rem;
  }

  .knowledge-contents__list {
    margin-top: 1.3125rem;
  }

  .knowledge-contents__list-title {
    font-size: 1.125rem;
    padding-left: 1.5rem;
  }

  .knowledge-contents__list-title::before {
    top: 0.625rem;
    width: 1.1875rem;
    height: 1.1875rem;
  }

  .knowledge-contents__list-sub-title {
    font-size: 1.125rem;
    padding-left: 1.25rem;
  }

  .knowledge-contents__list-sub-title::before {
    top: 0.6875rem;
    left: 0;
    width: 0.875rem;
    height: 0.875rem;
  }

  .knowledge-detail-contents h3 {
    margin-bottom: 2.5rem;
    font-size: 1.625rem;
    line-height: 1.3461538462;
    padding: 0 1.25rem;
  }

  .knowledge-detail-contents p.knowledge-detail-content__text-l {
    margin-bottom: 2.5rem;
    font-size: 1.25rem;
    line-height: 1;
  }

  .knowledge-detail-contents p.knowledge-detail-content__text-s {
    margin-bottom: 2.5rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .knowledge-detail-contents .knowledge-detail-content__img {
    margin-bottom: 5rem;
    max-width: 55rem;
  }

  .knowledge-detail-contents .knowledge-detail-content__img img {
    aspect-ratio: 880/345;
  }

  .knowledge-download__content {
    padding: 2.6875rem 2.8125rem 3rem;
  }

  .knowledge-download__title {
    font-size: 2.5625rem;
    line-height: 1.3414634146;
    max-width: 49.4375rem;
  }

  .knowledge-download__text {
    margin-top: 2.1875rem;
  }

  .knowledge-download__text p {
    font-size: 1.4375rem;
  }

  .knowledge-download__text p:nth-child(2) {
    margin-top: 1.5625rem;
  }

  .knowledge-download__img {
    margin-top: 2rem;
    max-width: 49.4375rem;
  }

  .knowledge-download__img img {
    aspect-ratio: 791/414;
  }

  .knowledge-download__button-wrap {
    margin-top: 2.8125rem;
  }

  .knowledge-download__button {
    font-size: 1.4375rem;
    line-height: 1.5217391304;
    padding: 0.875rem 5.3125rem;
  }

  .knowledge-detail-selectBox__content.sub-selectBox__content {
    display: none;
  }

  .knowledge-side__recommendーarticles {
    margin-top: 1rem;
  }

  .knowledge-side__recommend-bg.sub-side__articles-bg {
    display: block;
  }

  .sp-knowledge-side__recommend-bg.sub-side__articles-bg {
    display: none;
  }

  .layout-knowledge-detail {
    margin-top: 1.875rem;
  }

  .layout-knowledge-contents {
    margin-top: 5rem;
  }

  .layout-changeByEdit {
    margin-top: 5.1875rem;
  }

  .seminer__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .seminer__sub-section-title.sub-section-title__en {
    max-width: 19.5rem;
  }

  .seminer-card__article-content {
    max-width: 73.5rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.3125rem;
  }

  .seminer-card__img {
    max-width: 21.125rem;
    padding-inline: 0rem;
  }

  .seminer-card__img img {
    aspect-ratio: 338/186;
  }

  .seminer-card__body {
    margin-top: 0.625rem;
    max-width: 100%;
  }

  .seminer-card__title {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .layout-seminer {
    margin-top: 1.625rem;
  }

  .seminer__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .seminer___menu-list ul {
    display: block;
    margin-top: 1.25rem;
    display: flex;
    gap: 1.875rem;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5;
  }

  .seminer___tag-list ul {
    background: #f0f0f0;
    padding: 1.4375rem 4.375rem 1.5rem 2.8125rem;
    display: block;
    margin-top: 1.125rem;
    display: flex;
    gap: 1.25rem;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.5384615385;
  }

  .seminer__article {
    margin-top: 0rem;
    max-width: 32.8125rem;
  }

  .seminer__title {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-left: 10.3125rem;
  }

  .seminer__content {
    margin-top: 0rem;
    padding-bottom: 1.3125rem;
    margin-top: -1.875rem;
    align-items: center;
  }

  .seminer__img {
    max-width: 9.3125rem;
    margin-right: 1.25rem;
  }

  .seminer__img img {
    aspect-ratio: 149/211;
  }

  .seminer__author-wrap {
    display: flex;
    align-items: center;
    margin-top: 3.9375rem;
  }

  .seminer__author-img {
    margin-right: 1.1875rem;
    max-width: 6.9375rem;
  }

  .seminer__author-text {
    margin-top: 0rem;
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }

  .seminer__category-wrap {
    max-width: 21.125rem;
  }

  .seminer__category {
    font-size: 0.8125rem;
  }

  p.seminer__button-text.button__text {
    margin-top: 0rem;
    font-size: 0.875rem;
  }

  .seminer__button__arrow {
    width: 1rem;
    height: 1rem;
  }

  .seminer__button__arrow::before {
    left: 3px;
    width: 8px;
  }

  .seminer__button__arrow::after {
    left: 5px;
    width: 5px;
    height: 5px;
  }

  .layout-seminer-archive-seminer {
    margin-top: 1.1875rem;
  }

  .layout-seminer-archive {
    margin-top: 2.75rem;
    margin-bottom: 6.25rem;
  }

  .layout-seminer-popular-articles {
    margin-top: 2.75rem;
  }

  .seminer-detail__section-title {
    margin-top: 1.5625rem;
  }

  .seminer-detail__sub-section-title.detail-section-title__en {
    max-width: 11.625rem;
  }

  .seminer-detail__main-contents {
    display: flex;
    gap: 2.5rem;
    margin-left: auto;
    margin-right: 0;
    max-width: 1175px;
    width: 100%;
  }

  .seminer-detail__main {
    max-width: calc(100% - 14.375rem);
    width: 100%;
    margin-left: auto;
    padding-left: 1.5625rem;
  }

  .seminer-detail__inner {
    max-width: 55rem;
    width: 100%;
    padding-inline: 0rem;
  }

  .seminer-detail__menu-content {
    display: block;
    margin-top: 1.875rem;
  }

  .seminer-detail__head {
    margin-top: 0rem;
  }

  .seminer-detail__title {
    position: relative;
    font-size: 2.5rem;
    line-height: 1.25;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }

  .seminer-detail__title::before {
    position: absolute;
    content: "";
    background-image: url("../images/seminer-detail/seminer-detail__title-bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .seminer-detail__date-wrap {
    margin-top: 0.5625rem;
  }

  .seminer-detail__date {
    font-size: 1.25rem;
    line-height: 1;
  }

  .seminer-detail__date:nth-child(1) {
    padding-right: 0.5625rem;
  }

  .seminer-detail__date:nth-child(2) {
    margin-top: 0.3125rem;
    padding-left: 0rem;
  }

  .seminer-detail__flex {
    flex-direction: column;
  }

  .seminer-detail__sns-wrap {
    margin-top: 1.5rem;
  }

  .seminer-detail__sns-wrap2.sub-sns__wrap2 {
    margin-top: 0rem;
  }

  .seminer-detail__img {
    margin-top: 2.375rem;
    max-width: 55rem;
  }

  .seminer-detail__img img {
    aspect-ratio: 880/462;
  }

  .seminer-detail__text-wrap {
    margin-top: 2.5rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .seminer-detail__text + .seminer-detail__text {
    margin-top: 2.5rem;
  }

  .seminer-detail-contents {
    margin-top: 2.5rem;
    max-width: 55rem;
    width: 100%;
    padding-inline: 0rem;
  }

  .seminer-detail-contents h3 {
    margin-bottom: 2.5rem;
    font-size: 1.625rem;
    line-height: 1.3461538462;
    padding: 0 1.25rem;
  }

  .seminer-detail-contents p.seminer-detail-content__text-l {
    margin-bottom: 2.5rem;
    font-size: 1.25rem;
    line-height: 1;
  }

  .seminer-detail-contents p.seminer-detail-content__text-s {
    margin-bottom: 2.5rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .seminer-detail-contents .seminer-detail-content__img {
    margin-bottom: 5rem;
    max-width: 55rem;
  }

  .seminer-detail-contents .seminer-detail-content__img img {
    aspect-ratio: 880/345;
  }

  .seminer-detail-content__button-wrap1 {
    margin-top: 2.8125rem;
    margin-bottom: 2.5rem;
  }

  .seminer-detail-content__button-wrap2 {
    margin-top: 2.8125rem;
    margin-bottom: 5rem;
  }

  .seminer-detail-content__button {
    font-size: 1.4375rem;
    line-height: 1.5217391304;
    padding: 0.875rem 5.3125rem;
  }

  .seminer-contents__inner {
    max-width: 55rem;
    padding-inline: 0rem;
  }

  .seminer-contents__bg {
    border-left: 11px solid #d0b627;
    padding: 3.4375rem 1.5625rem 3.4375rem 4.4375rem;
    margin-bottom: 5rem;
  }

  .seminer-contents__title-content {
    gap: 1.3125rem;
  }

  .seminer-contents__title--en {
    max-width: 16.0625rem;
  }

  .seminer-contents__title--ja {
    font-size: 1.25rem;
  }

  .seminer-contents__list-title {
    font-size: 1.25rem;
    line-height: 1;
  }

  .seminer-contents__list-title:nth-child(3) {
    margin-top: 1.5rem;
  }

  .seminer-contents__list {
    margin-top: 0.8125rem;
  }

  .seminer-contents__list-item {
    font-size: 0.9375rem;
    line-height: 2;
    padding-left: 1.5rem;
  }

  .seminer-contents__list-item::before {
    top: 0.625rem;
    width: 0.875rem;
    height: 0.875rem;
  }

  .seminer-contents__list-sub-title {
    font-size: 1.125rem;
    padding-left: 1.25rem;
  }

  .seminer-contents__list-sub-title::before {
    top: 0.6875rem;
    left: 0;
    width: 0.875rem;
    height: 0.875rem;
  }

  .seminer-download {
    max-width: 55rem;
    width: 100%;
    padding-inline: 0rem;
  }

  .seminer-download__content {
    padding: 2.6875rem 2.8125rem 3rem;
  }

  .seminer-download__title {
    font-size: 2.5625rem;
    line-height: 1.3414634146;
    max-width: 49.4375rem;
  }

  .seminer-download__text {
    margin-top: 2.1875rem;
  }

  .seminer-download__text p {
    font-size: 1.4375rem;
  }

  .seminer-download__text p:nth-child(2) {
    margin-top: 1.5625rem;
  }

  .seminer-download__img {
    margin-top: 2rem;
    max-width: 49.4375rem;
  }

  .seminer-download__img img {
    aspect-ratio: 791/414;
  }

  .seminer-download__button-wrap {
    margin-top: 2.8125rem;
  }

  .seminer-download__button {
    font-size: 1.4375rem;
    line-height: 1.5217391304;
    padding: 0.875rem 5.3125rem;
  }

  .seminer-detail-selectBox__content.sub-selectBox__content {
    display: none;
  }

  .seminer-side__recommendーarticles {
    margin-top: 1rem;
  }

  .seminer-side__recommend-bg.sub-side__articles-bg {
    display: block;
  }

  .sp-seminer-side__recommend-bg.sub-side__articles-bg {
    display: none;
  }

  .seminer-document-form {
    max-width: 55rem;
    padding-inline: 0rem;
  }

  .seminer-document-form__title {
    font-size: 1.625rem;
    line-height: 1.3461538462;
  }

  .seminer-document-form__inner {
    max-width: 45.8125rem;
    padding-inline: 1.5625rem;
  }

  .seminer-document-form-wrap {
    margin-top: 3.75rem;
  }

  .seminer-document-form__contents {
    display: flex;
    gap: 0.625rem;
  }

  .seminer-document-form__label {
    font-size: 14px;
  }

  .seminer-document-form__label-name {
    margin-right: 0.625rem;
  }

  form > div:nth-child(7) > label > span.seminer-document-form__required {
    margin-right: 0.625rem;
  }

  .seminer-document-form__input {
    padding: 0.5rem 1.25rem;
    margin: 0.25rem 0 1.875rem 0;
  }

  .form-control {
    max-width: 22.625rem;
    padding: 0.5rem 1.25rem;
    margin: 0.25rem 0 1.875rem 0;
  }

  .seminer-document-form__select {
    padding: 0.5rem 1.25rem;
    margin: 0.25rem 0 1.875rem 0;
  }

  .seminer-document-form__select-content::before {
    width: 16px;
    right: 28px;
    top: 16px;
  }

  .seminer-document-form__textarea {
    padding: 0.5rem 1.25rem;
  }

  .seminer-document-form__privacy {
    margin-top: 1.875rem;
  }

  .seminer-document-form__button-wrap {
    margin-top: 3.125rem;
  }

  .layout-seminer-detail {
    margin-top: 1.875rem;
  }

  .layout-seminer-contents {
    margin-top: 5rem;
  }

  .layout-changeByEdit {
    margin-top: 5.1875rem;
  }

  ._layout-seminer-document-form {
    margin-bottom: 3.125rem;
  }

  .beliveInEdithing__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .beliveInEdithing__sub-section-title.sub-section-title__en {
    max-width: 41.25rem;
  }

  .beliveInEdithing-card__article-content {
    max-width: 73.5rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.3125rem;
  }

  .beliveInEdithing-card__img {
    max-width: 21.125rem;
    padding-inline: 0rem;
  }

  .beliveInEdithing-card__img img {
    aspect-ratio: 338/186;
  }

  .beliveInEdithing-card__body {
    margin-top: 0.625rem;
    max-width: 100%;
  }

  .beliveInEdithing-card__title {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .layout-beliveInEdithing {
    margin-top: 1.625rem;
  }

  .beliveInEdithing__inner {
    max-width: 70.625rem;
    padding-inline: 1.5625rem;
  }

  .beliveInEdithing___menu-list ul {
    display: block;
    margin-top: 1.25rem;
    display: flex;
    gap: 1.875rem;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5;
  }

  .beliveInEdithing___tag-list ul {
    background: #f0f0f0;
    padding: 1.4375rem 4.375rem 1.5rem 2.8125rem;
    display: block;
    margin-top: 1.125rem;
    display: flex;
    gap: 1.25rem;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.5384615385;
  }

  .beliveInEdithing__article {
    margin-top: 0rem;
    max-width: 32.8125rem;
  }

  .beliveInEdithing__title {
    font-size: 1.5rem;
    line-height: 1.3333333333;
    margin-left: 10.3125rem;
  }

  .beliveInEdithing__content {
    margin-top: 0rem;
    padding-bottom: 1.3125rem;
    margin-top: -1.875rem;
    align-items: center;
  }

  .beliveInEdithing__img {
    max-width: 9.3125rem;
    margin-right: 1.25rem;
  }

  .beliveInEdithing__img img {
    aspect-ratio: 149/211;
  }

  .beliveInEdithing__author-wrap {
    display: flex;
    align-items: center;
    margin-top: 3.9375rem;
  }

  .beliveInEdithing__author-img {
    margin-right: 1.1875rem;
    max-width: 6.9375rem;
  }

  .beliveInEdithing__author-text {
    margin-top: 0rem;
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }

  .beliveInEdithing__category-wrap {
    max-width: 21.125rem;
  }

  .beliveInEdithing__category {
    font-size: 0.8125rem;
  }

  p.beliveInEdithing__button-text.button__text {
    margin-top: 0rem;
    font-size: 0.875rem;
  }

  .beliveInEdithing__button__arrow {
    width: 1rem;
    height: 1rem;
  }

  .beliveInEdithing__button__arrow::before {
    left: 3px;
    width: 8px;
  }

  .beliveInEdithing__button__arrow::after {
    left: 5px;
    width: 5px;
    height: 5px;
  }

  .layout-beliveInEdithing-archive-case {
    margin-top: 3.5rem;
  }

  .layout-beliveInEdithing-recommendation {
    margin-top: 2.75rem;
  }

  .layout-beliveInEdithing-archive-howTo {
    margin-top: 5.0625rem;
    margin-bottom: 6.25rem;
  }

  .beliveInEdithing-detail__section-title {
    margin-top: 1.5625rem;
  }

  .beliveInEdithing-detail__main-contents {
    display: flex;
    gap: 2.5rem;
    margin-inline: auto;
    max-width: 1150px;
    width: 100%;
  }

  .beliveInEdithing-detail__main {
    width: 76.5217%;
    margin-left: auto;
    padding-left: 1.5625rem;
  }

  .beliveInEdithing-detail__inner {
    max-width: 55rem;
    width: 100%;
    padding-inline: 0rem;
  }

  .beliveInEdithing-detail__menu-content {
    display: block;
    margin-top: 1.875rem;
  }

  .beliveInEdithing-detail__head {
    margin-top: 0rem;
  }

  .beliveInEdithing-detail__title {
    font-size: 2.5rem;
    line-height: 1.25;
    padding-left: 0.9375rem;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }

  .beliveInEdithing-detail__date-wrap {
    margin-top: 0.5625rem;
  }

  .beliveInEdithing-detail__date {
    font-size: 1.25rem;
    line-height: 1;
  }

  .beliveInEdithing-detail__flex {
    flex-direction: column;
  }

  .beliveInEdithing-detail__sns-wrap {
    margin-top: 1.5rem;
  }

  .beliveInEdithing-detail__img {
    margin-top: 3.8125rem;
    max-width: 55rem;
  }

  .beliveInEdithing-detail__text-wrap {
    margin-top: 4.9375rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .beliveInEdithing-detail__introduction {
    margin-top: 3.8125rem;
    margin-bottom: 5.375rem;
  }

  .beliveInEdithing-detail__introduction-wrap {
    padding: 2.25rem 0 2.125rem;
  }

  .beliveInEdithing-detail__introduction-wrap + .beliveInEdithing-detail__introduction-wrap {
    margin-top: 1.6875rem;
  }

  .beliveInEdithing-detail__introduction-content {
    flex-direction: row;
    gap: 2.375rem;
    padding: 0 2.5rem 0 2.1875rem;
  }

  .beliveInEdithing-detail__introduction-img {
    max-width: 20.0625rem;
  }

  .beliveInEdithing-detail__introduction-img img {
    aspect-ratio: 321/287;
  }

  .beliveInEdithing-detail__introduction-text {
    font-size: 0.9375rem;
    line-height: 2;
    max-width: calc(100% - 20.0625rem);
  }

  .beliveInEdithing-contents__inner {
    max-width: 55rem;
    padding-inline: 0rem;
  }

  .beliveInEdithing-detail-contents {
    margin-bottom: 7.6875rem;
  }

  .beliveInEdithing-detail-contents h3 {
    margin-bottom: 3.75rem;
    font-size: 1.625rem;
    line-height: 1.3461538462;
    padding: 0 1.25rem;
  }

  .beliveInEdithing-detail-contents p.beliveInEdithing-detail-content__text-s {
    margin-bottom: 2.5rem;
    font-size: 0.9375rem;
    line-height: 2;
  }

  .beliveInEdithing-detail-contents .beliveInEdithing-detail-content__img {
    margin-bottom: 5rem;
    max-width: 55rem;
  }

  .beliveInEdithing-detail-contents .beliveInEdithing-detail-content__img img {
    aspect-ratio: 880/586;
  }

  .beliveInEdithing-detail-selectBox__content.sub-selectBox__content {
    display: none;
  }

  .beliveInEdithing-side__recommendーarticles {
    margin-top: 1rem;
  }

  .beliveInEdithing-side__recommend-bg.sub-side__articles-bg {
    display: block;
  }

  .sp-beliveInEdithing-side__recommend-bg.sub-side__articles-bg {
    display: none;
  }

  .layout-beliveInEdithing-detail {
    margin-top: 1.875rem;
  }

  .layout-beliveInEdithing-contents {
    margin-top: 5rem;
  }
}

@media screen and (min-width: 769px) {
  .sixstrength__title--en {
    max-width: 36.8125rem;
  }
}

@media screen and (min-width: 780px) {
  .seminer-detail__date:nth-child(2) {
    margin-top: 0rem;
    padding-left: 0.5625rem;
  }
}

@media screen and (min-width: 789px) {
  .knowledge-detail__date:nth-child(2) {
    margin-top: 0rem;
    padding-left: 0.5625rem;
  }
}

@media screen and (min-width: 850px) {
  .card-list__item.new-mark::after {
    top: 60%;
  }
}

@media screen and (min-width: 970px) {
  .document-menu___menu-list {
    margin-top: 1.4375rem;
    flex-direction: row;
    max-width: 61.125rem;
    padding-inline: 1.5625rem;
  }

  .document-menu__item {
    font-size: 1rem;
    line-height: 1.5;
  }

  .document-menu___flex:nth-child(1) > .document-menu__item:nth-child(1) {
    padding: 0.3125rem 4.625rem 0.3125rem 0;
  }

  .document-menu___flex:nth-child(1) > .document-menu__item:nth-child(2) {
    border-right: 1px solid #1c1c1c;
    padding: 0.3125rem 5.3125rem;
  }

  .document-menu___flex:nth-child(2) {
    margin-top: 0rem;
  }

  .document-menu___flex:nth-child(2) > .document-menu__item:nth-child(1) {
    padding: 0.3125rem 5.0625rem;
  }

  .document-menu___flex:nth-child(2) > .document-menu__item:nth-child(2) {
    padding: 0.3125rem 0 0.3125rem 5.0625rem;
  }
}

@media screen and (min-width: 1000px) {
  .top-powerOfEditing__section-title {
    max-width: 51.375rem;
  }
}

@media screen and (min-width: 1050px) {
  .top-card1__text-content {
    max-width: 26.0625rem;
  }

  .top-card1__title--ja {
    font-size: 1.5625rem;
  }

  .top-card1__text {
    margin-top: 1.5rem;
  }

  .top-card1__title {
    font-size: 1.375rem;
    line-height: 1.1818181818;
  }

  .top-card2__title {
    font-size: 1.375rem;
    line-height: 1.1818181818;
  }

  .top-knowledge__topics {
    font-size: 0.8125rem;
    line-height: 1.6923076923;
  }

  h3.top-publication__article-title.top-card1__title {
    margin-top: 0.3125rem;
    font-size: 1.375rem;
    line-height: 1.1818181818;
  }

  h3.top-powerOfEditing__article-title.top-card1__title {
    margin-top: 0.3125rem;
    font-size: 1.375rem;
    line-height: 1.1818181818;
  }

  h3.top-seminer__article-title.top-card1__title {
    margin-top: 0.3125rem;
    font-size: 1.375rem;
    line-height: 1.1818181818;
  }

  .sixstrength__text {
    margin-top: 1.8125rem;
    font-size: 1.375rem;
  }
}

@media screen and (min-width: 1060px) {
  .header {
    height: 104px;
  }

  .header__inner {
    padding: 0 2.25rem 0 2.5625rem;
    height: 70px;
    padding: 0;
    border-bottom: 1px solid #bcbcbc;
  }

  .header__content {
    padding: 0 2.25rem 0 2.5625rem;
  }

  .header__logo-text {
    font-size: 0.8125rem;
    margin-left: 0;
  }

  .header__button {
    display: block;
    display: flex;
    align-items: center;
    margin-right: 1.625rem;
    font-size: 0.8125rem;
    max-width: 27.1875rem;
    width: 100%;
  }

  .header__menu-info__sns {
    margin-top: 1.3125rem;
  }

  .header__nav {
    display: block;
    border-bottom: 1px solid #bcbcbc;
    height: 34px;
    display: flex;
    align-items: center;
  }

  .header__nav-items {
    display: flex;
    align-items: center;
    gap: 6%;
    max-width: 67.875rem;
    width: 100%;
    height: inherit;
    padding-inline: 1.25rem;
    margin-inline: auto;
  }

  .header-spacer {
    height: 104px;
  }

  .footer__button {
    display: block;
    display: flex;
    align-items: center;
    margin-right: 1.625rem;
    font-size: 0.8125rem;
    line-height: 1;
  }

  .pc-footer__button {
    display: block;
    display: flex;
    align-items: center;
    margin-right: 1.625rem;
    font-size: 0.8125rem;
    line-height: 1;
  }

  .card-list__item.new-mark::after {
    top: 65%;
  }
}

@media screen and (min-width: 1120px) {
  .knowledge__menu-content {
    display: block;
    margin-top: 1.875rem;
  }

  .knowledge-selectBox__content.sub-selectBox__content {
    display: none;
  }

  .knowledge-selectBox-content.sub-selectBox__content {
    display: none;
  }

  .seminer__menu-content {
    display: block;
    margin-top: 1.875rem;
  }

  .seminer-selectBox__content.sub-selectBox__content {
    display: none;
  }

  .seminer-selectBox-content.sub-selectBox__content {
    display: none;
  }

  .seminer-detail-profile__content {
    max-width: 58.125rem;
    margin-bottom: 6.5rem;
  }

  .seminer-detail-profile__img {
    max-width: 21.5625rem;
  }

  .seminer-detail-profile__img img {
    aspect-ratio: 345/589;
  }

  .seminer-detail-profile__title-content {
    max-width: 100%;
    padding: 2.625rem 2.5625rem 2.6875rem 2rem;
  }

  .seminer-detail-profile__title-wrap {
    margin-top: 0rem;
    display: flex;
    align-items: center;
    gap: 1.1875rem;
  }

  .seminer-detail-profile__title--en {
    max-width: 7.875rem;
  }

  .seminer-detail-profile__title--ja {
    font-size: 0.9375rem;
    line-height: 1.5333333333;
  }

  .seminer-detail-profile__title-name1 {
    margin-top: 0.75rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .seminer-detail-profile__title-name2 {
    margin-top: 0rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .seminer-detail-profile__text-body {
    margin-top: -25rem;
    margin-left: 23.5625rem;
    padding: 0;
    max-width: 28.4375rem;
  }

  .seminer-detail-profile__text {
    font-size: 0.9375rem;
    padding: 0;
  }

  .beliveInEdithing__menu-content {
    display: block;
    margin-top: 1.875rem;
  }

  .beliveInEdithing-selectBox__content.sub-selectBox__content {
    display: none;
  }

  .beliveInEdithing-selectBox-content.sub-selectBox__content {
    display: none;
  }
}

@media screen and (min-width: 1130px) {
  .drawer__bg {
    background-image: url("../images/common/drawer-bg.webp");
    padding: 61px 0 84px;
    height: 100%;
  }

  .drawer__nav-lists {
    flex-direction: row;
  }

  .drawer__nav-list:nth-child(1) {
    margin-right: 2.0625rem;
  }

  .menu__info {
    margin-top: 242px;
    margin-left: -200px;
  }

  .menu__info-wrap2 {
    margin-top: 1.3125rem;
    flex-direction: column;
  }
}

@media screen and (min-width: 1200px) {
  .sixstrength__article-body {
    padding: 3.8125rem 1.9375rem;
  }

  .sixstrength__article-number {
    font-size: 3.375rem;
    line-height: 1.1851851852;
  }

  .sixstrength__article-title {
    font-size: 2rem;
    line-height: 1.40625;
  }

  .sixstrength__article-text {
    margin-top: 1.375rem;
    font-size: 1rem;
    line-height: 1.5;
  }
}

@media screen and (min-width: 1281px) {
  .seminer-detail__main-contents {
    margin-right: auto;
  }
}

@media screen and (max-width: 950px) {
  .footer-logo {
    padding: 19px 0;
  }

  .footer-logo__items {
    flex-direction: column;
  }

  .footer-logo__item-wrap {
    margin-inline: auto;
  }

  .footer-logo__item {
    max-width: 8.875rem;
    width: 100%;
    margin-right: 1.4375rem;
  }

  .footer-logo__item-wrap > div:nth-child(2) {
    max-width: 10.625rem;
    width: 100%;
    margin-right: 0;
  }

  .footer-logo__item img {
    width: 100%;
    height: 100%;
  }

  .footer-logo__item.footer-logo__item--last {
    margin-inline: auto;
  }
}