@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//編集力
.top-powerOfEditing__section-title {
    max-width: rem(326);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(611);
    }
    @media screen and (min-width: 1000px) {
        max-width: rem(822);
    }
}

.top-powerOfEditing__top-bg {
    background: url(../images/top/powerOfEditing-bg.webp) no-repeat center / cover;
}

.top-powerOfEditing__top-title--en.top-card1__title--en {
    max-width: rem(196);
    @media screen and (min-width: $tab) {
        max-width: rem(320);
    }
}

.top-powerOfEditing__top-text.top-card1__text {
    margin-top: rem(20);
    @media screen and (min-width: $tab) {
        margin-top: rem(59);
    }
}

.top-powerOfEditing__top-button {
    display: inline-flex;
    align-items: center;
}

.top-powerOfEditing__article-img1,
.top-powerOfEditing__article-img2,
.top-powerOfEditing__article-img3,
.top-powerOfEditing__article-img4,
.top-powerOfEditing__article-img5,
.top-powerOfEditing__article-img6 {
    border-radius: 20px;
}

.top-powerOfEditing__article-img1.top-card1__img img,
.top-powerOfEditing__article-img2.top-card1__img img,
.top-powerOfEditing__article-img3.top-card1__img img,
.top-powerOfEditing__article-img4.top-card1__img img,
.top-powerOfEditing__article-img5.top-card1__img img,
.top-powerOfEditing__article-img6.top-card1__img img {
    aspect-ratio: 136 / 204;
    @media screen and (min-width: $tab) {
        aspect-ratio: 285 / 428;
    }
}

.top-powerOfEditing__category {
    background-color: $red;
}

.top-powerOfEditing__category.top-card1__category {
    @media screen and (min-width: $tab) {
        margin-top: rem(13);
    }
}

.top-powerOfEditing__category.top-card1__category img {
    max-width: rem(83);
    @media screen and (min-width: $tab) {
        max-width: rem(108);
    }
}

h3.top-powerOfEditing__article-title.top-card1__title {
    margin-top: rem(10);
    font-size: rem(13);
    line-height: calc(15 / 13);
    @media screen and (min-width: $tab) {
        font-size: rem(18);
    }
    @media screen and (min-width: 1050px) {
        margin-top: rem(5);
        font-size: rem(22);
        line-height: calc(26 / 22);
    }
}

.top-powerOfEditing__article-text {
    margin-top: rem(5);
    font-size: rem(13);
    line-height: calc(15 / 13);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        display: none;
    }
}

.top-powerOfEditing__topics {
    margin-top: rem(5);
    font-size: 10px;
    line-height: calc(22 / 10);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(13);
        line-height: calc(22 / 13);
    }
}

.top-powerOfEditing__top-button__arrow {
    left: rem(40);
    @media screen and (min-width: $sp) {
        left: rem(60);
    }
    @media screen and (min-width: $tab) {
        left: rem(80);
    }
}

.top-powerOfEditing__button {
    margin-top: rem(18);
    justify-content: right;
    padding-right: 5px;
}

.top-card1__top-link:hover .top-powerOfEditing__top-button__arrow {
    transform: translateX(8px);
}
