@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.card-category {
    display: inline;
    border-radius: 14px;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.5;
    color: $white;
}
