@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//セミナー情報 お申込みフォーム
.seminer-document-form {
    max-width: 100%;
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(880);
        padding-inline: rem(0);
    }
}

.seminer-document-form__title {
    border: 1px solid #0177b4;
    font-weight: bold;
    font-size: rem(18);
    line-height: calc(24 / 18);
    text-align: center;
    color: #007ccf;
    padding: rem(4) 0;
    @media screen and (min-width: $tab) {
        font-size: rem(26);
        line-height: calc(35 / 26);
    }
}

.seminer-document-form__inner {
    max-width: rem(670);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(10);
    @media screen and (min-width: $tab) {
        max-width: rem(733);
        padding-inline: rem(25);
    }
}

.seminer-document-form-wrap {
    margin-top: rem(53);
    @media screen and (min-width: $tab) {
        margin-top: rem(60);
    }
}

.seminer-document-form__contents {
    @media screen and (min-width: $tab) {
        display: flex;
        gap: rem(10);
    }
}

.seminer-document-form__content {
    width: 100%;
    @media screen and (min-width: $tab) {
    }
}

.seminer-document-form__label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    font-size: rem(14);
    font-weight: 400;
    @media screen and (min-width: $tab) {
        font-size: 14px;
    }
}

.seminer-document-form__label-name {
    margin-right: rem(6);
    @media screen and (min-width: $tab) {
        margin-right: rem(10);
    }
}

form > div:nth-child(7) > label > span.seminer-document-form__required {
    margin-right: 0;
    @media screen and (min-width: $tab) {
        margin-right: rem(10);
    }
}

.seminer-document-form__required {
    width: rem(31);
    height: rem(14);
    font-weight: 400;
    font-size: 10px;
    color: $white;
    background-color: #760303;
    margin-right: rem(10);
    padding: 0 rem(5);
}

.seminer-document-form__input {
    border: none;
    background-color: #f7f7f7;
    height: rem(38);
    width: 100%;
    max-width: rem(734);
    font-size: rem(14);
    font-weight: 400;
    color: #868686;
    padding: rem(8) rem(8);
    margin: rem(4) 0 rem(10) 0;
    @media screen and (min-width: $tab) {
        padding: rem(8) rem(20);
        margin: rem(4) 0 rem(30) 0;
    }
}

.form-control {
    border: none;
    background-color: #f7f7f7;
    height: rem(38);
    width: 100%;
    padding: rem(8) rem(8);
    margin: rem(4) 0 rem(10) 0;
    @media screen and (min-width: $tab) {
        max-width: rem(362);
        padding: rem(8) rem(20);
        margin: rem(4) 0 rem(30) 0;
    }
}

.seminer-document-form__text {
    font-weight: 400;
    font-size: 11px;
}

.seminer-document-form__text--right {
    font-weight: 400;
    font-size: 11px;
    margin-left: 0;
    @media screen and (min-width: 518px) {
        margin-left: auto;
    }
}

.seminer-document-form__select {
    border: none;
    background-color: #f7f7f7;
    height: rem(38);
    width: 100%;
    max-width: rem(427);
    font-size: rem(14);
    font-weight: 400;
    color: #868686;
    padding: rem(8) rem(8);
    margin: rem(4) 0 rem(10) 0;
    @media screen and (min-width: $tab) {
        padding: rem(8) rem(20);
        margin: rem(4) 0 rem(30) 0;
    }
}

.seminer-document-form__select-content.seminer-document-form__select-content--middle {
    max-width: rem(539);
}

.seminer-document-form__select-content {
    position: relative;
    max-width: rem(427);
    width: 100%;
}
.seminer-document-form__select.seminer-document-form__select--middle {
    max-width: rem(539);
}

//セレクトボックスの矢印デザイン変更
.seminer-document-form__select {
    -webkit-appearance: none;
    appearance: none; /* デフォルトの矢印を非表示 */
}
.seminer-document-form__select::-ms-expand {
    display: none; /* デフォルトの矢印を非表示(IE用) */
}
/* セレクトボックスの矢印デザイン変更 */
.seminer-document-form__select-content::before {
    background: black;
    height: calc(tan(60deg) * 14px / 2);
    width: 13px;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    content: "";
    position: absolute;
    right: 12px;
    top: 18px;
    @media screen and (min-width: $tab) {
        width: 16px;
        right: 28px;
        top: 16px;
    }
}

.seminer-document-form__optional {
    width: rem(31);
    height: rem(14);
    font-weight: 400;
    font-size: 10px;
    color: $white;
    background-color: #4a81ff;
    margin-right: rem(10);
    padding: 0 rem(5);
}

.seminer-document-form__textarea {
    border: none;
    background-color: #f7f7f7;
    height: rem(161);
    width: 100%;
    max-width: rem(734);
    font-size: rem(14);
    font-weight: 400;
    color: #868686;
    padding: rem(8) rem(8);
    margin: rem(4) 0 rem(10) 0;
    @media screen and (min-width: $tab) {
        padding: rem(8) rem(20);
    }
}

.seminer-document-form__privacy {
    margin-top: rem(20);
    @media screen and (min-width: $tab) {
        margin-top: rem(30);
    }
}

.seminer-document-form__privacy-text,
.seminer-document-form__acceptance {
    font-weight: 400;
    font-size: 14px;
}

.seminer-document-form__privacy-text > a {
    text-decoration: underline;
    color: #2680eb;
}

.seminer-document-form__privacy input[type="checkbox"] + span {
    margin-top: rem(30);
    display: inline-block;
    position: relative;
}

.contact__form-privacy input[type="checkbox"]:checked + span::before {
    background: #f7f7f7;
}

.contact__form-privacy input[type="checkbox"] + span::before {
    background: #f7f7f7;
    border: 1px solid #707070;
    content: "";
    display: block;
    width: rem(15);
    height: rem(15);
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.seminer-document-form__button-wrap {
    margin-top: rem(28);
    width: 100%;
    border-radius: 10px;
    background: #2680eb;
    text-align: center;
    @media screen and (min-width: $tab) {
        margin-top: rem(50);
    }
}

button.seminer-document-form__button {
    padding: rem(10) 0;
    font-weight: bold;
    font-size: rem(18);
    line-height: calc(28 / 18);
    color: white;
}

button {
    padding: 0;
    background-color: transparent;
    border: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
}
