@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//アコーディオン
.accordion__button {
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    padding-right: 21px;
    @media screen and (min-width: $tab) {
        padding-right: 60px;
    }
}
.accordion__button:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    /*横線*/
    width: 15px;
    height: 2px;
    background: #101010;
    @media screen and (min-width: $tab) {
        width: 39px;
    }
}
.accordion__button:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    transition: all 0.3s;
    /*縦線*/
    width: 2px;
    height: 15px;
    background: #101010;
    @media screen and (min-width: $tab) {
        right: 20px;
        height: 39px;
    }
}

/* 縦線（after）だけ縦回転させる */
.accordion__button.open:after {
    top: 25%;
    transform: rotate(90deg);
    opacity: 0;
  }

.hidden-text {
    display: none;
}
