@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

// RECOMENDおすすめ
.sub-recomend__bg {
    background:rgba(0,90,45,0.1);
    padding: rem(51) 0 rem(50);
    @media screen and (min-width: $tab) {
        padding: rem(77) 0 rem(126);
    }
}

.sub-recomend__inner {
    max-width: rem(710);
    width: 100%;
    padding-inline: rem(20);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: rem(1152);
        padding-inline: rem(25);
    }
}

.sub-recomend__title {
    max-width: rem(159);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(223);
    }
}

.sub-recomend__items {
    margin-top: rem(32);
    @media screen and (min-width: $tab) {
        margin-top: rem(44);
    }
}

.sub-recomend__img {
    max-width: rem(136);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(203);
    }
}

.sub-recomend__img img {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 136 / 181;
        @media screen and (min-width: $tab) {
            aspect-ratio: 203 / 293;
        }
}
