@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.sp-footer__inner {
	max-width: rem(710);
	width: 100%;
	margin-inline: auto;
	padding: 30px rem(20) 7px rem(20);
	@media screen and (min-width: $tab) {
		display: none;
	}
}

.sp-footer__logo {
	max-width: rem(335);
}

.sp-footer__flex-wrap {
	margin-top: rem(28);
	display: flex;
	justify-content: space-between;
}

.sp-footer__nav-items {
	display: flex;
	flex-direction: column;
}

.sp-footer__nav-item {
	margin-top: rem(13);
}

.sp-footer__nav-item:nth-child(1) {
	margin-top: 0;
}

.sp-footer__button-wrap {
	margin-top: rem(119);
	display: flex;
	flex-direction: column;
	max-width: rem(173);
	align-items: end;
	gap: rem(17);
}

.sp-footer__info {
	margin-top: rem(18);
}

.sp-footer__menu-info-wrap {
	margin-top: rem(30);
	display: flex;
	flex-direction: column;
}

.sp-footer__copyright {
	margin-top: rem(40);
	text-align: right;
}
