@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//card-list

.card-list {
	margin-top: rem(41);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	row-gap: rem(33);
	column-gap: rem(27);
	@media screen and (min-width: 500px) {
		grid-template-columns: repeat(3, 1fr);
	}
	@media screen and (min-width: $tab) {
		margin-top: rem(61);
		grid-template-columns: repeat(4, 1fr);
		row-gap: rem(77);
		column-gap: rem(76);
	}
}

.card-list__item {
	position: relative;
	max-width: rem(153);
	width: 100%;
	margin-inline: auto;
	@media screen and (min-width: $tab) {
		max-width: rem(221);
	}
}

.card-list__item.new-mark::after {
	content: "NEW";
	font-family: $space-grotesk;
	font-weight: bold;
	font-size: rem(12);
	line-height: 1;
	color: $white;
	width: rem(36);
	height: rem(35);
	background: #8b7086;
	border-radius: 50%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 150px;
	left: -10px;
	z-index: 10;
	@media screen and (min-width: $tab) {
		font-size: rem(22);
		width: rem(56);
		height: rem(55);
		top: 55%;
		left: -20px;
	}
	@media screen and (min-width: 850px) {
		top: 60%;
	}
	@media screen and (min-width: 1060px) {
		top: 65%;
	}
}

.card-list__img {
	max-width: rem(138);
	width: 100%;
	margin-inline: auto;
	@media screen and (min-width: $tab) {
		max-width: rem(209);
	}
}

.card-list__img img {
	object-fit: contain;
	width: 100%;
	aspect-ratio: 138 / 192;
	@media screen and (min-width: $tab) {
		aspect-ratio: 209 / 292;
	}
}

.card-list__body {
	padding-top: rem(10);
	display: flex;
	flex-direction: column;
	min-height: rem(74);
	@media screen and (min-width: $tab) {
		padding-top: rem(16);
		min-height: rem(64);
	}
}

.card-list__number {
	margin-top: -35px;
	width: 70px;
	height: 35px;
	border-radius: 70px 70px 0 0;
	color: $white;
	font-size: 23px;
	font-weight: bold;
	margin-inline: auto;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.card-list__title {
	font-size: rem(14);
	line-height: calc(18 / 14);
	font-weight: bold;
	display: -webkit-box;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;

	@media screen and (min-width: $tab) {
		font-size: rem(16);
		line-height: calc(20 / 16);
	}
}
