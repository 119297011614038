@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//おすすめ
.beliveInEdithing__inner {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(1130);
        padding-inline: rem(25);
    }
}

.beliveInEdithing__sub-section-title.sub-section-title__en {
    max-width: rem(326);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(660);
    }
}

.beliveInEdithing__menu-content {
    display: none;
    @media screen and (min-width: 1120px) {
        display: block;
        margin-top: rem(30);
    }
}

.beliveInEdithing__menu-list ul {
    margin-top: rem(20);
    display: flex;
    font-weight: bold;
    font-size: rem(16);
    line-height: calc(24 / 16);
    justify-content: center;
}

.beliveInEdithing__menu-item {
    padding: rem(5) rem(90);
    border-right: 1px solid #1c1c1c;
}

.beliveInEdithing__menu-list > ul > li:nth-child(1) {
    border-left: 1px solid #1c1c1c;
}

.beliveInEdithing__tag-list ul {
    background: #f0f0f0;
    padding: rem(23) rem(70) rem(24) rem(45);
    margin-top: rem(18);
    display: flex;
    gap: rem(19);
    font-weight: 500;
    font-size: rem(13);
    line-height: calc(20 / 13);
}

.beliveInEdithing-selectBox__content.sub-selectBox__content {
    margin-top: rem(21);
    display: flex;
    @media screen and (min-width: 1120px) {
        display: none;
    }
}

//beliveInEdithing-card
.beliveInEdithingーarchive__article-content.beliveInEdithing-card__article-content {
    row-gap: rem(49);
}
.beliveInEdithing-card__article-content {
    margin-top: rem(25);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: rem(35);
    @media screen and (min-width: $tab) {
        max-width: rem(1176);
        grid-template-columns: repeat(3, 1fr);
        gap: rem(21);
    }
}

.beliveInEdithing-card__img {
    max-width: rem(634);
    width: 100%;
    padding-inline: rem(17);
    margin-inline: auto;
    @media screen and (min-width: $tab) {
        max-width: rem(338);
        padding-inline: rem(0);
    }
}

.beliveInEdithing-card__img img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 300 / 165;
    @media screen and (min-width: $tab) {
        aspect-ratio: 338 / 186;
    }
}

.beliveInEdithing-card__body {
    margin-top: rem(5);
    max-width: rem(600);
    width: 100%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media screen and (min-width: $tab) {
        margin-top: rem(10);
        max-width: 100%;
    }
}

.beliveInEdithing-card__title {
    font-weight: bold;
    font-size: rem(16);
    line-height: calc(24 / 16);
    color: $sub-black1;
    @media screen and (min-width: $tab) {
        font-size: rem(20);
        line-height: calc(30 / 20);
    }
}

.beliveInEdithing-card__article-tag-wrap {
    display: flex;
    gap: rem(20);
}

.beliveInEdithing-card__article-tag {
    font-weight: 500;
    font-size: rem(13);
    line-height: calc(20 / 13);
}

//beliveInEdithing-card__linkホバー画像拡大
.beliveInEdithing-card__img {
    overflow: hidden;
}

.beliveInEdithing-card__img img {
    transition: transform 0.5s ease;
}

.beliveInEdithing-card__link:hover .beliveInEdithing-card__img img {
    transform: scale(1.1);
}

.beliveInEdithing-card__link:hover .beliveInEdithing__button__arrow {
    transform: translateX(8px);
}
