@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//top-mv
.top-mv {
    width: 100%;
    position: relative;
}

.top-mv__bg {
    background-image: url("../images/top/top-mv-bg-sp.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    @media screen and (min-width: $tab) {
        background-image: url("../images/top/top-mv-bg.webp");
    }
}

.top-mv__inner {
    padding-top: rem(181);
    width: 100%;
    margin-inline: auto;
    position: relative;
    padding-bottom: rem(42);
    height: 100%;
    @media screen and (min-width: $tab) {
        padding: 0;
        max-width: rem(1174);
        padding-inline: rem(25);
        padding-bottom: 0px;
    }
}
