@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//button
.button {
text-align: right;
}

.button__link {
	display: inline-flex;
	align-items: center;
	padding-right: rem(20);
}

.button__arrow,
.button__arrow--white {
	position: absolute;
	left: 10px;
	background-color: transparent;
	border-radius: 50px;
	width: 15px;
	height: 15px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transition: transform 0.5s ease;
	@media screen and (min-width: $tab) {
		width: 23px;
		height: 23px;
	}
}

.button__arrow {
	border: 1px solid $txt-color;
}

.button__arrow--white {
	border: 1px solid white;
}

.button__link:hover .button__arrow {
	transform: translateX(8px);
}

.button__text--white {
	font-family: $roboto;
	font-size: rem(13);
	line-height: calc(33 / 13);
	font-weight: bold;
	@media screen and (min-width: $tab) {
		line-height: calc(53 / 21);
		font-size: rem(21);
	}
}

.button__text {
	font-family: $roboto;
	font-weight: bold;
	font-size: rem(14);
	line-height: calc(35 / 14);
	@media screen and (min-width: $tab) {
		font-size: rem(24);
		font-size: 24px;
		line-height: calc(60 / 24);
	}
}

.button__text--white {
	color: $white;
}

.button-text--white:hover .button__arrow--white {
	transform: translateX(8px);
}
