@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-top-sixstrength {
    margin-top: rem(90);
    @media screen and (min-width: $tab) {
        margin-top: rem(163);
    }
}
