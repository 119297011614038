@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//snsパーツ

.sub-sns__wrap {
    @media screen and (min-width: $tab) {
        display: flex;
        gap: rem(10);
        flex-wrap: wrap;
    }
}

.sub-sns__wrap1,
.sub-sns__wrap2 {
    display: flex;
    gap: rem(10);
}

.beliveInEdithing-detail__sns-wrap1.sub-sns__wrap1 > a:nth-child(1) {
    display: none;
    @media screen and (min-width: $tab) {
        display: block;
    }
}

.beliveInEdithing-detail__sns-wrap1.sub-sns__wrap1 > a:nth-child(2) {
    display: block;
    @media screen and (min-width: $tab) {
        display: none;
    }
}

.sub-sns__item--good {
    @media screen and (min-width: $tab) {
        height: rem(24);
        border-radius: 5px;
        background-color: #6080b8;
        color: $white;
        font-weight: bold;
        font-size: rem(17);
        line-height: calc(20 / 17);
        display: flex;
        align-items: center;
        gap: rem(9);
        padding: 0 rem(14) 0 rem(12);
    }
}

.sub-sp-sns__item--good {
    height: rem(17);
    border-radius: 3px;
    background-color: #6080b8;
    color: $white;
    font-weight: 400;
    font-size: rem(11);
    line-height: calc(18 / 11);
    display: flex;
    align-items: center;
    gap: rem(4);
    padding: 0 rem(7) 0 rem(6);
}

.good-icon img {
    width: rem(13);
    height: rem(14);
    @media screen and (min-width: $tab) {
        width: rem(22);
        height: rem(22);
    }
}

.sub-sns__item--shere {
    height: rem(17);
    border-radius: 5px;
    background-color: #117df0;
    color: $white;
    font-weight: 400;
    font-size: rem(11);
    line-height: calc(14 / 11);
    display: flex;
    align-items: center;
    padding: 0 rem(5);
    @media screen and (min-width: $tab) {
        font-size: rem(12);
        line-height: calc(14 / 12);
        height: rem(24);
        border-radius: 3px;
        font-weight: bold;
        padding: 0 rem(11);
    }
}

.sub-sns__item--x {
    height: rem(17);
    border-radius: 5px;
    background-color: #505050;
    color: $white;
    display: flex;
    align-items: center;
    padding: 0 rem(9);
    @media screen and (min-width: $tab) {
        height: rem(24);
        border-radius: 3px;
        font-weight: bold;
        padding: 0 rem(19);
    }
}

.x-icon img {
    width: rem(10);
    height: rem(10);
    @media screen and (min-width: $tab) {
        width: rem(16);
        height: rem(16);
    }
}

.sub-sns__item--hatena {
    height: rem(17);
    border-radius: 5px;
    background-color: #47a3d9;
    color: $white;
    font-weight: 400;
    font-size: rem(12);
    line-height: calc(14 / 12);
    display: flex;
    align-items: center;
    padding: 0 rem(6);
    @media screen and (min-width: $tab) {
        height: rem(24);
        border-radius: 3px;
        font-weight: bold;
        font-size: rem(17);
        line-height: calc(20 / 17);
        padding: 0 rem(11);
    }
}

.hatena-icon img {
    width: rem(16);
    height: rem(13);
    @media screen and (min-width: $tab) {
        width: rem(23);
        height: rem(20);
    }
}

.knowledge-detail__sns-wrap2 {
    margin-top: rem(5);
    @media screen and (min-width: $tab) {
        margin-top: 0;
    }
}

.sub-sns__item--pocket {
    height: rem(17);
    border-radius: 5px;
    background-color: $white;
    color: $sub-black2;
    font-weight: 400;
    font-size: rem(11);
    line-height: calc(14 / 11);
    display: flex;
    align-items: center;
    gap: rem(3);
    padding: 0 rem(6);
    border: 1px solid #d5d5d5;
    @media screen and (min-width: $tab) {
        height: rem(24);
        border-radius: 3px;
        font-weight: bold;
        font-size: rem(17);
        line-height: calc(20 / 17);
        gap: rem(7);
        padding: 0 rem(12);
    }
}

.pocket-icon img {
    width: rem(11);
    height: rem(11);
    @media screen and (min-width: $tab) {
        width: rem(17);
        height: rem(17);
    }
}

.sub-sns__item--line {
    height: rem(17);
    border-radius: 5px;
    background-color: #59b75c;
    color: $white;
    font-weight: 400;
    font-size: rem(10);
    line-height: calc(12 / 10);
    display: flex;
    align-items: center;
    gap: rem(1);
    padding: 0 rem(8) 0 rem(6);
    @media screen and (min-width: $tab) {
        height: rem(24);
        border-radius: 3px;
        font-weight: bold;
        font-size: rem(12);
        line-height: calc(14 / 12);
        gap: rem(3);
        padding: 0 rem(13) 0 rem(8);
    }
}

.line-icon img {
    width: rem(16);
    height: rem(14);
    @media screen and (min-width: $tab) {
        width: rem(22);
        height: rem(20);
    }
}
