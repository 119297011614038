@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

//お役立ち記事 目次

.knowledge-contents__inner {
    max-width: rem(710);
    width: 100%;
    margin-inline: auto;
    padding-inline: rem(20);
    @media screen and (min-width: $tab) {
        max-width: rem(880);
        padding-inline: rem(0);
    }
}

.knowledge-contents__bg {
    background-color: #f0f0f0;
    border-left: 9px solid #6e0054;
    padding: rem(21) rem(8) rem(31) rem(10);
    margin-bottom: rem(50);
    @media screen and (min-width: $tab) {
        border-left: 11px solid #6e0054;
        padding: rem(21) rem(51) rem(56) rem(77);
        margin-bottom: rem(80);
    }
}

.knowledge-contents__title-content {
    display: flex;
    align-items: center;
    gap: rem(7);
    @media screen and (min-width: $tab) {
        gap: rem(21);
    }
}

.knowledge-contents__title--en {
    max-width: rem(158);
    width: 100%;
    @media screen and (min-width: $tab) {
        max-width: rem(257);
    }
}

.knowledge-contents__title--ja {
    font-weight: bold;
    font-size: rem(14);
    line-height: 1;
    @media screen and (min-width: $tab) {
        font-size: rem(20);
    }
}

.knowledge-contents__list {
    margin-top: rem(37);
    @media screen and (min-width: $tab) {
        margin-top: rem(21);
    }
}

.knowledge-contents__list-title {
    font-weight: bold;
    font-size: rem(14);
    line-height: 2;
    position: relative;
    padding-left: rem(20);
    @media screen and (min-width: $tab) {
        font-size: rem(18);
        padding-left: rem(24);
    }
}

.knowledge-contents__list-title::before {
    position: absolute;
    content: "";
    top: rem(7);
    left: 0;
    width: rem(15);
    height: rem(15);
    background-image: url("../images/common/knowledge-contents-icon.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    @media screen and (min-width: $tab) {
        top: rem(10);
        width: rem(19);
        height: rem(19);
    }
}

.knowledge-contents__list-sub-title {
    font-weight: 400;
    font-size: rem(14);
    line-height: 2;
    position: relative;
    padding-left: rem(26);
    @media screen and (min-width: $tab) {
        font-size: rem(18);
        padding-left: rem(20);
    }
}

.knowledge-contents__list-sub-title::before {
    position: absolute;
    content: "";
    top: rem(8);
    left: rem(11);
    width: rem(11);
    height: rem(11);
    background-image: url("../images/common/knowledge-contents-icon.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    @media screen and (min-width: $tab) {
        top: rem(11);
        left: 0;
        width: rem(14);
        height: rem(14);
    }
}

.knowledge-detail-contents {
    h3 {
        margin-bottom: rem(31);
        background-image: url("../images/knowledge-detail/knowledge-detail-title-bg.webp");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        font-weight: bold;
        font-size: rem(18);
        line-height: calc(27 / 18);
        color: $white;
        padding: 0 rem(4);
        @media screen and (min-width: $tab) {
            margin-bottom: rem(40);
            font-size: rem(26);
            line-height: calc(35 / 26);
            padding: 0 rem(20);
        }
    }
    p.knowledge-detail-content__text-l {
        margin-bottom: rem(30);
        font-weight: 400;
        font-size: rem(16);
        line-height: calc(20 / 16);
        color: $sub-black1;
        @media screen and (min-width: $tab) {
            margin-bottom: rem(40);
            font-size: rem(20);
            line-height: 1;
        }
    }
    p.knowledge-detail-content__text-s {
        margin-bottom: rem(30);
        font-weight: 400;
        font-size: rem(14);
        line-height: calc(28 / 14);
        color: $sub-black1;
        @media screen and (min-width: $tab) {
            margin-bottom: rem(40);
            font-size: rem(15);
            line-height: 2;
        }
    }
    .knowledge-detail-content__img {
        margin-bottom: rem(50);
        max-width: rem(340);
        width: 100%;
        margin-inline: auto;
        @media screen and (min-width: $tab) {
            margin-bottom: rem(80);
            max-width: rem(880);
        }
    }
    .knowledge-detail-content__img img {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 340 / 133;
        @media screen and (min-width: $tab) {
            aspect-ratio: 880 / 345;
        }
    }
}