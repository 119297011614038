@use "setting" as *;
@use "mixin" as *;
@use "function" as *;

.layout-branding {
    margin-top: rem(80);
    @media screen and (min-width: $tab) {
        margin-top: rem(73);
    }   
}
