@use "setting" as *;
@use "mixin" as *;
@use "function" as *;
//詳細ページセクションタイトル
.detail-section-title__contents {
	display: flex;
	justify-content: space-between;
	max-width: rem(710);
	width: 100%;
	margin-inline: auto;
	padding-inline: rem(20);
	@media screen and (min-width: $tab) {
		max-width: rem(1129);
		width: 100%;
		padding-inline: rem(25);
	}
}
.detail-section-title__back {
	display: inline;
	font-weight: bold;
	font-size: rem(15);
	letter-spacing: 0.3em;
	line-height: calc(17 / 15);
	color: #343434;
	text-transform: uppercase;
}

//左矢印
.publication-detail__arrow {
	position: relative;
    display: inline-block;
    padding: 0 0 0 16px;
    color: #000;
    vertical-align: middle;
    text-decoration: none;
    font-size: 15px;
}

.publication-detail__arrow::before,
.publication-detail__arrow::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
}
.publication-detail__arrow::before {
    right: 2px;
    width: 10px;
    height: 2px;
    background-color: $black;
}
.publication-detail__arrow::after {
    left: 3px;
    width: 6px;
    height: 6px;
    border-top: 2px solid $black;
    border-right: 2px solid $black;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.detail-section-title__content {
	text-align: right;
}

.detail-section-title__en {
	max-width: rem(206);
	width: 100%;
	@media screen and (min-width: $tab) {
		max-width: rem(394);
	}
}

.detail-section-title__ja {
	font-weight: 500;
	font-size: rem(14);
	line-height: calc(18 / 14);
	@media screen and (min-width: $tab) {
		font-size: rem(23);
		line-height: calc(36 / 23);
	}
}
